export const KEY_EVENTLIST = 'KEY_EVENTLIST'
export const KEY_ORDERLIST = 'KEY_ORDERLIST'
export const KEY_BOOTHLIST = 'KEY_BOOTHLIST'
export const KEY_ORDERHISTORY = 'KEY_ORDERHISTORY'
export const KEY_EVENTREPORT = 'KEY_EVENTREPORT'
export const KEY_MANAGEMENT = 'KEY_MANAGEMENT'
export const KEY_INCOMING_ORDER = 'KEY_INCOMING_ORDER'
export const KEY_GOODS_LOG = 'KEY_GOODS_LOG'

export const ORDER_STATUS = {
  NEW: "신규",
  RECEIPT: "접수",
  COMPLETE: "발송",
  CANCEL: "취소",
  CANCEL_COMPLETE: "취소완료"
}

export const EVENT_STATUS = {
  NEW: "신규",
  RECEIPT: "접수",
  MODIFY: "수정",
  COMPLETE: "완료",
  CANCEL: "취소",
  CANCEL_COMPLETE: "취소완료"
}

export const AreaCity = [
  { id: "e4b5c1f0-1a2b-4c3d-9e8f-123456789abc", name: "서울" },
  { id: "a1b2c3d4-e5f6-7g8h-9i0j-abcdefghijk", name: "경기" },
  { id: "f1e2d3c4-b5a6-7890-1234-56789abcdefg", name: "부산" },
  { id: "12345678-90ab-cdef-1234-567890abcdef", name: "대구" },
  { id: "abcdef12-3456-7890-abcd-ef1234567890", name: "인천" },
  { id: "0f1e2d3c-4b5a-6789-0123-456789abcdef", name: "광주" },
  { id: "9a8b7c6d-5e4f-3210-abcd-ef1234567890", name: "대전" },
  { id: "1a2b3c4d-5e6f-7890-1234-abcdefabcdef", name: "울산" },
  { id: "abcdefab-cdef-1234-5678-90abcdef1234", name: "강원" },
  { id: "1234abcd-5678-90ef-abcd-ef1234567890", name: "충북" },
  { id: "abcdef12-3456-7890-abcd-1234567890ef", name: "충남" },
  { id: "abcd1234-5678-90ef-abcdefabcdef12", name: "전북" },
  { id: "90abcdef-1234-5678-abcdefabcdef12", name: "전남" },
  { id: "7890abcd-ef12-3456-abcdefabcdef12", name: "경북" },
  { id: "5678abcd-ef12-3456-7890abcdef1234", name: "경남" },
  { id: "3456abcd-ef12-7890-abcd-ef1234567890", name: "제주" }
];

export const BoothType2 = [
  { id: "f7a9d8c2-3b4e-5f6g-7h8i-9j0klmnopqrs", name: "1부스" },
  { id: "c1d2e3f4-a5b6-7g8h-9i0j-klmnopqrstuv", name: "2부스" },
  { id: "a9b8c7d6-e5f4-3210-9876-54321abcdefg", name: "3부스" },
  { id: "0a1b2c3d-4e5f-6789-abcd-ef0123456789", name: "4부스" },
  { id: "abcdef01-2345-6789-0abc-def123456789", name: "1/2 부스" },
  { id: "9f8e7d6c-5b4a-3210-9876-54321abcdefg", name: "영산" },
  { id: "12345678-90ab-cdef-1234-567890abcdef", name: "기타" }
];
