import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "@mui/material/Container";
import { useNavigate, useParams } from "react-router-dom";
// import { useSelector } from "react-redux";
import { clientDeleteFunc, getClientFunc } from "../../../api";
import { toast } from "react-toastify";
import { memo, useEffect, useState } from "react";

const PartnerView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  // const client = useSelector((state) => state.client);
  const [client, setClient] = useState([])
  // 회사정보
  const clientGet = async (id) => {
    const response = await getClientFunc(id);
    if (response.status === 200) {
      setClient(() => response.data);

      // setPart(response.data.department);
    } else {
      toast.error("Client 정보를 가져오지 못했습니다.");
    }
  };

  //NOTE - 팀원의 정보중 client 검색
  useEffect(() => {
    clientGet(id);
  }, [id]);
  const handleDelete = async () => {
    if (window.confirm("삭제하시겠습니까?")) {
      const response = await clientDeleteFunc(id);
      if (response.status === 200) {
        setTimeout(() => {
          toast.success("Successfully Delete!");
          navigate("/partnerstable", { replace: true });
        }, 1000);
      } else {
        toast.error("거래처정보 삭제실패!");
      }
    }
  };

  return (
    <>
      <Container maxWidth='md' className='py-3'>
        <Row className='fs-1 fw-bold p-2 border-bottom mb-5'>{client ? client.companyName : "거래처"} 정보</Row>

        <Row className=' my-5'>
          <Col className='text-start '>
            <Button variant='outline-secondary' active className='px-4 mx-2'>
              거래처정보
            </Button>{" "}
            <Button
              variant='outline-secondary'
              onClick={() => {
                navigate("/areaPrice/" + id);
              }}
              className='px-4 mx-2'
            >
              견적 금액 정보
            </Button>
            <Button
              variant='outline-secondary'
              onClick={() => {
                navigate("/partnerShippingInfo/" + id);
              }}
              className='px-4 mx-2'
            >
              배송지정보
            </Button>{" "}
            <Button
              variant='outline-secondary'
              onClick={() => {
                navigate("/departmentInfo/" + id);
              }}
              className='px-4 mx-2'
            >
              부서 / 제품 정보
            </Button>{" "}

          </Col>
        </Row>
        <Form>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2' className="font-bold">
              업체명
            </Form.Label>
            <Col sm='9'>
              <Form.Label column sm='9'>
                {client?.companyName || ""}
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3 px-3  '>
            <Form.Label column sm='2' className="font-bold">
              회사코드
            </Form.Label>
            <Col sm='9'>
              <Form.Label column sm='9'>
                {client.companyCode || ""}
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2' className="font-bold">
              대표ID
            </Form.Label>
            <Col sm='7'>
              <Form.Label column sm='9' >
                {client.daepyoId || ""}
              </Form.Label>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2' className="font-bold">
              대표자명
            </Form.Label>
            <Col sm='9'>
              <Form.Label column sm='9'>
                {client.ceoName || ""}
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2' className="font-bold">
              사업자번호
            </Form.Label>
            <Col sm='9'>
              <Form.Label column sm='9'>
                {client.companyNumber || ""}
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2' className="font-bold">
              법인번호
            </Form.Label>
            <Col sm='9'>
              <Form.Label column sm='9'>
                {client.corporationNumber || ""}
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3 px-3'  >
            <Form.Label column sm='2' className="font-bold">
              업종
            </Form.Label>
            <Col sm='9'>
              <Form.Label column sm='9'>
                {client.sectors || ""}
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2' className="font-bold">
              업태
            </Form.Label>
            <Col sm='9'>
              <Form.Label column sm='9'>
                {client.business || ""}
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2' className="font-bold">
              대표전화
            </Form.Label>
            <Col sm='9'>
              <Form.Label column sm='9'>
                {client.telNumber || ""}
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2' className="font-bold">
              팩스번호
            </Form.Label>
            <Col sm='9'>
              <Form.Label column sm='9'>
                {client.faxNumber || ""}
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2' className="font-bold">
              주소
            </Form.Label>
            <Col sm='9'>
              <Form.Label column sm='9'>
                {client.addr1 || ""} {client.addr2 || ""}
              </Form.Label>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2' className="font-bold">
              로고
            </Form.Label>
            <Col sm='9'>
              <Form.Label column sm='9'>
                {client?.logoImage && (
                  <img src={client.logoImage} alt='logo' width={200} />
                )}
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3 px-3 '>
            <Form.Label column sm='2' className="font-bold">
              홈페이지
            </Form.Label>
            <Col sm='9'>
              <Form.Label column sm='9' className="">
                {client.homePage || ""}
              </Form.Label>
            </Col>
          </Form.Group>
          <Row className=' mt-5'>
            <Col className='text-start '>
              <Button
                type="button"
                variant='outline-danger'
                onClick={handleDelete}
                className='px-4 mx-3'
              >
                삭제
              </Button>{" "}
            </Col>
            <Col className='text-end '>
              <Button
                type="button"
                variant='outline-secondary'
                className='px-4 mx-3'
                onClick={() => navigate("/partnerstable")}
              >
                목 록
              </Button>{" "}
              <Button
                type="button"
                variant='outline-secondary'
                className='px-4 mr-3'
                onClick={() => navigate(`/partnereditpassword/${id}`)}
              >
                비밀번호 변경
              </Button>{" "}
              <Button
                as='input'
                type="button"
                value='수정'
                className='px-4'
                onClick={() => navigate(`/partneredit/${id}`)}
              />{" "}
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default memo(PartnerView);
