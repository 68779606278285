import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import HeardSearch from "../../../components/HeardSearch";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, InputGroup, Pagination, Row, Table } from "react-bootstrap";
import { memo, useEffect, useState } from "react";
import { areaPricesRegisterFunc, configPricesRegisterFunc, getAllAreaPriceFunc, getAllboothtypeFunc, getAllConfigCostsFunc, getClientFunc } from "../../../api";
import { toast } from "react-toastify";
import Pagenation from "../../../components/Pagenation";
import { AreaCity } from "../../../utils/constant";
import { formatLargeNumber } from "../../../utils";
import { ORDER_BY } from "../../../utils/type";
import Form from "react-bootstrap/Form";

const ConfigPrice = () => {
  const navigate = useNavigate();
  const { id } = useParams()
  const [prices, setPrices] = useState({})
  const headColumn = ["번호", "항 목", "고객사 견적 금액", "제주도/도서산간 "];
  const headColumnBox = ["번호", "항 목", "고객사 견적 금액"];

  useEffect(() => {
    // 전체회사 리스트
    const allBoothtypeGet = async () => {
      const response = await getAllConfigCostsFunc(id);

      if (response.status === 200) {
        const filteredPrices = { ...response?.data?.data };

        // Xóa các trường không cần thiết
        delete filteredPrices._id;
        delete filteredPrices.clientId;
        delete filteredPrices.__v;
        delete filteredPrices.createdAt;
        delete filteredPrices.updatedAt;

        setPrices(filteredPrices);
      } else {
        toast.error("부스종류를 가져오는데 실패했습니다.");
      }
    };
    allBoothtypeGet();
  }, []);

  const handleInputChange = (id, price) => {
    setPrices((prev) => ({ ...prev, [id]: price }));
  }

  const handleSubmit = async () => {
    const response = await configPricesRegisterFunc({
      clientId: id,
      ...prices
    });

    if (response.status === 200) {
      toast.success("Successfully Update!");
      navigate("/areaPrice/" + id, { replace: true, state: { selectSection: 1 } });
    } else {
      toast.error("거래처정보 변경실패!!");
    }
  };

  return (
    <>
      <Container className='align-items-center justify-content-center px-4 py-1'>
        {/* <HeardSearch searchForm={searchForm} /> */}
        <Row className="mt-3">
          <h3>1. 발송 비용</h3>
        </Row>
        <Table striped bordered hover className="mt-1">
          <thead>
            <tr>
              {headColumn.map((it, idx) => (
                <th className='text-center' key={idx}>
                  {it}
                  {idx === 3 &&
                    <>
                      (추가비용)
                    </>
                  }
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr
              className='text-center align-middle'
              style={{ cursor: "pointer" }}
            >
              <td>{1}</td>
              <td>발송 비용</td>
              <td>
                <Form.Group as={Col} className="px-3" style={{ flexWrap: "nowrap" }}>
                  <Row sm="3">
                    <InputGroup className="mb-2" size="sm">
                      <InputGroup.Text style={{ width: "50px", justifyContent: "center" }}> 이형 </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="0"
                        name="parcelOddShaped"
                        size="sm"
                        min={0}
                        value={prices["parcelOddShaped"] !== undefined ?
                          formatLargeNumber(prices["parcelOddShaped"]?.toString())
                          : ""}

                        onChange={(e) => {
                          let rawValue = e.target.value.replace(/,/g, "");
                          rawValue = rawValue.replace(/^0+/, "");

                          const regex = /^\d+$/;
                          if (!regex.test(rawValue) && rawValue !== "") return;

                          handleInputChange(
                            "parcelOddShaped",
                            rawValue,
                          )
                        }}
                        style={{ textAlign: "center" }}
                      />
                    </InputGroup>
                  </Row>
                  <Row sm="3">
                    <InputGroup className="mb-2" size="sm">
                      <InputGroup.Text style={{ width: "50px", justifyContent: "center" }}> 대 </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="0"
                        name="parcelLarge"
                        size="sm"
                        min={0}
                        value={prices["parcelLarge"] !== undefined ?
                          formatLargeNumber(prices["parcelLarge"]?.toString())
                          : ""}

                        onChange={(e) => {
                          let rawValue = e.target.value.replace(/,/g, "");
                          rawValue = rawValue.replace(/^0+/, "");

                          const regex = /^\d+$/;
                          if (!regex.test(rawValue) && rawValue !== "") return;

                          handleInputChange(
                            "parcelLarge",
                            rawValue,
                          )
                        }}
                        style={{ textAlign: "center" }}
                      />
                    </InputGroup>
                  </Row>
                  <Row sm="3">
                    <InputGroup className="mb-2" size="sm">
                      <InputGroup.Text style={{ width: "50px", justifyContent: "center" }}> 중 </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="0"
                        name="parcelMedium"
                        size="sm"
                        min={0}
                        value={prices["parcelMedium"] !== undefined ?
                          formatLargeNumber(prices["parcelMedium"]?.toString())
                          : ""}

                        onChange={(e) => {
                          let rawValue = e.target.value.replace(/,/g, "");
                          rawValue = rawValue.replace(/^0+/, "");

                          const regex = /^\d+$/;
                          if (!regex.test(rawValue) && rawValue !== "") return;

                          handleInputChange(
                            "parcelMedium",
                            rawValue,
                          )
                        }}
                        style={{ textAlign: "center" }}
                      />
                    </InputGroup>
                  </Row>
                  <Row sm="3">
                    <InputGroup className="mb-2" size="sm">
                      <InputGroup.Text style={{ width: "50px", justifyContent: "center" }}> 소 </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="0"
                        name="parcelSmall"
                        size="sm"
                        min={0}
                        value={prices["parcelSmall"] !== undefined ?
                          formatLargeNumber(prices["parcelSmall"]?.toString())
                          : ""}

                        onChange={(e) => {
                          let rawValue = e.target.value.replace(/,/g, "");
                          rawValue = rawValue.replace(/^0+/, "");

                          const regex = /^\d+$/;
                          if (!regex.test(rawValue) && rawValue !== "") return;

                          handleInputChange(
                            "parcelSmall",
                            rawValue,
                          )
                        }}
                        style={{ textAlign: "center" }}
                      />
                    </InputGroup>
                  </Row>
                  <Row sm="3">
                    <InputGroup size="sm">
                      <InputGroup.Text style={{ width: "50px", justifyContent: "center" }}> 극소 </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="0"
                        name="parcelMini"
                        size="sm"
                        min={0}
                        value={prices["parcelMini"] !== undefined ?
                          formatLargeNumber(prices["parcelMini"]?.toString())
                          : ""}

                        onChange={(e) => {
                          let rawValue = e.target.value.replace(/,/g, "");
                          rawValue = rawValue.replace(/^0+/, "");

                          const regex = /^\d+$/;
                          if (!regex.test(rawValue) && rawValue !== "") return;

                          handleInputChange(
                            "parcelMini",
                            rawValue,
                          )
                        }}
                        style={{ textAlign: "center" }}
                      />
                    </InputGroup>
                  </Row>
                </Form.Group>
              </td>
              <td>
                <Form.Group as={Col} className="px-3" style={{ flexWrap: "nowrap" }}>
                  <Row sm="3">
                    <InputGroup size="sm">
                      {/* <InputGroup.Text style={{ width: "50px", justifyContent: "center" }}> 이형 </InputGroup.Text> */}
                      <Form.Control
                        type="text"
                        placeholder="0"
                        name="additionalCosts"
                        size="sm"
                        min={0}
                        value={prices["additionalCosts"] !== undefined ?
                          formatLargeNumber(prices["additionalCosts"]?.toString())
                          : ""}

                        onChange={(e) => {
                          let rawValue = e.target.value.replace(/,/g, "");
                          rawValue = rawValue.replace(/^0+/, "");

                          const regex = /^\d+$/;
                          if (!regex.test(rawValue) && rawValue !== "") return;

                          handleInputChange(
                            "additionalCosts",
                            rawValue,
                          )
                        }}
                        style={{ textAlign: "center" }}
                      />
                    </InputGroup>
                  </Row>
                </Form.Group>
              </td>
            </tr>
          </tbody>
        </Table>


        <Row className="mt-5">
          <h3>2. 재포장/박스 비용</h3>
        </Row>
        <Table striped bordered hover className="mt-1">
          <thead>
            <tr>
              {headColumnBox.map((it, idx) => (
                <th className='text-center' key={idx}>
                  {it}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr
              className='text-center align-middle'
              style={{ cursor: "pointer" }}
            >
              <td>{2}</td>
              <td>재포장(박스)비용</td>
              <td>
                <Form.Group as={Col} className="px-3" style={{ flexWrap: "nowrap" }}>
                  <Row sm="3">
                    <InputGroup className="mb-2" size="sm">
                      <InputGroup.Text style={{ width: "50px", justifyContent: "center" }}> 대 </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="0"
                        name="boxLarge"
                        size="sm"
                        min={0}
                        value={prices["boxLarge"] !== undefined ?
                          formatLargeNumber(prices["boxLarge"]?.toString())
                          : ""}

                        onChange={(e) => {
                          let rawValue = e.target.value.replace(/,/g, "");
                          rawValue = rawValue.replace(/^0+/, "");

                          const regex = /^\d+$/;
                          if (!regex.test(rawValue) && rawValue !== "") return;

                          handleInputChange(
                            "boxLarge",
                            rawValue,
                          )
                        }}
                        style={{ textAlign: "center" }}
                      />
                    </InputGroup>
                  </Row>
                  <Row sm="3">
                    <InputGroup className="mb-2" size="sm">
                      <InputGroup.Text style={{ width: "50px", justifyContent: "center" }}> 중 </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="0"
                        name="boxMedium"
                        size="sm"
                        min={0}
                        value={prices["boxMedium"] !== undefined ?
                          formatLargeNumber(prices["boxMedium"]?.toString())
                          : ""}

                        onChange={(e) => {
                          let rawValue = e.target.value.replace(/,/g, "");
                          rawValue = rawValue.replace(/^0+/, "");

                          const regex = /^\d+$/;
                          if (!regex.test(rawValue) && rawValue !== "") return;

                          handleInputChange(
                            "boxMedium",
                            rawValue,
                          )
                        }}
                        style={{ textAlign: "center" }}
                      />
                    </InputGroup>
                  </Row>
                  <Row sm="3">
                    <InputGroup size="sm">
                      <InputGroup.Text style={{ width: "50px", justifyContent: "center" }}> 소 </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="0"
                        name="boxSmall"
                        size="sm"
                        min={0}
                        value={prices["boxSmall"] !== undefined ?
                          formatLargeNumber(prices["boxSmall"]?.toString())
                          : ""}

                        onChange={(e) => {
                          let rawValue = e.target.value.replace(/,/g, "");
                          rawValue = rawValue.replace(/^0+/, "");

                          const regex = /^\d+$/;
                          if (!regex.test(rawValue) && rawValue !== "") return;

                          handleInputChange(
                            "boxSmall",
                            rawValue,
                          )
                        }}
                        style={{ textAlign: "center" }}
                      />
                    </InputGroup>
                  </Row>

                </Form.Group>
              </td>
            </tr>
          </tbody>
        </Table>


        <Row className="mt-5">
          <h3>3. 기타 비용</h3>
        </Row>
        <Table striped bordered hover className="mt-1">
          <thead>
            <tr>
              {headColumnBox.map((it, idx) => (
                <th className='text-center' key={idx}>
                  {it}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr
              className='text-center align-middle'
              style={{ cursor: "pointer" }}
            >
              <td>{3}</td>
              <td>창고 보관 비용</td>
              <td>
                <Form.Group as={Col} className="px-3" style={{ flexWrap: "nowrap" }}>
                  <Row sm="5">
                    <InputGroup size="sm">
                      <Form.Control
                        type="text"
                        placeholder="0"
                        name="storageCosts"
                        size="sm"
                        min={0}
                        value={prices["storageCosts"] !== undefined ?
                          formatLargeNumber(prices["storageCosts"]?.toString())
                          : ""}

                        onChange={(e) => {
                          let rawValue = e.target.value.replace(/,/g, "");
                          rawValue = rawValue.replace(/^0+/, "");

                          const regex = /^\d+$/;
                          if (!regex.test(rawValue) && rawValue !== "") return;

                          handleInputChange(
                            "storageCosts",
                            rawValue,
                          )
                        }}
                        style={{ textAlign: "center" }}
                      />
                    </InputGroup>
                  </Row>

                </Form.Group>
              </td>
            </tr>
            <tr
              className='text-center align-middle'
              style={{ cursor: "pointer" }}
            >
              <td>{4}</td>
              <td>시스템 이용 비용</td>
              <td>
                <Form.Group as={Col} className="px-3" style={{ flexWrap: "nowrap" }}>
                  <Row sm="3">
                    <InputGroup size="sm">
                      {/* <InputGroup.Text style={{ width: "50px", justifyContent: "center" }}> 대 </InputGroup.Text> */}
                      <Form.Control
                        type="text"
                        placeholder="0"
                        name="usageCosts"
                        size="sm"
                        min={0}
                        value={prices["usageCosts"] !== undefined ?
                          formatLargeNumber(prices["usageCosts"]?.toString())
                          : ""}

                        onChange={(e) => {
                          let rawValue = e.target.value.replace(/,/g, "");
                          rawValue = rawValue.replace(/^0+/, "");

                          const regex = /^\d+$/;
                          if (!regex.test(rawValue) && rawValue !== "") return;

                          handleInputChange(
                            "usageCosts",
                            rawValue,
                          )
                        }}
                        style={{ textAlign: "center" }}
                      />
                    </InputGroup>
                  </Row>

                </Form.Group>
              </td>
            </tr>
          </tbody>
        </Table>
        <Row className='mt-5 mb-3 text-end'>
          <Col>
            {/* <Button
              variant="outline-secondary"
              className="px-4 mx-3"
              onClick={() => {
                navigate("/areaPrice/" + id, { replace: true, state: { selectSection: 1 } });
              }}
            >
              목 록
            </Button> */}
            <Button
              as='input'
              type='submit'
              value={"수정"}
              className='px-4'
              onClick={handleSubmit}
            />
          </Col>
        </Row>

      </Container>
    </>
  );
};

export default memo(ConfigPrice);
