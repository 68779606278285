import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "@mui/material/Container";
import { useState, useRef, useEffect, memo } from "react";
import JoditEditor from "jodit-react";
import { useNavigate, useParams } from "react-router-dom";
import {
  boothtypeDeleteFunc,
  boothtypeUpdateFunc,
  getBoothtypeFunc,
} from "../../../api";
import { toast } from "react-toastify";
import { BoothType2 } from "../../../utils/constant";

const BoothTypeEdit = () => {
  const editor = useRef(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const [boothName, setboothName] = useState("");
  const [content, setContent] = useState("");
  const [selectData, setSelectData] = useState();
  const [edit, setEdit] = useState(false);
  const [selectBoothType2, setSelectBoothType2] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (edit === false) {
      setEdit(true);
      return;
    }
    const formData = new FormData();
    formData.append("boothType", selectData);
    formData.append("boothName", boothName);
    formData.append("boothContent", content);
    formData.append("boothType2Id", selectBoothType2 || BoothType2?.[0]?.id);

    const boothType2 = BoothType2.find((item) => item.id === selectBoothType2)
    formData.append("boothType2", boothType2?.name || BoothType2?.[0]?.name);

    const config = {
      "Content-Type": "multipart/form-data",
    };
    const res = await boothtypeUpdateFunc(id, formData, config);
    if (res.status === 200) {
      setTimeout(() => {
        toast.success("Successfully Update!");
      }, 1000);
      navigate("/boothtype", { replace: true });
    } else {
      toast.error("부스종류 변경실패!!");
    }
  };



  useEffect(() => {
    const boothTypeGet = async (id) => {
      const res = await getBoothtypeFunc(id);
      if (res.status === 200) {
        setboothName(res.data.boothName);
        setContent(res.data.boothContent);
        setSelectData(res.data.boothType);
        setSelectBoothType2(res.data.boothType2Id)
      } else {
        toast.error("부스종류 정보를 가져오지 못했습니다.");
      }
    };
    boothTypeGet(id);
  }, [id]);

  const handleDelete = async () => {
    const res = await boothtypeDeleteFunc(id);
    if (res.status === 200) {
      setTimeout(() => {
        toast.success("Successfully Delete!");
        navigate("/boothtype", { replace: true });
      }, 1000);
    } else {
      toast.error("부스종류 삭제실패!");
    }
  };

  const jodiconfig = {
    disabled: !edit,
  };

  return (
    <>
      <Container maxWidth='md' className='py-3'>
        <Row className='fs-1 fw-bold p-2 border-bottom mb-5'>
          부스종류관리 {edit ? "수정" : "뷰"}
        </Row>
        <Form onSubmit={handleSubmit}>
          {!edit ? (
            <Form.Group as={Row} className='mb-3 px-3 align-items-center'>
              <Form.Label column sm='2'>
                부스 종류 1
              </Form.Label>
              <Col sm='9'>{selectData}</Col>
            </Form.Group>
          ) : (
            <Form.Group as={Row} className='mb-3 px-3 align-items-center'>
              <Form.Label column sm='2'>
                부스 종류 1
              </Form.Label>
              <Col sm='9'>
                <div
                  onChange={(e) => {
                    setSelectData(e.target.value);
                  }}
                >
                  {["Truss", "Block", "Steel", "Led", "Fabric", "기타"].map(
                    (type, idx) => (
                      <Form.Check
                        key={idx}
                        inline
                        label={`${type}`}
                        name='group1'
                        type='radio'
                        value={`${type}`}
                        defaultChecked={type === selectData}
                        disabled={!edit}
                      />
                    )
                  )}
                </div>
              </Col>
            </Form.Group>
          )}

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              부스 종류 2
            </Form.Label>
            <Col sm='3'>
              <Form.Select aria-label="Default select example"
                value={selectBoothType2}
                onChange={(e) => setSelectBoothType2(e.target.value)}
                disabled={!edit}>
                {
                  BoothType2?.map((item, index) =>
                    <option value={item.id}>{item?.name}</option>
                  )
                }
              </Form.Select>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              부스 형태
            </Form.Label>
            <Col sm='3'>
              <Form.Control
                type='text'
                name='bootName'
                value={boothName}
                size='sm'
                onChange={(e) => setboothName(e.target.value)}
                disabled={!edit}
                required
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              내용
            </Form.Label>
            <Col sm='12'>
              <JoditEditor
                name='bootContent'
                ref={editor}
                value={content}
                onChange={(newContent) => setContent(newContent)}
                config={!edit ? jodiconfig : ""}
              />
            </Col>
          </Form.Group>

          <Row>
            {!edit && <Col className='text-start m-5 '>
              <Button
                variant='outline-secondary'
                className='px-4 mx-3'
                onClick={handleDelete}
              >
                삭제
              </Button>{" "}
            </Col>}
            <Col className='text-end m-5 '>
              <Button
                variant='outline-secondary'
                className='px-4 mx-3'
                onClick={() => navigate("/boothtype")}
              >
                {!edit ? "목 록" : "취 소"}
              </Button>{" "}
              <Button
                as='input'
                type='submit'
                value={edit ? "저장" : "수정"}
                className='px-4'
              />{" "}
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default memo(BoothTypeEdit);
