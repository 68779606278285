import React, { memo } from 'react'
import { Button, Col, Pagination, Row, Table } from 'react-bootstrap'
import { AreaCity } from '../../../utils/constant';

const AreaTable = ({ id, navigate }) => {

  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr className='text-center'>
            <th>번호</th>
            <th>지역</th>
          </tr>
        </thead>
        <tbody>
          {/* TODO:  */}
          {AreaCity &&
            AreaCity.map((it, idx) => (
              <tr
                key={it.id}
                className='text-center '
                style={{ verticalAlign: "middle", cursor: "pointer" }}
                onClick={() => navigate("/areaPriceRegister/" + id + "/" + it.id)}
              >
          <td  >{idx + 1}</td>
          <td >{it.name}</td>
        </tr>
            ))}
      </tbody>
    </Table >
      <Row className=' mt-5'>
        <Col className='text-end '>
        </Col>
      </Row>

    </>
  )
}

export default memo(AreaTable)