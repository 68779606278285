import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import HeardSearch from "../../../components/HeardSearch";
import { useNavigate } from "react-router-dom";
import { Button, Col, Pagination, Row, Table } from "react-bootstrap";
import { memo, useEffect, useState } from "react";
import { toast } from "react-toastify";
import ManageMentHead from "./ManageMentHead";
import { getAllboothFunc } from "../../../api";
import Pagenation from "../../../components/Pagenation";
import { KEY_BOOTHLIST } from "../../../utils/constant";
import useStoreLocal from "../../../hook/useStoreLocal.hook";

const ManageMent = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([])
  console.log("🚀 ~ Receiving ~ data:", data)
  const [searchStartDay, setSearchStartDay] = useState("")
  const [searchEndDay, setSearchEndDay] = useState("")
  const [sendStartDay, setSendStartDay] = useState("")
  const [sendEndDay, setSendEndDay] = useState("")
  const [selectClient, setSelectClient] = useState("All")
  const [selectDepartment, setSelectDepartment] = useState("All")
  const [selectProduct, setSelectProduct] = useState("All")
  const [search, setSearch] = useState("")
  const [searchState, setSearchState] = useState({
    신규: false,
    접수: false,
    발송: false,
    취소: false,
  })
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [totlaCount, setTotalCount] = useState({
    total: 0,
    pageItem: 0,
  });
  const hendleChangePage = () => {
    navigate("/boothRegister");
  };
  const [searchQuery, setSearchQuery, backPage, setValueBackPage] = useStoreLocal(KEY_BOOTHLIST, {});

  const headColumn = [
    "번호",
    "날짜",
    "거래처명",
    "부서명",
    "제품명",
    "부스종류",
    "부스명",
  ];
  const myButton = [
    { variant: "primary", name: "부스등록", onClick: hendleChangePage },
  ];

  const searchData = { selectClient, selectDepartment, selectProduct, search, page }

  const allBoothGet = async (searchData) => {
    const response = await getAllboothFunc(searchData);
    if (response.status === 200) {
      setData(() => response?.data?.data);
      setPageCount(() =>
        response?.data?.Pagination.pageCount
      );
      setTotalCount((totlaCount) => ({
        ...totlaCount,
        total: response?.data?.Pagination.count,
        pageItem: response?.data?.Pagination.pageItem,
      }));
    } else {
      toast.error("error for get Booth data");
    }
  };

  useEffect(() => {
    if (!backPage) {
      allBoothGet(searchData);
    }
    setSearchQuery({ ...searchData, ...searchQuery, page: page })
  }, [page]);

  useEffect(() => {
    if (backPage) {
      const {
        selectClient,
        selectDepartment,
        selectProduct,
        search,
        page
      } = searchQuery;

      selectClient && setSelectClient(selectClient);
      selectDepartment && setSelectDepartment(selectDepartment);
      selectProduct && setSelectProduct(selectProduct);
      search && setSearch(search);
      page && setPage(page);

      allBoothGet({ ...searchData, ...searchQuery });
      setValueBackPage(false);
    }
  }, [searchQuery, backPage]);

  const handleSearchFnc = async (e) => {
    e.preventDefault();
    setPage(1);
    handleSearchChange();
    allBoothGet(searchData);
  }

  const handleSearchChange = () => {
    setSearchQuery(searchData);
  };

  return (
    <>
      <Container className='align-items-center justify-content-center p-5'>
        <ManageMentHead
          handleSearchFnc={handleSearchFnc}
          setSelectClient={setSelectClient}
          selectClient={selectClient}
          setSelectDepartment={setSelectDepartment}
          selectDepartment={selectDepartment}
          setSelectProduct={setSelectProduct}
          selectProduct={selectProduct}

          setSearchStartDay={setSearchStartDay}
          searchStartDay={searchStartDay}
          setSearchEndDay={setSearchEndDay}
          searchEndDay={searchEndDay}
          setSearchState={setSearchState}
          searchState={searchState}

          setSearch={setSearch}
          search={search} />

        <Table striped bordered hover>
          <thead>
            <tr>
              {headColumn.map((it, idx) => (
                <th className='text-center' key={idx}>
                  {it}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((it, idx) => (
              <tr
                key={it._id}
                className='text-center align-middle'
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/boothview/" + it._id)}
              >
                <td>{totlaCount?.total -
                  (page - 1) * totlaCount?.pageItem -
                  idx}</td>
                {it?.createdAt ? <td>{it.createdAt.slice(0, 10)}</td> : <td></td>}
                {it?.clientName ? <td>{it.clientName}</td> : <td></td>}
                {it?.department ? <td>{it.department}</td> : <td></td>}
                {it?.productName ? <td>{it.productName}</td> : <td></td>}
                {it?.boothType ? <td>{it.boothType}/{it?.boothTypeId ? it?.boothTypeId?.boothType2 : ''}/{it?.boothTypeId ? it?.boothTypeId?.boothName : ''}</td> : <td></td>}
                {it?.boothName ? <td>{it.boothName}</td> : <td></td>}
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagenation
          pageCount={pageCount}
          page={page}
          setPage={setPage}
        />
        <Row className='mt-3 mb-3 text-end'>
          <Col>
            {myButton.map((it, idx) => (
              <Button
                key={idx}
                variant={it.variant}
                onClick={it.onClick ? it.onClick : null}
                className='px-4 mx-2'
              >
                {it.name}
              </Button>
            ))}
          </Col>
        </Row>

      </Container>
    </>
  );
};

export default memo(ManageMent);
