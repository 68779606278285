import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getEventRequestItemFunc } from "../api";
import image from "../assets/imageCommit.png"
import { formatDateToKorean, formatLargeNumber } from "../utils";
import { calculateTotalCost } from "../scenes/eventmanagement/EventFormDetail";

const PrintEventStatement = React.forwardRef((props, ref) => {
  const { id } = useParams();
  const [parcel, setParcel] = useState({ oddShaped: "", parcelLarge: "", parcelMedium: "", parcelSmall: "", parcelMini: "", parcelTotal: "" })
  const [data, setData] = useState([]);
  const [shippingBox, setShippingBox] = useState({ boxLarge: "", boxMedium: "", boxSmall: "", boxTotal: "" })
  const [quickService, setQuickService] = useState({ damas: "", motorcycle: "", quickTotal: "" })
  const [extraFee, setExtraFee] = useState({ reasonFee1: "", fee1: "", reasonFee2: "", fee2: "" })
  const [boxPrices, setBoxPrices] = useState(props.boxPrices)
  const [parcelPrices, setParcelPrices] = useState();
  const [shippingBoxArr, setShippingBoxArr] = useState();
  const [otherCost, setOtherCost] = useState(props?.otherCost);
  const [totalBoothPrices, setTotalBoothPrices] = useState(props?.totalBoothPrices ?? 0);
  const [resonOther, setResonOther] = useState();

  const totalCost = (
    calculateTotalCost(otherCost, data) +
    Number(parcel.parcelTotal) +
    Number(shippingBox.boxTotal) +
    Number(quickService?.quickTotal) +
    Number(extraFee.fee1) +
    Number(extraFee.fee2) +
    totalBoothPrices
  );

  useEffect(() => {
    setTotalBoothPrices(props?.totalBoothPrices ?? 0);
    setOtherCost(props?.otherCost);
  }, [props?.totalBoothPrices, props?.otherCost]);

  useEffect(() => {
    const eventRequestItemGet = async (id) => {
      const response = await getEventRequestItemFunc(id);
      if (response.status === 200) {
        response.data.updatedAt = response.data?.updatedAt?.slice(0, 10);
        response.data?.parcel && setParcel(JSON.parse(response.data?.parcel))

        response.data?.parcel && setParcel(JSON.parse(response.data?.parcel))
        response.data?.shippingBox && setShippingBox(JSON.parse(response.data?.shippingBox))
        response.data?.quickService && setQuickService(JSON.parse(response.data?.quickService))
        response?.data?.extraFee && setExtraFee(JSON.parse(response?.data?.extraFee))
        setData(response.data);
      } else {
        console.log("error");
      }
    };
    eventRequestItemGet(id);
  }, [id]);

  useEffect(() => {
    const labels = {
      oddShaped: "이형",
      parcelLarge: "대",
      parcelMedium: "중",
      parcelSmall: "소",
      parcelMini: "극소"
    };

    const labelsShipping = {
      boxLarge: "대",
      boxMedium: "중",
      boxSmall: "소",
    };

    const resultArray = Object.entries(props?.parcelPrices)
      .filter(([key, value]) => key !== "additionalCosts" && parcel?.[key] > 0)
      .map(([key, value]) => ({ key, value, text: labels[key] }));

    const resultArrayShippingBox = Object.entries(props?.boxPrices)
      .filter(([key, value]) => value > 0)
      .map(([key, value]) => ({ key, value, text: labelsShipping[key] }));

    console.log("error", Number(data?.numberKey));

    setParcelPrices(resultArray);
    setShippingBoxArr(resultArrayShippingBox);
  }, [id, props.parcelPrices, props.boxPrices])

  useEffect(() => {
    const transformedArray = Array.from({ length: 5 }, (_, i) => {
      const reasonKey = `otherReasonCost${i + 1}`;
      const costKey = `otherCost${i + 1}`;
      const numberKey = `otherCostNumber${i + 1}`;

      if (data?.[numberKey] === "0" && otherCost?.[reasonKey] === "") {
        return null;
      }

      return {
        key: otherCost[reasonKey],
        value: Number(otherCost?.[costKey]) * Number(data?.[numberKey]) ?? 0,
        unitPrice: Number(otherCost?.[costKey]) ?? 0,
        quantity: Number(data?.[numberKey]) ?? 0
      };
    }).filter(Boolean);

    if (otherCost.guestbookCost && otherCost.guestbookCost !== "0") {
      transformedArray.unshift({
        key: "방명록",
        value: Number(otherCost.guestbookCost) * Number(data?.guestBook) ?? 0,
        unitPrice: Number(otherCost?.guestbookCost) ?? 0,
        quantity: Number(data?.guestBook) ?? 0
      });
    }

    setResonOther(transformedArray);
  }, [id, data, otherCost])

  return (
    <div ref={ref}>
      <div key={1} className="print_wapper">
        <div className={`paper ${data?.goods?.length === 5 ? "paper_padding" : ""}`}>
          <div className="content" style={{
            border: "2px solid #000",
            borderRight: "1px solid #000",
            position: "relative"
          }}>
            <div style={{
              position: "absolute",
              top: "8px",
              right: "16px"
            }}>
              <img src={image} alt="" />
            </div>
            <div className="header border-bottom-none"
              style={{
                position: "relative",
                borderRight: "1px solid #000",
                borderBottom: "0"
              }}
            >
              <h1 style={{ margin: "0px" }}>거 래 명 세 표</h1>
            </div>

            <table className="table-event-statement"
              style={{
                width: "100%"
              }}
            >
              <tr className="statement-event">
                <td className="border-white-print" style={{
                  width: "8%",
                  borderTop: "1px solid #fff"
                }}>발급일 :</td>
                <td colSpan={2} className="border-white-print-top" style={{
                  width: "27%",
                  borderTop: "1px solid #fff",
                  maxWidth: "30%",
                  whiteSpace: "normal",
                  minWidth: "auto"
                }}>
                  <span>
                    {formatDateToKorean(data.shippingDate)}
                  </span>
                </td>
                <td rowSpan={6} style={{ width: "14%" }}>
                  <div className="group-suplier">
                    <span>
                      공
                    </span>
                    <span>
                      급
                    </span>
                    <span>
                      자
                    </span>
                  </div>
                </td>
                <td style={{ width: "9%" }}>등록번호</td>
                <td colSpan="3" style={{ width: "42%" }}>
                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}>
                    220-86-88486
                  </div>
                </td>
              </tr>

              <tr className="statement-event">
                <td className="border-white-print" style={{ width: "8%" }}></td>
                <td colSpan={2} className="" style={{ width: "27%", maxWidth: "30%", whiteSpace: "normal", minWidth: "auto" }}>
                  <span style={{
                    marginRight: "16px"
                  }}>
                    {data ? data?.staff?.client?.companyName : ""}
                  </span>
                  <span>
                    {data ? data?.staff?.staffName : ""}
                  </span>
                </td>
                <td className=" " style={{ width: "9%" }}>상 호</td>
                <td className=" " style={{ width: "14%" }}>㈜디자인티티</td>
                <td className=" " style={{ width: "14%" }}>성 명</td>
                <td className=" " style={{ width: "14%" }}>손건화</td>
              </tr>

              <tr className="statement-event">
                <td className="border-white-print" style={{ width: "8%" }}>신청코드 :</td>
                <td colSpan={2} style={{
                  width: "27%",
                  maxWidth: "30%",
                  whiteSpace: "normal",
                  minWidth: "auto"
                }}>
                  <span>
                    {data.eventCode}
                  </span>
                </td>
                <td style={{ width: "9%" }}>주    소</td>
                <td className="border-print-bottom-none"
                  colSpan="3"
                  style={{ width: "42%" }}
                >
                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start"
                  }}>
                    경기도 화성시 세자로 396번길 49(안녕동)
                  </div>
                </td>
              </tr>

              <tr className="statement-event">
                <td className="border-white-print" style={{ width: "8%" }}>신청명:</td>
                <td className="border-print-bottom-none" colSpan={2}
                  style={{
                    width: "27%",
                    maxWidth: "30%",
                    whiteSpace: "normal",
                    minWidth: "auto"
                  }}
                >
                  <span>
                    {data.eventName}
                  </span>
                </td>
                <td rowSpan="2" style={{ width: "9%" }}>업 태</td>
                <td rowSpan="2" style={{ width: "14%" }}>제조 부동산 및 임대업</td>
                <td rowSpan="2" style={{ width: "14%" }}>종 목</td>
                <td rowSpan="2" style={{ width: "14%" }}>전시부스, 의약외품 도소매업 등</td>
              </tr>

              <tr className="statement-event">
                <td className="border-white-print" style={{ width: "8%" }}>신청일자 :</td>
                <td className="border-top-0 border-print-bottom-none"
                  colSpan={2} style={{ width: "27%", maxWidth: "30%", whiteSpace: "normal", minWidth: "auto" }}>
                  <span>
                    {data.eventStart?.slice(0, 10)} ~ {data.eventEnd?.slice(0, 10)}
                  </span>
                </td>
              </tr>

              <tr className="statement-event">
                <td className="border-top-0" colSpan={3}
                  style={{
                    width: "35%",
                    borderLeft: "1px solid #fff",
                    paddingLeft: "8px",
                    textAlign: "start"
                  }}>
                  <span>
                    아래와 같이 공급합니다.
                  </span>
                </td>
                <td style={{ width: "9%" }}>전화 번호</td>
                <td colSpan="3" style={{ width: "32%" }}>
                  <div style={{
                    display: "flex",
                    alignItems: "center",

                    justifyContent: "center"
                  }}>
                    02-553-1233
                  </div>
                </td>
              </tr>

              <tr className="statement-event">
                <td className="border-top-0" colSpan={4}
                  style={{
                    width: "46%",
                    borderLeft: "1px solid #fff",
                    paddingLeft: "8px",
                    textAlign: "center",
                    border: '0'
                  }}>
                  <span>
                    합계 금액 (공급가액+세액)
                  </span>
                </td>
                <td style={{ width: "9%", fontWeight: "700", border: '0' }}>
                  <span className="won-statement">
                    ₩
                  </span>
                </td>
                <td colSpan="2" style={{ width: "14%", border: '0' }}>
                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "700",
                    justifyContent: "center",
                  }}>
                    {formatLargeNumber((totalCost + (totalCost * 0.1))?.toString())}
                  </div>
                </td>
                <td style={{ width: "14%", border: '0' }}>
                  <div style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "start",
                    fontWeight: "700"
                  }}>
                    원정
                  </div>
                </td>
              </tr>

              <tr className="statement-event" style={{ borderTop: "2px solid #000" }}>
                <td style={{ width: "8%", fontWeight: "700", borderLeft: "1px solid #fff" }}>순번</td>
                <td style={{ fontWeight: "700", width: "9%" }}>유 형</td>
                <td className="border-print-bottom-none"
                  style={{
                    fontWeight: "700",
                    width: "18%",
                    maxWidth: "30%",
                    whiteSpace: "normal",
                    minWidth: "auto"
                  }}
                >
                  <span>
                    항 목
                  </span>
                </td>
                <td style={{ fontWeight: "700", width: "9%" }}>세 부</td>
                <td style={{ fontWeight: "700", width: "9%" }}>수 량</td>
                <td style={{ fontWeight: "700", width: "14%" }}>단 가</td>
                <td style={{ fontWeight: "700", width: "14%" }}>공 급 가 액</td>
                <td style={{ fontWeight: "700", width: "14%" }}>세 액</td>
              </tr>

              {!!props?.boothPrices?.length && props.boothPrices?.map((item, idx) =>
                <>
                  <tr className="statement-event">
                    <td style={{ width: "8%", borderLeft: "1px solid #fff" }}>{idx + 1}</td>
                    <td style={{ width: "9%" }}>부스</td>
                    <td className="border-print-bottom-none"
                      style={{
                        width: "18%",
                        maxWidth: "30%",
                        whiteSpace: "normal",
                        minWidth: "auto"
                      }}
                    >
                      <span>
                        {item?.boothName}
                      </span>
                    </td>
                    <td style={{ width: "9%" }}>{item?.boothType}</td>

                    <td style={{ width: "9%" }}>{item?.count}</td>
                    <td style={{ width: "14%" }}>
                      {formatLargeNumber(item?.unitPrice1?.toString())}
                    </td>
                    <td style={{ width: "14%" }}>
                      {formatLargeNumber(item?.price?.toString())}
                    </td>
                    <td style={{ width: "14%" }}>
                      {formatLargeNumber((item?.price * 0.1).toFixed(item?.price > 100 ? 0 : 2)?.toString())}
                    </td>
                  </tr>
                </>
              )}

              {
                !!parcelPrices?.length && parcelPrices?.map(({ key, value, text }, index) =>
                  <>
                    <tr className="statement-event">
                      <td style={{ width: "8%", borderLeft: "1px solid #fff" }}>{props?.boothPrices?.length + 1 + index}</td>
                      <td style={{ width: "9%" }}>물류</td>
                      <td className="border-print-bottom-none"
                        style={{
                          width: "18%",
                          maxWidth: "30%",
                          whiteSpace: "normal",
                          minWidth: "auto"
                        }}
                      >
                        <span>
                          발송비용
                        </span>
                      </td>
                      <td style={{ width: "9%" }}>{text}</td>
                      <td style={{ width: "9%" }}>{parcel?.[key]}</td>
                      <td style={{ width: "14%" }}>{value}</td>
                      <td style={{ width: "14%" }}>
                        {(parcel?.[key] && props?.parcelPrices?.[key]) ? parcel?.[key] * props?.parcelPrices?.[key] : 0}
                      </td>
                      <td style={{ width: "14%" }}>
                        {(parcel?.[key] && props?.parcelPrices?.[key]) ? (parcel?.[key] * props?.parcelPrices?.[key] * 0.1).toFixed(2) : 0}
                      </td>
                    </tr>
                  </>
                )
              }

              {
                !!shippingBoxArr?.length && shippingBoxArr?.map(({ key, value, text }, index) =>
                  <>
                    <tr className="statement-event">
                      <td style={{ width: "8%", borderLeft: "1px solid #fff" }}>{props?.boothPrices?.length + parcelPrices.length + 1 + index}</td>
                      <td style={{ width: "9%" }}>물류</td>
                      <td className="border-print-bottom-none"
                        style={{
                          width: "18%",
                          maxWidth: "30%",
                          whiteSpace: "normal",
                          minWidth: "auto"
                        }}
                      >
                        <span>
                          재포장(박스)비용
                        </span>
                      </td>
                      <td style={{ width: "9%" }}>{text}</td>
                      <td style={{ width: "9%" }}>{shippingBox?.[key]}</td>
                      <td style={{ width: "14%" }}>{value}</td>
                      <td style={{ width: "14%" }}>
                        {(shippingBox?.[key] && props?.boxPrices?.[key]) ? shippingBox?.[key] * props?.boxPrices?.[key] : 0}
                      </td>
                      <td style={{ width: "14%" }}>
                        {(shippingBox?.[key] && props?.boxPrices?.[key]) ? (shippingBox?.[key] * props?.boxPrices?.[key] * 0.1).toFixed(2) : 0}
                      </td>
                    </tr>
                  </>
                )
              }

              {
                !!Number(extraFee?.fee1) > 0 &&
                <tr className="statement-event">
                  <td style={{ width: "8%", borderLeft: "1px solid #fff" }}>
                    {props?.boothPrices?.length + parcelPrices?.length + shippingBoxArr?.length + 1}
                  </td>
                  <td style={{ width: "9%" }}>물류</td>
                  <td className="border-print-bottom-none"
                    style={{
                      width: "18%",
                      maxWidth: "30%",
                      whiteSpace: "normal",
                      minWidth: "auto"
                    }}
                  >
                    <span>
                      기타
                    </span>
                  </td>
                  <td style={{ width: "9%" }}>{extraFee?.reasonFee1}</td>
                  <td style={{ width: "9%" }}></td>
                  <td style={{ width: "14%" }}></td>
                  <td style={{ width: "14%" }}>
                    {extraFee?.fee1}
                  </td>
                  <td style={{ width: "14%" }}>
                    {(extraFee?.fee1 * 0.1).toFixed(2)}
                  </td>
                </tr>
              }

              {
                !!Number(extraFee?.fee2) > 0 &&
                <tr className="statement-event">
                  <td style={{ width: "8%", borderLeft: "1px solid #fff" }}>
                    {props?.boothPrices?.length + parcelPrices?.length + shippingBoxArr?.length + (Number(extraFee?.fee1) > 0 ? 2 : 1)}
                  </td>
                  <td style={{ width: "9%" }}>물류</td>
                  <td className="border-print-bottom-none"
                    style={{
                      width: "18%",
                      maxWidth: "30%",
                      whiteSpace: "normal",
                      minWidth: "auto"
                    }}
                  >
                    <span>
                      기타
                    </span>
                  </td>
                  <td style={{ width: "9%" }}>{extraFee?.reasonFee2}</td>
                  <td style={{ width: "9%" }}></td>
                  <td style={{ width: "14%" }}></td>
                  <td style={{ width: "14%" }}>
                    {extraFee?.fee2}
                  </td>
                  <td style={{ width: "14%" }}>
                    {(extraFee?.fee2 * 0.1).toFixed(2)}
                  </td>
                </tr>
              }

              {
                !!resonOther?.length && resonOther?.map(({ key, value, unitPrice, quantity }, index) =>
                  <>
                    <tr className="statement-event">
                      <td style={{ width: "8%", borderLeft: "1px solid #fff" }}>
                        {props?.boothPrices?.length +
                          parcelPrices?.length +
                          shippingBoxArr?.length +
                          index + 1 +
                          (Number(extraFee?.fee1) > 0 ? 1 : 0) +
                          (Number(extraFee?.fee2) > 0 ? 1 : 0)}
                      </td>
                      <td style={{ width: "9%" }}>기타</td>
                      <td className="border-print-bottom-none"
                        style={{
                          width: "18%",
                          maxWidth: "30%",
                          whiteSpace: "normal",
                          minWidth: "auto"
                        }}
                      >
                        <span>
                          {key}
                        </span>
                      </td>
                      <td style={{ width: "9%" }}></td>
                      <td style={{ width: "9%" }}>{quantity}</td>
                      <td style={{ width: "14%" }}>{unitPrice}</td>
                      <td style={{ width: "14%" }}>
                        {value}
                      </td>
                      <td style={{ width: "14%" }}>
                        {(value * 0.1).toFixed(2)}
                      </td>
                    </tr>
                  </>
                )
              }

              <tr className="statement-event">
                <td colSpan={4} style={{ width: "44%", fontWeight: "700", borderLeft: "1px solid #fff" }}>합 계</td>

                <td style={{ width: "9%", borderRight: "0" }}></td>
                <td style={{ width: "14%", borderLeft: "0", borderRight: "0" }}></td>
                <td style={{ width: "14%", borderLeft: "0" }}>
                  {formatLargeNumber(totalCost?.toString())}
                </td>
                <td style={{ width: "14%", }}>
                  {formatLargeNumber((totalCost * 0.1)?.toString())}
                </td>
              </tr>

              <tr >
                <td colSpan={8} style={{
                  width: "44%",
                  fontWeight: "700",
                  borderLeft: "1px solid #fff",
                  borderBottom: "0"
                }}>
                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    minHeight: "20px"
                  }}>*견적금액은 수정사항이 있는 경우 변경될 수 있으며, 신청단계가 처리 완료된 때에 확인바랍니다.</div>
                </td>
              </tr>

              <tr>
                <td colSpan={8} style={{
                  width: "44%",
                  fontWeight: "700",
                  borderLeft: "1px solid #fff",
                  borderTop: "0",
                  borderBottom: "0"
                }}>
                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    minHeight: "20px"
                  }}>
                  </div>
                </td>
              </tr>

              <tr tyle={{
                width: "44%",
                fontWeight: "700",
                border: "0",
                borderBottom: "1px solid #fff",
                borderLeft: "1px solid #fff"
              }}>
                <td colSpan={8} style={{
                  width: "44%",
                  fontWeight: "700",
                  border: "0",
                  borderBottom: "1px solid #fff",
                  borderLeft: "1px solid #fff"
                }}>
                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    minHeight: "20px"
                  }}>
                    입금계좌 우리은행 169-639859-13-001</div>
                </td>
              </tr>

            </table>
          </div>

        </div>
      </div>
    </div>
  );
});

export default PrintEventStatement;
