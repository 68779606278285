import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "@mui/material/Container";
// import Table from "react-bootstrap/Table";
import { useState, useRef } from "react";
import JoditEditor from "jodit-react";
import { useNavigate } from "react-router-dom";
import { boothtypeRegisterFunc } from "../../../api";
import { toast } from "react-toastify";
import { BoothType2 } from "../../../utils/constant";

const BoothTypeRegister = () => {
  const editor = useRef(null);
  const navigate = useNavigate();
  const [boothName, setboothName] = useState("");
  const [content, setContent] = useState("");
  const [selectData, setSelectData] = useState("Truss");
  const [selectBoothType2, setSelectBoothType2] = useState("");

  // console.log(selectData,boothName,content)

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("boothType", selectData);
    formData.append("boothName", boothName);
    formData.append("boothContent", content);
    formData.append("boothType2Id", selectBoothType2);
    
    const boothType2 = BoothType2.find((item) => item.id ===  selectBoothType2)
    formData.append("boothType2", boothType2?.name);

    const config = {
      "Content-Type": "multipart/form-data",
    };
    const response = await boothtypeRegisterFunc(formData, config);
    if (response.status === 200) {
      toast.success("Successfully Register!");
      navigate("/boothtype", { replace: true });
      setboothName("");
      setContent("");
      setSelectData("");
    } else {
      toast.error("부스타입 등록에 실패했습니다.!");
    }

  };
  return (
    <>
      <Container maxWidth='md' className='py-3'>
        <Row className='fs-1 fw-bold p-2 border-bottom mb-5'>부스종류관리</Row>
        <Form onSubmit={handleSubmit}>
          <Form.Group as={Row} className='mb-3 px-3 align-items-center'>
            <Form.Label column sm='2'>
              부스 종류 1
            </Form.Label>
            <Col sm='9'>
              <div
                onChange={(e) => {
                  setSelectData(e.target.value);
                }}
              >
                {["Truss", "Block", "Steel", "Led", "Fabric", "기타"].map(
                  (type, idx) => (
                    <Form.Check
                      key={idx}
                      inline
                      label={`${type}`}
                      name='group1'
                      type='radio'
                      value={`${type}`}
                      defaultChecked={type === "Truss"}
                    />
                  )
                )}
              </div>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              부스 종류 2
            </Form.Label>
            <Col sm='3'>
              <Form.Select aria-label="Default select example"
                value={selectBoothType2}
                onChange={(e) => setSelectBoothType2(e.target.value)}>
                {
                  BoothType2?.map((item, index) =>
                    <option value={item.id}>{item?.name}</option>
                  )
                }
              </Form.Select>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              부스 형태
            </Form.Label>
            <Col sm='3'>
              <Form.Control
                type='text'
                name='bootName'
                value={boothName}
                size='sm'
                onChange={(e) => setboothName(e.target.value)}
                required
              />
            </Col>
          </Form.Group>



          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              내용
            </Form.Label>
            <Col sm='12'>
              <JoditEditor
                name='bootContent'
                ref={editor}
                value={content}
                onChange={(newContent) => setContent(newContent)}
              />
            </Col>
          </Form.Group>

          <Row>
            <Col className='text-end m-5 '>
              <Button
                variant='outline-secondary'
                className='px-4 mx-3'
                onClick={() => navigate("/boothtype")}
              >
                목 록
              </Button>{" "}
              <Button as='input' type='submit' value='저장' className='px-4' />{" "}
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default BoothTypeRegister;
