
import {apirequest} from "./apiCall.js"
import {BASE_URL} from "./helper.js"

//  거래처 관리 api 
export const clientRegisterFunc = async(data,header)=>{
    return await apirequest("POST",`${BASE_URL}/client/register`,data,header);
}
export const getAllClientsFunc = async(search,page)=>{
    console.log(search)
    if(search !== undefined && page !== undefined){  return await apirequest("GET",`${BASE_URL}/client/?search=${encodeURIComponent(search)}&page=${page}`);}
    return await apirequest("GET",`${BASE_URL}/client/`);
}
export const getAllClientsExcelFunc = async(search,page)=>{
    if(search !== undefined && page !== undefined){  return await apirequest("GET",`${BASE_URL}/client/excel?search=${encodeURIComponent(search)}&page=${page}`);}
    return await apirequest("GET",`${BASE_URL}/client/excel`);
}
export const getClientFunc = async(did)=>{
    return await apirequest("GET",`${BASE_URL}/client/get-client/${did}`);
}

export const clientUpdateFunc = async(data,id,header)=>{
    return await apirequest("PUT",`${BASE_URL}/client/update-client/${id}`,data,header);
}
export const clientDepartmentUpdateFunc = async(id,data,header)=>{
    return await apirequest("PUT",`${BASE_URL}/client/update-clientdepartment/${id}`,data,header);
}
export const clientDepartmentUpdateSetFunc = async(id,data,header)=>{
    return await apirequest("PUT",`${BASE_URL}/client/update-new-department/${id}`,data,header);
}
export const clientDeleteFunc = async(id)=>{
    return await apirequest("POST",`${BASE_URL}/client/delete-client/${id}`);
}

// 직원 관리 api 

export const getAllStaffsFunc = async(did)=>{
    return await apirequest("GET",`${BASE_URL}/staff/${did}`);
}
export const getStaffFunc = async(id)=>{
    return await apirequest("GET",`${BASE_URL}/staff/get-staff/${id}`);
}
export const staffRegisterFunc = async(id,data,header)=>{
    return await apirequest("POST",`${BASE_URL}/staff/register-staff/${id}`,data,header);
}
export const staffUpdateFunc = async(data,id,header)=>{
    return await apirequest("PUT",`${BASE_URL}/staff/update-staff/${id}`,data,header);
}
export const staffDeleteFunc = async(id)=>{
    return await apirequest("POST",`${BASE_URL}/staff/delete-staff/${id}`);
}

// 부스타입 관리 api 
export const boothtypeRegisterFunc = async(data,header)=>{
    return await apirequest("POST",`${BASE_URL}/boothtype/register-type/`,data,header);
}

export const getAllboothtypeFunc = async(page, orderBy)=>{
    return await apirequest("GET",`${BASE_URL}/boothtype/?page=${page}&orderBy=${orderBy}`);
}
export const getBoothtypeFunc = async(id)=>{
    return await apirequest("GET",`${BASE_URL}/boothtype/get-type/${id}`);
}

export const boothtypeUpdateFunc = async(id,data,header)=>{
    return await apirequest("PUT",`${BASE_URL}/boothtype/update-type/${id}`,data,header);
}
export const boothtypeDeleteFunc = async(id)=>{
    return await apirequest("POST",`${BASE_URL}/boothtype/delete-type/${id}`);
}


// 부스관리 api 
export const boothRegisterFunc = async(data,header)=>{
    return await apirequest("POST",`${BASE_URL}/booth/register/`,data,header);
}

export const getAllboothFunc = async(data)=>{
    if(data){
       
        const {selectClient,selectDepartment,selectProduct,search,page} = data

        return await apirequest("GET",`${BASE_URL}/booth/?search=${encodeURIComponent(search)}&selectClient=${encodeURIComponent(selectClient)}&selectDepartment=${encodeURIComponent(selectDepartment)}&selectProduct=${encodeURIComponent(selectProduct)}&page=${encodeURIComponent(page)}&`);
       
    }
    return await apirequest("GET",`${BASE_URL}/booth/`);
}
export const getBoothFunc = async(id)=>{
    return await apirequest("GET",`${BASE_URL}/booth/get-booth/${id}`);
}

export const boothUpdateFunc = async(id,data,header)=>{
    return await apirequest("PUT",`${BASE_URL}/booth/update-booth/${id}`,data,header);
}
export const boothDeleteFunc = async(id)=>{
    return await apirequest("POST",`${BASE_URL}/booth/delete-booth/${id}`);
}

// 제품관리 api 

export const productRegisterFunc = async(data,header)=>{
    return await apirequest("POST",`${BASE_URL}/product/register-product/`,data,header);
}
export const getAllProductFunc = async(id, isDeleted, department)=>{
    return await apirequest("GET",`${BASE_URL}/product/${id}?isDeleted=${isDeleted}&department=${department}`);
}
export const getDashboard = async(id)=>{
    return await apirequest("GET",`${BASE_URL}/dashboard`);
}
export const getProductFunc = async(id)=>{
    return await apirequest("GET",`${BASE_URL}/product/get-product/${id}`);
}
export const productUpdateFunc = async(id,data,header)=>{
    return await apirequest("PUT",`${BASE_URL}/product/update-product/${id}`,data,header);
}
export const productDeleteFunc = async(id)=>{
    return await apirequest("POST",`${BASE_URL}/product/delete-product/${id}`);
}

// 물품관리 api 

export const goodsRegisterFunc = async(data,header)=>{
    return await apirequest("POST",`${BASE_URL}/goods/register/`,data,header);
}
export const getAllGoodsFunc = async(data)=>{
    if(data){
       
        const {searchStartDay,searchEndDay,selectClient,selectDepartment,selectProduct,search,trueStates:searchState,sendStartDay,sendEndDay,selectCatgoris,page} = data

        return await apirequest("GET",`${BASE_URL}/goods/?search=${encodeURIComponent(search)}&searchStartDay=${encodeURIComponent(searchStartDay)}&searchEndDay=${encodeURIComponent(searchEndDay)}&selectClient=${encodeURIComponent(selectClient)}&selectDepartment=${encodeURIComponent(selectDepartment)}&selectProduct=${encodeURIComponent(selectProduct)}&searchState=${encodeURIComponent(searchState)}&page=${encodeURIComponent(page)}&catgoris=${encodeURIComponent(selectCatgoris)}`);
       
    }
    return await apirequest("GET",`${BASE_URL}/goods/`);
}
export const getGoodsFunc = async(id)=>{
    return await apirequest("GET",`${BASE_URL}/goods/get-goods/${id}`);
}
export const goodsUpdateFunc = async(id,data,header)=>{
    return await apirequest("PUT",`${BASE_URL}/goods/update-goods/${id}`,data,header);
}
export const goodsUpdateLogFunc = async(id,data,header)=>{
    return await apirequest("POST",`${BASE_URL}/goods/update-log/${id}`,data,header);
}
export const goodsDeleteFunc = async(id)=>{
    return await apirequest("POST",`${BASE_URL}/goods/delete-goods/${id}`);
}

// log
export const getAlllogFunc = async(data)=>{
    if(data){
       
        const {searchStartDay,searchEndDay,selectClient,selectDepartment,selectProduct,search,selectCatgoris,page} = data

        return await apirequest("GET",`${BASE_URL}/log/?search=${encodeURIComponent(search)}&searchStartDay=${encodeURIComponent(searchStartDay)}&searchEndDay=${encodeURIComponent(searchEndDay)}&selectClient=${encodeURIComponent(selectClient)}&selectDepartment=${encodeURIComponent(selectDepartment)}&selectProduct=${encodeURIComponent(selectProduct)}&page=${encodeURIComponent(page)}&catgoris=${encodeURIComponent(selectCatgoris)}`);
       
    }
    return await apirequest("GET",`${BASE_URL}/log/`);
}
export const getAlllogExcelFunc = async(data)=>{
    if(data){

        const {searchStartDay,searchEndDay,selectClient,selectDepartment,selectProduct,search,selectCatgoris,page} = data

        return await apirequest("GET",`${BASE_URL}/log/excel?search=${encodeURIComponent(search)}&searchStartDay=${encodeURIComponent(searchStartDay)}&searchEndDay=${encodeURIComponent(searchEndDay)}&selectClient=${encodeURIComponent(selectClient)}&selectDepartment=${encodeURIComponent(selectDepartment)}&selectProduct=${encodeURIComponent(selectProduct)}&page=${encodeURIComponent(page)}&catgoris=${encodeURIComponent(selectCatgoris)}`);

    }
    return await apirequest("GET",`${BASE_URL}/log/excel`);
}
export const getlogFunc = async(id)=>{
    return await apirequest("GET",`${BASE_URL}/log/${id}`);
}

//!SECTION 추가 admin eventRequest

export const getAllEventRequestFunc = async(data)=>{
    if(data){
       
        const {searchStartDay,searchEndDay,selectClient,selectDepartment,selectProduct,search,trueStates:searchState,sendStartDay,sendEndDay,page} = data

        return await apirequest("GET",`${BASE_URL}/event/eventrequest/?search=${encodeURIComponent(search)}&searchStartDay=${encodeURIComponent(searchStartDay)}&searchEndDay=${encodeURIComponent(searchEndDay)}&selectClient=${encodeURIComponent(selectClient)}&selectDepartment=${encodeURIComponent(selectDepartment)}&selectProduct=${encodeURIComponent(selectProduct)}&searchState=${encodeURIComponent(searchState)}&page=${encodeURIComponent(page)}&sendStartDay=${encodeURIComponent(sendStartDay)}&sendEndDay=${encodeURIComponent(sendEndDay)}`);
       
    }
    return await apirequest("GET",`${BASE_URL}/event/eventrequest`);
}

export const getAllEventRequestFuncByMonth = async(params)=>{
    const {  month, year } = params;
    const url = new URL(`${BASE_URL}/event/eventrequestbymonth`);
    url.searchParams.append('month', month);
    url.searchParams.append('year', year);
    
    return await apirequest("GET", url.toString());
}

export const getEventRequestItemFunc = async(id)=>{
    return await apirequest("GET",`${BASE_URL}/event/eventrequest/${id}`);
}
export const updateEventRequestItemFunc = async(id,data,header)=>{
    return await apirequest("PUT",`${BASE_URL}/event/eventrequest/${id}`,data,header);
}

// 입고신청 api

export const getAllIncomingOrderFunc = async(data)=>{
    if(data){
       
        const {searchStartDay,searchEndDay,selectClient,selectDepartment,selectProduct,search,trueStates:searchState,sendStartDay,sendEndDay,page} = data

        return await apirequest("GET",`${BASE_URL}/incomingorder/?search=${encodeURIComponent(search)}&searchStartDay=${encodeURIComponent(searchStartDay)}&searchEndDay=${encodeURIComponent(searchEndDay)}&selectClient=${encodeURIComponent(selectClient)}&selectDepartment=${encodeURIComponent(selectDepartment)}&selectProduct=${encodeURIComponent(selectProduct)}&searchState=${encodeURIComponent(searchState)}&page=${encodeURIComponent(page)}&sendStartDay=${encodeURIComponent(sendStartDay)}&sendEndDay=${encodeURIComponent(sendEndDay)}`);
       
    }
    return await apirequest("GET",`${BASE_URL}/incomingorder/`);
}
export const getIncomingOrderItemFunc = async(id)=>{
    return await apirequest("GET",`${BASE_URL}/incomingorder/${id}`);
}
export const updateIncomingOrderItemFunc = async(id,data)=>{
    return await apirequest("PUT",`${BASE_URL}/incomingorder/${id}`,data);
}

//물품신청현황 api

export const getAlldeliveryGoodsOrderFunc = async(data)=>{
    
    if(data){
       
        const {searchStartDay,searchEndDay,selectClient,selectDepartment,selectProduct,search,trueStates:searchState,sendStartDay,sendEndDay,page} = data

        return await apirequest("GET",`${BASE_URL}/deliveryorder/?search=${encodeURIComponent(search)}&searchStartDay=${encodeURIComponent(searchStartDay)}&searchEndDay=${encodeURIComponent(searchEndDay)}&selectClient=${encodeURIComponent(selectClient)}&selectDepartment=${encodeURIComponent(selectDepartment)}&selectProduct=${encodeURIComponent(selectProduct)}&searchState=${encodeURIComponent(searchState)}&page=${encodeURIComponent(page)}&sendStartDay=${encodeURIComponent(sendStartDay)}&sendEndDay=${encodeURIComponent(sendEndDay)}`);
       
    }
   
    return await apirequest("GET",`${BASE_URL}/deliveryorder/`);
}

export const getDeliveryGoodsOrderFunc = async(id)=>{
    return await apirequest("GET",`${BASE_URL}/deliveryorder/${id}`);
}

export const updateDeliveryGoodsOrderFunc = async(id,data)=>{
    return await apirequest("PUT",`${BASE_URL}/deliveryorder/${id}`,data);
}

//Banner api

export const bannerRegisterFunc = async(data,header)=>{
    return await apirequest("POST",`${BASE_URL}/banner/`,data,header);
}
export const getAllBannerFunc = async({page})=>{
    return await apirequest("GET",`${BASE_URL}/banner?page=${page}`);
}

export const getbannerItemFunc = async(id)=>{
    return await apirequest("GET",`${BASE_URL}/banner/${id}`);
}

export const updateBannerItemFunc = async(id,data,header)=>{
    return await apirequest("PUT",`${BASE_URL}/banner/${id}`,data,header);
}
export const deleteBannerItemFunc = async(id)=>{
    return await apirequest("POST",`${BASE_URL}/banner/delete/${id}`);
}


// 공지사항 관리

export const getAllNoticeFunc = async(data)=>{
    if(data){
       
        const {searchStartDay,searchEndDay,search,page} = data

        return await apirequest("GET",`${BASE_URL}/notice/?search=${encodeURIComponent(search)}&searchStartDay=${encodeURIComponent(searchStartDay)}&searchEndDay=${encodeURIComponent(searchEndDay)}&selectClient=$&page=${encodeURIComponent(page)}`);
       
    }
    return await apirequest("GET",`${BASE_URL}/notice/`);
}


// 부서별 게시판 

export const getAllDepartmentNoticeFunc = async(data)=>{
    if(data){
        const {searchStartDay, searchEndDay, selectClient, selectDepartment, selectCatgoris, search, page} = data

        return await apirequest("GET",`${BASE_URL}/dnotice/?search=${encodeURIComponent(search)}&searchStartDay=${encodeURIComponent(searchStartDay)}&searchEndDay=${encodeURIComponent(searchEndDay)}&selectClient=${encodeURIComponent(selectClient)}&selectDepartment=${encodeURIComponent(selectDepartment)}&selectCatgoris=${encodeURIComponent(selectCatgoris)}&page=${encodeURIComponent(page)}`);
       
    }
    return await apirequest("GET",`${BASE_URL}/notice/`);
}

// 행사장 갤러리
export const getAllGallaryFunc = async(data)=>{
    if(data){
       
        const {searchStartDay,searchEndDay,search,page} = data

        return await apirequest("GET",`${BASE_URL}/event/hallGallary/?search=${encodeURIComponent(search)}&searchStartDay=${encodeURIComponent(searchStartDay)}&searchEndDay=${encodeURIComponent(searchEndDay)}&selectClient=$&page=${encodeURIComponent(page)}`);
       
    }
    return await apirequest("GET",`${BASE_URL}/event/hallGallary/`);
}
// 행사장정보
export const getAllContentInfoFunc = async(data)=>{
    if(data){
       
        const {searchStartDay,searchEndDay,search,page} = data

        return await apirequest("GET",`${BASE_URL}/event/hallinfo/?search=${encodeURIComponent(search)}&searchStartDay=${encodeURIComponent(searchStartDay)}&searchEndDay=${encodeURIComponent(searchEndDay)}&selectClient=$&page=${encodeURIComponent(page)}`);
       
    }
    return await apirequest("GET",`${BASE_URL}/event/hallGallary/`);
}


//change password client
export const clientUpdatePasswordFunc = async(data)=>{
    return await apirequest("POST",`${BASE_URL}/client/update-password`,data);
}
//  check duplicate id 
export const clientUCheckDuplicateIdFunc = async(data)=>{
    return await apirequest("POST",`${BASE_URL}/client/check-duplication-id`,data);
}

// address client
export const getAddressAccordingClient = async(clientId) => {
    return await apirequest("GET", `${BASE_URL}/deliveryaddress/client/${clientId}`)
}
// get id address
export const getAddressAccordingId = async(addressId) => {
    return await apirequest("GET", `${BASE_URL}/deliveryaddress/${addressId}`)
}
// update address client
export const updateAddressAccordingClient = async (addressId, data, header) => {
    return await apirequest(
        "PUT",
        `${BASE_URL}/deliveryaddress/update/${addressId}`,
        data,
        header
    );
};
// delete address client
export const deleteAddressAccordingClient = async (addressId) => {
    return await apirequest("POST", `${BASE_URL}/deliveryaddress/delete/${addressId}`);
};
// delete department info
export const deleteDepartment = async (id, data) => {
return await apirequest("DELETE", `${BASE_URL}/department/${id}`, data);
}

// get statistics
export const getStatistics = async (data) => {
    const { client_id, year } = data;
    return await apirequest("GET", `${BASE_URL}/statistics?client_id=${encodeURIComponent(client_id)}&year=${encodeURIComponent(year)}`);
}

// update dNotice
export const updateDNotice = async(id, data, header)=>{
    return await apirequest("PUT",`${BASE_URL}/dnotice/update/${id}`, data, header);
}
// delete dNotice
export const deleteDNotice = async (id) => {
    return await apirequest("POST", `${BASE_URL}/dnotice/delete/${id}`);
}

export const updateProductOrderBy = async(id,data)=>{
    return await apirequest("PUT",`${BASE_URL}/product/update-orderby/${id}`,data);
}

//Banner api

export const popupRegisterFunc = async(data,header)=>{
    return await apirequest("POST",`${BASE_URL}/popup/`,data,header);
}
export const getAllPopupFunc = async({page})=>{
    return await apirequest("GET",`${BASE_URL}/popup?page=${page}`);
}

export const getPopupItemFunc = async(id)=>{
    return await apirequest("GET",`${BASE_URL}/popup/${id}`);
}

export const updatePopupItemFunc = async(id,data,header)=>{
    return await apirequest("PUT",`${BASE_URL}/popup/${id}`,data,header);
}
export const deletePopupItemFunc = async(id)=>{
    return await apirequest("POST",`${BASE_URL}/popup/delete/${id}`);
}

export const updatePopupOrderBy = async(id,data)=>{
    return await apirequest("PUT",`${BASE_URL}/popup/update-numberby/${id}`,data);
}

export const areaPricesRegisterFunc = async(data)=>{
    return await apirequest("POST",`${BASE_URL}/area/`,data);
}

export const getAllAreaPriceFunc = async(areaId, clientId, page, )=>{
    return await apirequest("GET",`${BASE_URL}/area/?areaId=${areaId}&clientId=${clientId}&page=${page}`);
}

export const configPricesRegisterFunc = async(data)=>{
    return await apirequest("POST",`${BASE_URL}/configPrice/`,data);
}

export const getAllConfigCostsFunc = async(clientId, page)=>{
    return await apirequest("GET",`${BASE_URL}/configPrice/?clientId=${clientId}&page=${page}`);
}

export const getEventBoothPriceFunc = async(id)=>{
    return await apirequest("GET",`${BASE_URL}/booth/event/${id}`);
}

export const otherCostRegisterFunc = async(data)=>{
    return await apirequest("POST",`${BASE_URL}/otherCost/`,data);
}

export const getAllOtherCostsFunc = async(clientId, page)=>{
    return await apirequest("GET",`${BASE_URL}/otherCost/?clientId=${clientId}&page=${page}`);
}

export const getAllEventLogsFunc = async(eventId)=>{
    return await apirequest("GET",`${BASE_URL}/eventLog?eventId=${eventId}&limit=${1000}`);
}

export const getAllOrderLogsFunc = async(orderId)=>{
    return await apirequest("GET",`${BASE_URL}/orderLog?orderId=${orderId}&limit=${1000}`);
}