import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getEventRequestItemFunc } from "../api";
import image from "../assets/logo_designtt.png"
import { formatDateToYYYYMMDDHHMMSS, formatLargeNumber } from "../utils";
import { calculateTotalCost } from "../scenes/eventmanagement/EventFormDetail";
import { EVENT_STATUS } from "../utils/constant";

const PrintEvent = React.forwardRef((props, ref) => {
	const { id } = useParams();
	const [parcel, setParcel] = useState({ oddShaped: "", parcelLarge: "", parcelMedium: "", parcelSmall: "", parcelMini: "", parcelTotal: "" })
	const [data, setData] = useState([]);
	const [shippingBox, setShippingBox] = useState({ boxLarge: "", boxMedium: "", boxSmall: "", boxTotal: "" })
	const [quickService, setQuickService] = useState({ damas: "", motorcycle: "", quickTotal: "" })
	const [extraFee, setExtraFee] = useState({ reasonFee1: "", fee1: "", reasonFee2: "", fee2: "" })
	const [boxPrices, setBoxPrices] = useState("")
	const [parcelPrices, setParcelPrices] = useState("");
	const [otherCost, setOtherCost] = useState(props?.otherCost);
	const [totalBoothPrices, setTotalBoothPrices] = useState(props?.totalBoothPrices ?? 0);

	useEffect(() => {
		setTotalBoothPrices(props?.totalBoothPrices ?? 0);
		setOtherCost(props?.otherCost);
	}, [props?.totalBoothPrices, props?.otherCost]);

	useEffect(() => {
		const eventRequestItemGet = async (id) => {
			const response = await getEventRequestItemFunc(id);
			if (response.status === 200) {
				response.data.updatedAt = response.data?.updatedAt?.slice(0, 10);
				response.data?.parcel && setParcel(JSON.parse(response.data?.parcel))

				response.data?.parcel && setParcel(JSON.parse(response.data?.parcel))
				response.data?.shippingBox && setShippingBox(JSON.parse(response.data?.shippingBox))
				response.data?.quickService && setQuickService(JSON.parse(response.data?.quickService))
				response?.data?.extraFee && setExtraFee(JSON.parse(response?.data?.extraFee))

				setData(response.data);
			} else {
				console.log("error");
			}
		};
		eventRequestItemGet(id);
	}, [id]);

	return (
		<div ref={ref}>
			<div key={1} className="print_wapper">
				<div className={`paper ${data?.goods?.length === 5 ? "paper_padding" : ""}`}>
					<div className="content" style={{ border: "none" }}>
						<div className="header border-bottom-none"
							style={{
								position: "relative",
								border: "1px solid #000",
							}}
						>
							{
								data?.eventState === EVENT_STATUS.COMPLETE ?
									<h1 style={{ margin: "0px" }}>전시 행사 완료 보고서</h1> :
									<h1 style={{ margin: "0px" }}>전시 행사 접수 신청서</h1>
							}
							<div style={{ position: "absolute", right: "25px" }}>
								<img src={image} alt="" width={100} height={60} />
							</div>
							<div style={{ position: "absolute", left: "5px" }}>
								<div style={{ textAlign: "start", display: "flex", gap: "8px" }}>
									<div style={{ width: "85px" }}>
										최종수정일시
									</div>
									<div>
										{formatDateToYYYYMMDDHHMMSS(new Date())}
									</div>
								</div>
								<div style={{ display: "flex", gap: "8px" }}>
									<div className="text-align-start" style={{ textAlign: "start", width: "85px" }}>출력일시</div>
									<div>{props?.eventLogs?.lenth ? formatDateToYYYYMMDDHHMMSS(props?.eventLogs?.[0]?.createdAt) : ''}</div>
								</div>
							</div>
						</div>
						<div className="row" style={{
							display: "grid",
							gridTemplateRows: "auto auto",
							gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
							maxWidth: "102%",
							boxSizing: "border-box",
							marginLeft: "0",
							marginRight: "0",
							border: "1px solid #000"
						}}>
							<div className="item-print">신청번호</div>
							<div className="item-print half" style={{ gridColumn: "span 2" }}>{data.eventCode}</div>
							<div className="item-print">신청일자</div>
							<div className="item-print border-right-none" style={{ gridColumn: "span 2" }}>{formatDateToYYYYMMDDHHMMSS(data?.createdAt)}</div>

							<div className="item-print">회사명</div>
							<div className="item-print half">{data.companyName}</div>
							<div className="item-print">부서명</div>
							<div className="item-print">{data.department}</div>
							<div className="item-print">신청인</div>
							<div className="item-print border-right-none half"> {data ? data?.staff?.staffName : ""}</div>

							<div className="item-print">신청인 연락처</div>
							<div className="item-print">{data.staffPhone}</div>
							<div className="item-print">제품명</div>
							<div className="item-print border-right-none half" style={{ gridColumn: "span 3" }}> {data.productName}</div>

							<div className="item-print item-print-highlight ">행사 일자</div>
							<div className="item-print half" style={{ gridColumn: "span 2" }}>
								{data?.eventStart?.slice(0, 10)} ~ {data?.eventEnd?.slice(0, 10)}
							</div>
							<div className="item-print item-print-highlight ">행사장 주소</div>
							<div className="item-print border-right-none" style={{ gridColumn: "span 2" }}>{data.eventLocation}</div>

							<div className="item-print item-print-highlight ">행사 명칭</div>
							<div className="item-print border-right-none half" style={{ gridColumn: "span 5", justifyContent: "start" }}>{data.eventName}</div>

							<div className="item-print item-print-highlight ">행사 시간</div>
							<div className="item-print half" style={{ gridColumn: "span 2" }}>
								{data.eventTime}
							</div>
							<div className="item-print item-print-highlight ">설치/철거시간</div>
							<div className="item-print border-right-none" style={{ gridColumn: "span 2" }}>{data.disassembly}</div>

							<div className="item-print item-print-highlight ">부스 이름</div>
							<div className="item-print half" style={{ gridColumn: "span 2" }}>
								{data?.booths?.map((it, idx) => (<>{it.boothName} <br /> </>))}
							</div>
							<div className="item-print item-print-highlight ">방명록 신청 수</div>
							<div className="item-print border-right-none" style={{ gridColumn: "span 2" }}>{data?.guestBook}</div>

							<div className="item-print ">행사 지시 사항</div>
							<div className="item-print border-right-none half" style={{ gridColumn: "span 5" }}>{data.directions}</div>

							<div className="item-print ">특이 사항</div>
							<div className="item-print border-right-none half" style={{ gridColumn: "span 5" }}>{data.memo}</div>

							<div className="item-print" style={{ gridColumn: "span 5" }}>발송 정보</div>
							<div className="item-print border-right-none">비고</div>
							<div className="item-print border-bottom-none" style={{ width: "100%", padding: "0" }}>
								<div style={{ flex: "1", display: "flex", alignItems: "center", justifyContent: "center" }}>1</div>
								<div className="item-print-left" style={{ flex: "2", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
									<span>재포장(박스) </span>
									<span>사용</span>
								</div>
							</div>
							<div className="item-print cost-print border-bottom-none" style={{ gridColumn: "span 4", padding: "0px 0px" }}>
								<div className="item-print-cost ">이형</div>
								<div className="item-print-cost ">{parcel?.oddShaped ?? 0}</div>
								<div className="item-print-cost ">대</div>
								<div className="item-print-cost ">{parcel?.parcelLarge ?? 0}</div>
								<div className="item-print-cost ">중</div>
								<div className="item-print-cost ">{parcel?.parcelMedium ?? 0}</div>
								<div className="item-print-cost ">소</div>
								<div className="item-print-cost ">{parcel?.parcelMini ?? 0}</div>
								<div className="item-print-cost ">극소</div>
								<div className="item-print-cost " style={{ border: "none" }}>{parcel?.parcelSmall ?? 0}</div>
							</div>
							<div className="item-print border-right-none border-bottom-none"></div>
						</div>

						<table className="table table-event mt-3" style={{
							borderTop: "0",
							marginTop: "16px",
							display: "flex",
							flexDirection: "column",
							tableLayout: "fixed",
							// borderCollapse: "collapse"
						}}>
							<thead>
								<tr className="title-tr">
									<th className="title title-event" style={{ width: "7%", borderTop: "1px solid #000" }}>번호</th>
									<th className="title title-event" style={{ width: "8%", borderTop: "1px solid #000" }}>제품명</th>
									<th className="title title-event" style={{ width: "8%", borderTop: "1px solid #000" }}>분류</th>
									<th className="title title-event" style={{ width: "30%", borderTop: "1px solid #000" }}>물품명</th>
									<th className="title title-event" style={{ width: "8%", borderTop: "1px solid #000" }}>포장정보</th>
									<th className="title title-event" style={{ width: "15%", borderTop: "1px solid #000" }}>사진</th>
									<th className="title title-event" style={{ width: "8%", borderTop: "1px solid #000" }}>주문수량</th>
									<th className="title title-event" style={{ width: "8%", borderTop: "1px solid #000" }}>단위</th>
									<th className="title title-event" style={{ width: "8%", borderRight: "1px solid #000", borderTop: "1px solid #000" }}>적재위치</th>
								</tr>
							</thead>
							<tbody>
								{data?.goods?.map((it, idx) => (
									<tr className="title-tr">
										<td className="title title-event" style={{ width: "7%" }}>{data?.goods?.length - idx}</td>
										<td className="title title-event" style={{ width: "8%" }}>{it.goodsId?.productName}</td>
										<td className="title title-event" style={{ width: "8%" }}>{it.goodsId?.category}</td>
										<td className="title title-event" style={{ width: "30%", maxWidth: "30%", whiteSpace: "normal", minWidth: "auto" }}>
											<span>
												{it?.goodsId?.goodsName}
											</span>
										</td>
										<td className="title title-event" style={{ width: "8%" }}></td>
										<td className="title title-event" style={{ width: "15%" }}><img
											style={{
												width: "100px",
												aspectRatio: "1 / 1"
											}}
											src={it?.goodsId?.goodsImage[0]}
											alt=''
										/></td>
										<td className="title title-event" style={{ width: "8%" }}>{it?.amount} </td>
										<td className="title title-event" style={{ width: "8%" }}>{it?.goodsId?.unit}</td>
										<td className="title title-event" style={{ width: "8%", borderRight: "1px solid #000" }}>{it?.goodsId?.loadingLocation}</td>
									</tr>

								))}
							</tbody>
						</table>
					</div>

				</div>

			</div>

			{(data?.InstallationPhoto || data?.InstallationPhoto2) ? <div key={2} className="print_wapper">
				<div className="paper">
					{data?.InstallationPhoto ? <div className="content">
						<div className="header">
							<h1>행사설치사진1</h1>
						</div>
						<img src={data?.InstallationPhoto} alt='부스배치도' style={{ margin: "auto" }} />
					</div> : null}
					{data?.InstallationPhoto2 ? <div className="content" style={{ marginTop: "10px" }}>
						<div className="header">
							<h1>행사설치사진2</h1>
						</div>
						<img src={data?.InstallationPhoto2} alt='부스배치도' style={{ margin: "auto" }} />
					</div> : null}
				</div>
			</div> : null}

			<div key={2} className="print_wapper">
				<div className={`paper`}>
					<div className="content" style={{ border: "none" }}>
						<div className="row" style={{
							display: "grid",
							gridTemplateRows: "auto auto",
							gridTemplateColumns: "repeat(18, minmax(0,1fr))",
							maxWidth: "102%",
							boxSizing: "border-box",
							marginLeft: "0",
							marginRight: "0",
							border: "none"
						}}>
							<div className="item-print border-top-item border-left-item border-bottom-none" style={{ gridColumn: "span 6" }}>Ⅰ. 부스 신청 목록</div>
							<div style={{ gridColumn: "span 12" }}></div>


							<div className="item-print border-top-item border-left-item">번호</div>
							<div className="item-print border-top-item" style={{ gridColumn: "span 5" }}>부스 이름</div>
							<div className="item-print border-top-item" style={{ gridColumn: "span 4" }}>부스 유형</div>
							<div className="item-print border-top-item" style={{ gridColumn: "span 4" }}>신청 개수</div>
							<div className="item-print border-top-item" style={{ gridColumn: "span 4" }}>금액(원)_ vat별도</div>

							{!!props?.boothPrices?.length && props.boothPrices?.map((item, idx) =>
								<>
									<div className="item-print border-left-item">{props?.boothPrices?.length - idx}</div>
									<div className="item-print" style={{ gridColumn: "span 5" }}>{item?.boothName}</div>
									<div className="item-print" style={{ gridColumn: "span 4" }}>{item?.boothType}</div>
									<div className="item-print" style={{ gridColumn: "span 4" }}>{item?.count}</div>
									<div className="item-print" style={{ gridColumn: "span 4" }}>
										<span className="won-money-icon">
											₩
										</span>
										{formatLargeNumber(item?.price?.toString())}
									</div>
								</>
							)}

							<div className="item-print border-bottom-none" style={{ gridColumn: "span 12" }}></div>
							<div className="item-print" style={{ gridColumn: "span 2" }}>소계</div>
							<div className="item-print" style={{ gridColumn: "span 4" }}>
								<span className="won-money-icon">
									₩
								</span>
								{formatLargeNumber(totalBoothPrices?.toString()) ?? 0}
							</div>

						</div>

						<div className="row mt-3" style={{
							display: "grid",
							gridTemplateRows: "auto auto",
							gridTemplateColumns: "repeat(18, minmax(0,1fr))",
							maxWidth: "102%",
							boxSizing: "border-box",
							marginLeft: "0",
							marginRight: "0",
							border: "none"
						}}>
							<div className="item-print border-top-item border-left-item border-bottom-none" style={{ gridColumn: "span 6" }}>Ⅱ. 물류 신청 목록</div>
							<div style={{ gridColumn: "span 12" }}></div>

							<div className="item-print border-top-item border-left-item">번호</div>
							<div className="item-print border-top-item" style={{ gridColumn: "span 3" }}>항목</div>
							<div className="item-print border-top-item" style={{ gridColumn: "span 10" }}>세부항목</div>
							<div className="item-print border-top-item" style={{ gridColumn: "span 4" }}>금액(원)_ vat별도</div>


							<div className="item-print border-left-item border-bottom-none">1</div>
							<div className="item-print border-bottom-none" style={{ gridColumn: "span 3" }}>발송비용</div>
							<div className="item-print item-print-cost border-bottom-none" >이형</div>
							<div className="item-print item-print-cost border-bottom-none">{parcel?.oddShaped}</div>
							<div className="item-print item-print-cost border-bottom-none">대</div>
							<div className="item-print item-print-cost border-bottom-none">{parcel?.parcelLarge}</div>
							<div className="item-print item-print-cost border-bottom-none">중</div>
							<div className="item-print item-print-cost border-bottom-none">{parcel?.parcelMedium}</div>
							<div className="item-print item-print-cost border-bottom-none">소</div>
							<div className="item-print item-print-cost border-bottom-none">{parcel?.parcelMini}</div>
							<div className="item-print item-print-cost border-bottom-none">극소</div>
							<div className="item-print border-bottom-none">{parcel?.parcelSmall}</div>
							<div className="item-print border-bottom-none" style={{ gridColumn: "span 4" }}>
								<span className="won-money-icon">
									₩
								</span>
								{formatLargeNumber(parcel.parcelTotal?.toString()) || 0}
							</div>

							<div className="item-print border-left-item" style={{ borderTop: "1px solid #000" }}>2</div>
							<div className="item-print" style={{ gridColumn: "span 3", borderTop: "1px solid #000" }}>재포장(박스) 비용</div>
							<div className="item-print" style={{ gridColumn: "span 10", borderTop: "1px solid #000", display: "grid", gridTemplateColumns: "repeat(6, 1fr)", padding: "0" }}>
								<div className="item-print border-bottom-none item-print-cost">이형</div>
								<div className="item-print border-bottom-none item-print-cost">{shippingBox?.boxLarge}</div>
								<div className="item-print border-bottom-none item-print-cost">대</div>
								<div className="item-print border-bottom-none item-print-cost">{shippingBox?.boxMedium}</div>
								<div className="item-print border-bottom-none item-print-cost">중</div>
								<div className="item-print border-bottom-none border-right-none">{shippingBox?.boxSmall}</div>
							</div>
							<div className="item-print" style={{ gridColumn: "span 4", borderTop: "1px solid #000" }}>
								<span className="won-money-icon">
									₩
								</span>
								{formatLargeNumber(shippingBox.boxTotal?.toString()) || 0}
							</div>

							<div className="item-print border-left-item">3</div>
							<div className="item-print" style={{ gridColumn: "span 3" }}>퀵서비스</div>
							<div className="item-print" style={{ gridColumn: "span 10", display: "grid", gridTemplateColumns: "repeat(4, 1fr)", padding: "0" }}>
								<div className="item-print border-bottom-none item-print-cost">트럭</div>
								<div className="item-print border-bottom-none item-print-cost">{formatLargeNumber(quickService.damas?.toString())}</div>
								<div className="item-print border-bottom-none item-print-cost">오토바이</div>
								<div className="item-print border-bottom-none border-right-none">{formatLargeNumber(quickService.motorcycle?.toString())}</div>
							</div>
							<div className="item-print" style={{ gridColumn: "span 4" }}>
								<span className="won-money-icon">
									₩
								</span>
								{formatLargeNumber((quickService.quickTotal)?.toString()) || 0}
							</div>

							<div className="item-print border-left-item">4</div>
							<div className="item-print" style={{ gridColumn: "span 3" }}>기 타</div>
							<div className="item-print" style={{ gridColumn: "span 10", display: "grid", gridTemplateColumns: "repeat(4, 1fr)", padding: "0" }}>
								<div className="item-print border-bottom-none item-print-cost">{extraFee.reasonFee1}</div>
								<div className="item-print border-bottom-none item-print-cost">{formatLargeNumber(extraFee.fee1?.toString())}</div>
								<div className="item-print border-bottom-none item-print-cost">{extraFee.reasonFee2}</div>
								<div className="item-print border-bottom-none border-right-none">{formatLargeNumber(extraFee.fee1?.toString())}</div>
							</div>
							<div className="item-print" style={{ gridColumn: "span 4" }}>
								<span className="won-money-icon">
									₩
								</span>
								{formatLargeNumber((Number(extraFee.fee1) +
									Number(extraFee.fee2)).toString())}
							</div>

							<div className="item-print border-bottom-none" style={{ gridColumn: "span 12" }}></div>
							<div className="item-print" style={{ gridColumn: "span 2" }}>소계</div>
							<div className="item-print" style={{ gridColumn: "span 4" }}>
								<span className="won-money-icon">
									₩
								</span>
								{
									formatLargeNumber((
										Number(parcel.parcelTotal) +
										Number(shippingBox.boxTotal) +
										Number(quickService?.quickTotal) +
										Number(extraFee.fee1) +
										Number(extraFee.fee2)
									)?.toString())
								}
							</div>

						</div>

						<div className="row" style={{
							display: "grid",
							gridTemplateRows: "auto auto",
							gridTemplateColumns: "repeat(18, minmax(0,1fr))",
							maxWidth: "102%",
							boxSizing: "border-box",
							marginLeft: "0",
							marginRight: "0",
							border: "none"
						}}>
							<div className="item-print border-top-item border-left-item border-bottom-none" style={{ gridColumn: "span 6" }}>Ⅲ. 기타 신청 목록</div>
							<div style={{ gridColumn: "span 12" }}></div>


							<div className="item-print border-top-item border-left-item">번호</div>
							<div className="item-print border-top-item" style={{ gridColumn: "span 5" }}>항목</div>
							<div className="item-print border-top-item" style={{ gridColumn: "span 8" }}>신청 개수</div>
							<div className="item-print border-top-item" style={{ gridColumn: "span 4" }}>금액(원)_ vat별도</div>


							<div className="item-print border-left-item">1</div>
							<div className="item-print" style={{ gridColumn: "span 5" }}>방명록</div>
							<div className="item-print" style={{ gridColumn: "span 8" }}>{data.guestBook}</div>
							<div className="item-print" style={{ gridColumn: "span 4" }}>
								<span className="won-money-icon">
									₩
								</span>
								{formatLargeNumber((data.guestBook * Number(otherCost?.guestbookCost ?? 0))?.toString()) || 0}
							</div>

							<div className="item-print border-left-item">2</div>
							<div className="item-print" style={{ gridColumn: "span 5" }}>{otherCost.otherReasonCost1 ?? '-'}</div>
							<div className="item-print" style={{ gridColumn: "span 8" }}>{data?.otherCostNumber1}</div>
							<div className="item-print" style={{ gridColumn: "span 4" }}>
								<span className="won-money-icon">
									₩
								</span>
								{formatLargeNumber(((data.otherCostNumber1 ?? 0) * Number(otherCost?.otherCost1 ?? 0))?.toString()) || 0}
							</div>

							<div className="item-print border-left-item">3</div>
							<div className="item-print" style={{ gridColumn: "span 5" }}>{otherCost.otherReasonCost2 ?? '-'}</div>
							<div className="item-print" style={{ gridColumn: "span 8" }}>{data?.otherCostNumber2}</div>
							<div className="item-print" style={{ gridColumn: "span 4" }}>
								<span className="won-money-icon">
									₩
								</span>
								{formatLargeNumber(((data.otherCostNumber2 ?? 0) * Number(otherCost?.otherCost2 ?? 0))?.toString()) || 0}
							</div>

							<div className="item-print border-left-item">4</div>
							<div className="item-print" style={{ gridColumn: "span 5" }}>{otherCost.otherReasonCost3 ?? '-'}</div>
							<div className="item-print" style={{ gridColumn: "span 8" }}>{data?.otherCostNumber3}</div>
							<div className="item-print" style={{ gridColumn: "span 4" }}>
								<span className="won-money-icon">
									₩
								</span>
								{formatLargeNumber(((data.otherCostNumber3 ?? 0) * Number(otherCost?.otherCost3 ?? 0))?.toString()) || 0}
							</div>

							<div className="item-print border-left-item">5</div>
							<div className="item-print" style={{ gridColumn: "span 5" }}>{otherCost.otherReasonCost4 ?? '-'}</div>
							<div className="item-print" style={{ gridColumn: "span 8" }}>{data?.otherCostNumber4}</div>
							<div className="item-print" style={{ gridColumn: "span 4" }}>
								<span className="won-money-icon">
									₩
								</span>
								{formatLargeNumber(((data.otherCostNumber4 ?? 0) * Number(otherCost?.otherCost4 ?? 0))?.toString()) || 0}
							</div>

							<div className="item-print border-left-item">6</div>
							<div className="item-print" style={{ gridColumn: "span 5" }}>{otherCost.otherReasonCost5 ?? '-'}</div>
							<div className="item-print" style={{ gridColumn: "span 8" }}>{data?.otherCostNumber5}</div>
							<div className="item-print" style={{ gridColumn: "span 4" }}>
								<span className="won-money-icon">
									₩
								</span>
								{formatLargeNumber(((data.otherCostNumber5 ?? 0) * Number(otherCost?.otherCost5 ?? 0))?.toString()) || 0}
							</div>

							<div className="item-print border-bottom-none" style={{ gridColumn: "span 12" }}></div>
							<div className="item-print" style={{ gridColumn: "span 2" }}>소계</div>
							<div className="item-print" style={{ gridColumn: "span 4" }}>
								<span className="won-money-icon">
									₩
								</span>
								{formatLargeNumber(calculateTotalCost(otherCost, data)?.toString())}
							</div>
						</div>

						<div className="row" style={{
							display: "grid",
							gridTemplateRows: "auto auto",
							gridTemplateColumns: "repeat(18, minmax(0,1fr))",
							maxWidth: "102%",
							boxSizing: "border-box",
							marginLeft: "0",
							marginRight: "0",
							border: "none",
							marginTop: "16px"
						}}>
							<div className="item-print border-top-item border-left-item" style={{ gridColumn: "span 6", gridRow: "span 2" }}>Ⅳ. 신청 내역 총 합계</div>
							<div className="item-print border-top-item" style={{ gridColumn: "span 6" }}>금액(원)_ vat별도</div>
							<div className="item-print border-top-item" style={{ gridColumn: "span 6", gridRow: "span 2" }}>
								<span style={{ textAlign: "left" }}>
									*견적금액은 수정사항이 있는 경우 변경될 수 있으며, 신청단계가 처리 완료된 때에 확인바랍니다.
								</span>
							</div>

							<div className="item-print" style={{ gridColumn: "span 6", minHeight: "26.5px" }}>
								<span className="won-money-icon">
									₩
								</span>
								{formatLargeNumber((
									calculateTotalCost(otherCost, data) +
									Number(parcel.parcelTotal) +
									Number(shippingBox.boxTotal) +
									Number(quickService?.quickTotal) +
									Number(extraFee.fee1) +
									Number(extraFee.fee2) +
									totalBoothPrices
								)?.toString())}
							</div>

						</div>


						<div className="row" style={{
							display: "grid",
							gridTemplateRows: "auto auto",
							gridTemplateColumns: "repeat(9, minmax(0,1fr))",
							maxWidth: "102%",
							boxSizing: "border-box",
							marginLeft: "0",
							marginRight: "0",
							border: "none",
							marginTop: "16px"
						}}>
							<div className="item-print border-top-item border-left-item" style={{ gridRow: "span 2" }}>공급자</div>
							<div className="item-print border-top-item" >사업자번호</div>
							<div className="item-print border-top-item" style={{ gridColumn: "span 3" }}>
								220-86-88486
							</div>
							<div className="item-print border-top-item" >소재지</div>
							<div className="item-print border-top-item" style={{ gridColumn: "span 3" }}>
								경기도 화성시 세자로396번길 49
							</div>

							<div className="item-print">상 호</div>
							<div className="item-print" >
								디자인티티
							</div>
							<div className="item-print" >대표자</div>
							<div className="item-print" >
								손건화
							</div>
							<div className="item-print">업 태</div>
							<div className="item-print" >
								제 조
							</div>
							<div className="item-print" > 종 목</div>
							<div className="item-print" >
								전시디자인
							</div>

						</div>


					</div>
				</div>
			</div>

		</div>
	);
});

export default PrintEvent;
