import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, InputGroup, Row, Table } from "react-bootstrap";
import { memo, useEffect, useState } from "react";
import {getAllOtherCostsFunc, otherCostRegisterFunc } from "../../../api";
import { toast } from "react-toastify";
import { formatLargeNumber } from "../../../utils";
import Form from "react-bootstrap/Form";

const OtherCost = () => {
  const navigate = useNavigate();
  const { id } = useParams()
  const [prices, setPrices] = useState({})
  const headColumn = ["번호", "항목", "금액(원)"];

  useEffect(() => {
    // 전체회사 리스트
    const getAllOtherCosts = async () => {
      const response = await getAllOtherCostsFunc(id);

      if (response.status === 200) {
        const filteredPrices = { ...response?.data?.data };

        // Xóa các trường không cần thiết
        delete filteredPrices._id;
        delete filteredPrices.clientId;
        delete filteredPrices.__v;
        delete filteredPrices.createdAt;
        delete filteredPrices.updatedAt;

        setPrices(filteredPrices);
      } else {
        toast.error("부스종류를 가져오는데 실패했습니다.");
      }
    };
    getAllOtherCosts();
  }, []);

  console.log('prices', prices)

  const handleInputChange = (id, price) => {
    setPrices((prev) => ({ ...prev, [id]: price }));
  }

  const handleSubmit = async () => {
    const response = await otherCostRegisterFunc({
      clientId: id,
      ...prices
    });

    if (response.status === 200) {
      toast.success("Successfully Update!");
      navigate("/areaPrice/" + id, { replace: true, state: { selectSection: 2 } });
    } else {
      toast.error("거래처정보 변경실패!!");
    }
  };

  return (
    <>
      <Container className='align-items-center justify-content-center px-4 py-1'>
        {/* <HeardSearch searchForm={searchForm} /> */}
        <Table striped bordered hover className="mt-1">
          <thead>
            <tr>
              {headColumn.map((it, idx) => (
                <th className='text-center' key={idx}>
                  {it}
                  {idx === 2 &&
                    <>
                      – <span style={{ fontSize: "10px" }}>VAT별도</span>
                    </>
                  }
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr
              className='text-center align-middle'
              style={{ cursor: "pointer" }}
            >
              <td>{1}</td>
              <td>방명록</td>
              <td>
                <Form.Group as={Col} className="px-3" style={{ flexWrap: "nowrap" }}>
                  <Row sm="3">
                    <InputGroup size="sm">
                      <Form.Control
                        type="text"
                        placeholder="0"
                        name="guestbookCost"
                        size="sm"
                        min={0}
                        value={prices["guestbookCost"] !== undefined ?
                          formatLargeNumber(prices["guestbookCost"]?.toString())
                          : ""}

                        onChange={(e) => {
                          let rawValue = e.target.value.replace(/,/g, "");
                          rawValue = rawValue.replace(/^0+/, "");

                          const regex = /^\d+$/;
                          if (!regex.test(rawValue) && rawValue !== "") return;

                          handleInputChange(
                            "guestbookCost",
                            rawValue,
                          )
                        }}
                        style={{ textAlign: "center" }}
                      />
                    </InputGroup>
                  </Row>
                </Form.Group>
              </td>
            </tr>
            <tr
              className='text-center align-middle'
              style={{ cursor: "pointer" }}
            >
              <td>{2}</td>
              <td><Form.Group as={Col} className="px-3" style={{ flexWrap: "nowrap" }}>
                <Row sm="3">
                  <InputGroup size="sm">
                    <Form.Control
                      type="text"
                      placeholder="항목 2"
                      name="otherReasonCost1"
                      size="sm"
                      min={0}
                      value={prices["otherReasonCost1"] !== undefined ?
                        prices["otherReasonCost1"]
                        : ""}

                      onChange={(e) => {
                        let rawValue = e.target.value;

                        handleInputChange(
                          "otherReasonCost1",
                          rawValue,
                        )
                      }}
                      style={{ textAlign: "center" }}
                    />
                  </InputGroup>
                </Row>
              </Form.Group></td>
              <td>
                <Form.Group as={Col} className="px-3" style={{ flexWrap: "nowrap" }}>
                  <Row sm="3">
                    <InputGroup size="sm">
                      <Form.Control
                        type="text"
                        placeholder="0"
                        name="otherCost1"
                        size="sm"
                        min={0}
                        value={prices["otherCost1"] !== undefined ?
                          formatLargeNumber(prices["otherCost1"]?.toString())
                          : ""}

                        onChange={(e) => {
                          let rawValue = e.target.value.replace(/,/g, "");
                          rawValue = rawValue.replace(/^0+/, "");

                          const regex = /^\d+$/;
                          if (!regex.test(rawValue) && rawValue !== "") return;

                          handleInputChange(
                            "otherCost1",
                            rawValue,
                          )
                        }}
                        style={{ textAlign: "center" }}
                      />
                    </InputGroup>
                  </Row>
                </Form.Group>
              </td>
            </tr>
            <tr
              className='text-center align-middle'
              style={{ cursor: "pointer" }}
            >
              <td>{3}</td>
              <td><Form.Group as={Col} className="px-3" style={{ flexWrap: "nowrap" }}>
                <Row sm="3">
                  <InputGroup size="sm">
                    <Form.Control
                      type="text"
                      placeholder="항목 3"
                      name="otherReasonCost2"
                      size="sm"
                      min={0}
                      value={prices["otherReasonCost2"] !== undefined ?
                        prices["otherReasonCost2"]
                        : ""}

                      onChange={(e) => {
                        let rawValue = e.target.value;

                        handleInputChange(
                          "otherReasonCost2",
                          rawValue,
                        )
                      }}
                      style={{ textAlign: "center" }}
                    />
                  </InputGroup>
                </Row>
              </Form.Group></td>
              <td>
                <Form.Group as={Col} className="px-3" style={{ flexWrap: "nowrap" }}>
                  <Row sm="3">
                    <InputGroup size="sm">
                      <Form.Control
                        type="text"
                        placeholder="0"
                        name="otherCost2"
                        size="sm"
                        min={0}
                        value={prices["otherCost2"] !== undefined ?
                          formatLargeNumber(prices["otherCost2"]?.toString())
                          : ""}

                        onChange={(e) => {
                          let rawValue = e.target.value.replace(/,/g, "");
                          rawValue = rawValue.replace(/^0+/, "");

                          const regex = /^\d+$/;
                          if (!regex.test(rawValue) && rawValue !== "") return;

                          handleInputChange(
                            "otherCost2",
                            rawValue,
                          )
                        }}
                        style={{ textAlign: "center" }}
                      />
                    </InputGroup>
                  </Row>
                </Form.Group>
              </td>
            </tr>
            <tr
              className='text-center align-middle'
              style={{ cursor: "pointer" }}
            >
              <td>{4}</td>
              <td><Form.Group as={Col} className="px-3" style={{ flexWrap: "nowrap" }}>
                <Row sm="3">
                  <InputGroup size="sm">
                    <Form.Control
                      type="text"
                      placeholder="항목 4"
                      name="otherReasonCost3"
                      size="sm"
                      min={0}
                      value={prices["otherReasonCost3"] !== undefined ?
                        prices["otherReasonCost3"]
                        : ""}

                      onChange={(e) => {
                        let rawValue = e.target.value;

                        handleInputChange(
                          "otherReasonCost3",
                          rawValue,
                        )
                      }}
                      style={{ textAlign: "center" }}
                    />
                  </InputGroup>
                </Row>
              </Form.Group></td>
              <td>
                <Form.Group as={Col} className="px-3" style={{ flexWrap: "nowrap" }}>
                  <Row sm="3">
                    <InputGroup size="sm">
                      <Form.Control
                        type="text"
                        placeholder="0"
                        name="otherCost3"
                        size="sm"
                        min={0}
                        value={prices["otherCost3"] !== undefined ?
                          formatLargeNumber(prices["otherCost3"]?.toString())
                          : ""}

                        onChange={(e) => {
                          let rawValue = e.target.value.replace(/,/g, "");
                          rawValue = rawValue.replace(/^0+/, "");

                          const regex = /^\d+$/;
                          if (!regex.test(rawValue) && rawValue !== "") return;

                          handleInputChange(
                            "otherCost3",
                            rawValue,
                          )
                        }}
                        style={{ textAlign: "center" }}
                      />
                    </InputGroup>
                  </Row>
                </Form.Group>
              </td>
            </tr>
            <tr
              className='text-center align-middle'
              style={{ cursor: "pointer" }}
            >
              <td>{5}</td>
              <td><Form.Group as={Col} className="px-3" style={{ flexWrap: "nowrap" }}>
                <Row sm="3">
                  <InputGroup size="sm">
                    <Form.Control
                      type="text"
                      placeholder="항목 5"
                      name="otherReasonCost4"
                      size="sm"
                      min={0}
                      value={prices["otherReasonCost4"] !== undefined ?
                        prices["otherReasonCost4"]
                        : ""}

                      onChange={(e) => {
                        let rawValue = e.target.value;

                        handleInputChange(
                          "otherReasonCost4",
                          rawValue,
                        )
                      }}
                      style={{ textAlign: "center" }}
                    />
                  </InputGroup>
                </Row>
              </Form.Group></td>
              <td>
                <Form.Group as={Col} className="px-3" style={{ flexWrap: "nowrap" }}>
                  <Row sm="3">
                    <InputGroup size="sm">
                      <Form.Control
                        type="text"
                        placeholder="0"
                        name="otherCost4"
                        size="sm"
                        min={0}
                        value={prices["otherCost4"] !== undefined ?
                          formatLargeNumber(prices["otherCost4"]?.toString())
                          : ""}

                        onChange={(e) => {
                          let rawValue = e.target.value.replace(/,/g, "");
                          rawValue = rawValue.replace(/^0+/, "");

                          const regex = /^\d+$/;
                          if (!regex.test(rawValue) && rawValue !== "") return;

                          handleInputChange(
                            "otherCost4",
                            rawValue,
                          )
                        }}
                        style={{ textAlign: "center" }}
                      />
                    </InputGroup>
                  </Row>
                </Form.Group>
              </td>
            </tr>
            <tr
              className='text-center align-middle'
              style={{ cursor: "pointer" }}
            >
              <td>{6}</td>
              <td><Form.Group as={Col} className="px-3" style={{ flexWrap: "nowrap" }}>
                <Row sm="3">
                  <InputGroup size="sm">
                    <Form.Control
                      type="text"
                      placeholder="항목 6"
                      name="otherReasonCost5"
                      size="sm"
                      min={0}
                      value={prices["otherReasonCost5"] !== undefined ?
                        prices["otherReasonCost5"]
                        : ""}

                      onChange={(e) => {
                        let rawValue = e.target.value;

                        handleInputChange(
                          "otherReasonCost5",
                          rawValue,
                        )
                      }}
                      style={{ textAlign: "center" }}
                    />
                  </InputGroup>
                </Row>
              </Form.Group></td>
              <td>
                <Form.Group as={Col} className="px-3" style={{ flexWrap: "nowrap" }}>
                  <Row sm="3">
                    <InputGroup size="sm">
                      <Form.Control
                        type="text"
                        placeholder="0"
                        name="otherCost5"
                        size="sm"
                        min={0}
                        value={prices["otherCost5"] !== undefined ?
                          formatLargeNumber(prices["otherCost5"]?.toString())
                          : ""}

                        onChange={(e) => {
                          let rawValue = e.target.value.replace(/,/g, "");
                          rawValue = rawValue.replace(/^0+/, "");

                          const regex = /^\d+$/;
                          if (!regex.test(rawValue) && rawValue !== "") return;

                          handleInputChange(
                            "otherCost5",
                            rawValue,
                          )
                        }}
                        style={{ textAlign: "center" }}
                      />
                    </InputGroup>
                  </Row>
                </Form.Group>
              </td>
            </tr>
          </tbody>
        </Table>
        <Row className='mt-5 mb-3 text-end'>
          <Col>
            {/* <Button
              variant="outline-secondary"
              className="px-4 mx-3"
              onClick={() => {
                navigate("/areaPrice/" + id, { replace: true, state: { selectSection: 1 } });
              }}
            >
              목 록
            </Button> */}
            <Button
              as='input'
              type='submit'
              value={"수정"}
              className='px-4'
              onClick={handleSubmit}
            />
          </Col>
        </Row>

      </Container>
    </>
  );
};

export default memo(OtherCost);
