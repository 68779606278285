import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getDeliveryGoodsOrderFunc, updateDeliveryGoodsOrderFunc } from "../api";
import { formatDateToYYYYMMDDHHMM, formatDateToYYYYMMDDHHMMSS, formatLargeNumber } from "../utils";
import image from "../assets/logo_designtt.png"
import { ORDER_STATUS } from "../utils/constant";

const PrintReceiving = React.forwardRef((props, ref) => {
    const [data, setData] = useState([]);
    const { id } = useParams();
    const [state, setState] = useState("")
    const [extraFee, setExtraFee] = useState({ reasonFee1: "", fee1: "", reasonFee2: "", fee2: "" })

    const [shippingBox, setShippingBox] = useState({ boxLarge: "", boxMedium: "", boxSmall: "", boxTotal: "" })
    const [quickService, setQuickService] = useState({ damas: "", motorcycle: "", quickTotal: "" })
    const [parcel, setParcel] = useState({ parcelLarge: "", parcelMedium: "", parcelSmall: "", parcelTotal: "" })

    const [forms, setForms] = useState({
        orderCode: "",
        deliveryMethod: "",
        staffPhone: "",
        recipientName: "",
        recipientPhone: "",
        addr1: "",
        addr2: "",
        zipCode: "",
        specialMemo: "",
        state: "신규",
        addressID: "",
        totalPrice: 0,
        shippingDate: ""

    });
    useEffect(() => {
        //  전체 물품 리스트
        const DeliveryGoodsOrderGet = async (id) => {
            const response = await getDeliveryGoodsOrderFunc(id);
            if (response.status === 200) {

                setData(response.data)
                // console.log("🚀 ~ DeliveryGoodsOrderGet ~ response.data:", response.data)
                setForms({
                    ...forms,
                    orderCode: response?.data?.orderCode || "",
                    deliveryMethod: response?.data?.combined ? "합배송" : "개별배송" || "",
                    staffPhone: response?.data?.staffPhone,
                    recipientName: response?.data?.recipientName,
                    recipientPhone: response?.data?.recipientPhone,
                    addr1: response?.data?.addr1,
                    addr2: response?.data?.addr2,
                    zipCode: response?.data?.zipCode,
                    specialMemo: response?.data?.specialMemo,
                    state: response?.data?.state,
                    shippingDate: response?.data?.shippingDate,
                    totalPrice: response?.data?.totalPrice,
                    addressID: response?.data?.addressID || "",
                })
                //     setPreview(response.data.eventImage)
                setState(response?.data?.state)
                if (response?.data) {
                    if (Number(response?.data?.totalPrice) !== 0) {
                        console.log(response);
                        setParcel(JSON.parse(response.data?.parcel))
                        setShippingBox(JSON.parse(response.data?.shippingBox))
                        setQuickService(JSON.parse(response.data?.quickService))
                        response?.data?.extraFee && setExtraFee(JSON.parse(response?.data?.extraFee))
                    }
                }

            }


        };
        DeliveryGoodsOrderGet(id)


    }, [id]);
    return (
        <div ref={ref}>
            <div key={1} className={`print_wapper print_wapper_order`}>
                <div className={`paper ${data?.goods?.length === 5 ? "paper_padding" : ""}`}>
                    <div className="content" style={{ border: "0" }}>
                        <div className="header border-bottom-none" style={{ position: "relative", border: "1px solid #000" }}>
                            {data?.state === ORDER_STATUS.COMPLETE ?
                                <h1 style={{ marginBottom: "0" }}>물류 발송 완료 보고서</h1> :
                                <h1 style={{ marginBottom: "0" }}>물류 신청서</h1>
                            }
                            <div style={{ position: "absolute", right: "25px" }}>
                                <img src={image} alt="" width={100} height={60} />
                            </div>

                            <div style={{ position: "absolute", left: "5px" }}>
                                <div style={{ textAlign: "start", display: "flex", gap: "8px" }}>
                                    <div style={{ width: "85px" }}>
                                        최종수정일시
                                    </div>
                                    <div>
                                        {formatDateToYYYYMMDDHHMMSS(new Date())}
                                    </div>
                                </div>
                                <div style={{ display: "flex", gap: "8px" }}>
                                    <div className="text-align-start" style={{ textAlign: "start", width: "85px" }}>출력일시</div>
                                    <div>{props.orderLogs?.length ? formatDateToYYYYMMDDHHMMSS(props.orderLogs?.[0].createdAt) : ''}</div>
                                </div>
                            </div>
                        </div>

                        <div className="row" style={{
                            display: "grid",
                            gridTemplateRows: "auto auto",
                            gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                            maxWidth: "102%",
                            boxSizing: "border-box",
                            marginLeft: "0",
                            marginRight: "0",
                            border: "1px solid #000",
                        }}>
                            <div className="item-print">신청번호</div>
                            <div className="item-print half" style={{ gridColumn: "span 2" }}>{data.orderCode}</div>
                            <div className="item-print">신청일자</div>
                            <div className="item-print border-right-none" style={{ gridColumn: "span 2" }}>{formatDateToYYYYMMDDHHMMSS(data?.createdAt)}</div>

                            <div className="item-print">회사명</div>
                            <div className="item-print half">{data.companyName}</div>
                            <div className="item-print">부서명</div>
                            <div className="item-print">{data.department}</div>
                            <div className="item-print">신청인</div>
                            <div className="item-print border-right-none half"> {data ? data?.staffName : ""}</div>

                            <div className="item-print">신청인 연락처</div>
                            <div className="item-print">{data.staffPhone}</div>
                            <div className="item-print">제품명</div>
                            <div className="item-print border-right-none half" style={{ gridColumn: "span 3" }}> {data.productName}</div>

                            <div className="item-print item-print-highlight ">수취인</div>
                            <div className="item-print half" style={{ gridColumn: "span 2" }}>
                                {data?.recipientName}
                            </div>
                            <div className="item-print item-print-highlight ">수취인 연락처</div>
                            <div className="item-print border-right-none" style={{ gridColumn: "span 2" }}>{data.recipientPhone}</div>

                            <div className="item-print item-print-highlight ">수취인 주소</div>
                            <div className="item-print border-right-none half" style={{ gridColumn: "span 5", justifyContent: "start" }}>
                                {data.addr1} {data.addr2}</div>

                            <div className="item-print">특이 사항</div>
                            <div className="item-print border-right-none half" style={{ gridColumn: "span 5", justifyContent: "start" }}>{data.specialMemo}</div>

                            <div className="item-print" style={{ gridColumn: "span 5" }}>발송 정보</div>
                            <div className="item-print border-right-none">비고</div>

                            <div className="item-print" style={{ width: "100%", padding: "0" }}>
                                <div style={{ flex: "1", display: "flex", alignItems: "center", justifyContent: "center" }}>1</div>
                                <div className="item-print-left" style={{ flex: "2", minHeight: "41px", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                    <span>발송비용 </span>
                                </div>
                            </div>
                            <div className="item-print cost-print" style={{ gridColumn: "span 4", padding: "0px 0px" }}>
                                <div className="item-print-cost ">이형</div>
                                <div className="item-print-cost ">{parcel?.oddShaped ?? ''}</div>
                                <div className="item-print-cost ">대</div>
                                <div className="item-print-cost ">{parcel?.parcelLarge ?? ""}</div>
                                <div className="item-print-cost ">중</div>
                                <div className="item-print-cost ">{parcel?.parcelMedium ?? ""}</div>
                                <div className="item-print-cost ">소</div>
                                <div className="item-print-cost ">{parcel?.parcelSmall ?? ""}</div>
                                <div className="item-print-cost ">극소</div>
                                <div className="item-print-cost " style={{ border: "none" }}>{parcel?.parcelMini ?? ""}</div>
                            </div>
                            <div className="item-print border-right-none"></div>

                            <div className="item-print border-bottom-none" style={{ width: "100%", padding: "0" }}>
                                <div style={{ flex: "1", display: "flex", alignItems: "center", justifyContent: "center" }}>2</div>
                                <div className="item-print-left"
                                    style={{ flex: "2", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                    <span>재포장(박스) </span>
                                    <span>사용</span>
                                </div>
                            </div>
                            <div className="item-print cost-print border-bottom-none" style={{ gridColumn: "span 4", padding: "0px 0px" }}>
                                <div className="item-print-cost ">대</div>
                                <div className="item-print-cost " style={{ gridColumn: "span 2" }}>{shippingBox?.boxLarge ?? 0}</div>
                                <div className="item-print-cost ">중</div>
                                <div className="item-print-cost " style={{ gridColumn: "span 2" }}>{shippingBox?.boxMedium ?? 0}</div>
                                <div className="item-print-cost ">소</div>
                                <div className="item-print-cost border-right-none" style={{ gridColumn: "span 3" }}>{shippingBox?.boxSmall ?? 0}</div>
                            </div>
                            <div className="item-print border-right-none border-bottom-none"></div>
                        </div>

                        <table className="table table-event mt-3" style={{
                            borderTop: "0",
                            marginTop: "16px",
                            display: "flex",
                            flexDirection: "column",
                            tableLayout: "fixed",
                            borderBottom: "1px solid #000"
                            // borderCollapse: "collapse"
                        }}>
                            <thead>
                                <tr className="title-tr" >
                                    <th className="title title-event border-print-bottom-none" style={{ width: "6%", borderTop: "1px solid #000" }}>번호</th>
                                    <th className="title title-event border-print-bottom-none" style={{ width: "9%", borderTop: "1px solid #000" }}>제품명</th>
                                    <th className="title title-event border-print-bottom-none" style={{ width: "8%", borderTop: "1px solid #000" }}>분류</th>
                                    <th className="title title-event border-print-bottom-none" style={{ width: "30%", borderTop: "1px solid #000" }}>물품명</th>
                                    <th className="title title-event border-print-bottom-none" style={{ width: "8%", borderTop: "1px solid #000" }}>포장정보</th>
                                    <th className="title title-event border-print-bottom-none" style={{ width: "15%", borderTop: "1px solid #000" }}>사진</th>
                                    <th className="title title-event border-print-bottom-none" style={{ width: "8%", borderTop: "1px solid #000" }}>주문수량</th>
                                    <th className="title title-event border-print-bottom-none" style={{ width: "8%", borderTop: "1px solid #000" }}>단위</th>
                                    <th className="title title-event border-print-bottom-none" style={{ width: "8%", borderRight: "1px solid #000", borderTop: "1px solid #000" }}>적재위치</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.goods?.map((it, idx) => (
                                    <tr className={`title-tr`}>
                                        <td className={`title title-event border-print-top`} style={{ width: "6%" }}>{idx + 1}</td>
                                        <td className={`title title-event border-print-top`} style={{ width: "9%" }}>{it.goodsId?.productName}</td>
                                        <td className={`title title-event border-print-top`} style={{ width: "8%" }}>{it.goodsId?.category}</td>
                                        <td className={`title title-event border-print-top`} style={{ width: "30%", maxWidth: "30%", whiteSpace: "normal", minWidth: "auto" }}>
                                            <span>
                                                {it?.goodsId?.goodsName}
                                            </span>
                                        </td>
                                        <td className={`title title-event border-print-top`} style={{ width: "8%" }}></td>
                                        <td className={`title title-event border-print-top`} style={{ width: "15%" }}>
                                            {it?.goodsId?.goodsImage[0] ? <img
                                                style={{
                                                    width: "100px",
                                                    aspectRatio: "1 / 1",
                                                    objectFit: "cover"
                                                }}
                                                src={it?.goodsId?.goodsImage[0] ?? ''}
                                                alt=''
                                            /> : <div style={{ height: "100px" }}></div>}
                                        </td>
                                        <td className={`title title-event border-print-top`} style={{ width: "8%" }}>{it?.amount} </td>
                                        <td className={`title title-event border-print-top`} style={{ width: "8%" }}>{it?.goodsId?.unit}</td>
                                        <td className={`title title-event border-print-top`} style={{ width: "8%", borderRight: "1px solid #000" }}>{it?.goodsId?.loadingLocation}</td>
                                    </tr>

                                ))}
                            </tbody>
                        </table>

                        {/* <div className="table mt-3" style={{ border: "1px solid #000", }}>
                            <p className="title">번호</p>
                            <p className="title">제품명</p>
                            <p className="title">분류</p>
                            <p className="title">물품명</p>
                            <p className="title">포장정보</p>
                            <p className="title">사진</p>
                            <p className="title">주문수량</p>
                            <p className="title">단위</p>
                            <p className="title">적재위치</p>
                            {data?.goods?.map((it, idx) => (
                                <>
                                    <p>{data?.goods?.length - idx}</p>
                                    <p>{it.goodsId?.productName}</p>
                                    <p>{it.goodsId?.category}</p>
                                    <p>{it?.goodsId?.goodsName}</p>
                                    <p><img
                                        src={it?.goodsId?.goodsImage[0]}
                                        alt=''
                                    /></p>
                                    <p>{it?.amount} </p>
                                    <p>{it?.goodsId?.unit}</p>
                                    <p>{it?.goodsId?.loadingLocation}</p>
                                    <p></p>
                                </>

                            ))}


                        </div> */}

                        <div className="row pt-3" style={{
                            display: "grid",
                            gridTemplateRows: "auto auto",
                            gridTemplateColumns: "repeat(18, minmax(0,1fr))",
                            maxWidth: "102%",
                            boxSizing: "border-box",
                            marginLeft: "0",
                            marginRight: "0",
                            border: "none",
                            breakInside: "avoid"
                        }}>
                            <div className="item-print border-top-item border-left-item border-bottom-none" style={{ gridColumn: "span 6" }}>신청 내용 견적정보</div>
                            <div style={{ gridColumn: "span 12" }}></div>

                            <div className="item-print border-top-item border-left-item">번호</div>
                            <div className="item-print border-top-item" style={{ gridColumn: "span 3" }}>항목</div>
                            <div className="item-print border-top-item" style={{ gridColumn: "span 10" }}>세부항목</div>
                            <div className="item-print border-top-item" style={{ gridColumn: "span 4" }}>금액(원)_ vat별도</div>

                            <div className="item-print border-left-item border-bottom-none">1</div>
                            <div className="item-print border-bottom-none" style={{ gridColumn: "span 3" }}>발송비용</div>
                            <div className="item-print item-print-cost border-bottom-none" >이형</div>
                            <div className="item-print item-print-cost border-bottom-none">{parcel?.oddShaped}</div>
                            <div className="item-print item-print-cost border-bottom-none">대</div>
                            <div className="item-print item-print-cost border-bottom-none">{parcel?.parcelLarge}</div>
                            <div className="item-print item-print-cost border-bottom-none">중</div>
                            <div className="item-print item-print-cost border-bottom-none">{parcel?.parcelMedium}</div>
                            <div className="item-print item-print-cost border-bottom-none">소</div>
                            <div className="item-print item-print-cost border-bottom-none">{parcel?.parcelSmall}</div>
                            <div className="item-print item-print-cost border-bottom-none">극소</div>
                            <div className="item-print border-bottom-none">{parcel?.parcelMini}</div>
                            <div className="item-print border-bottom-none" style={{ gridColumn: "span 4" }}>
                                <span className="won-money-icon">
                                    ₩
                                </span>
                                {formatLargeNumber(parcel.parcelTotal?.toString()) || 0}
                            </div>

                            <div className="item-print border-left-item" style={{ borderTop: "1px solid #000" }}>2</div>
                            <div className="item-print" style={{ gridColumn: "span 3", borderTop: "1px solid #000" }}>재포장(박스) 비용</div>
                            <div className="item-print" style={{ gridColumn: "span 10", borderTop: "1px solid #000", display: "grid", gridTemplateColumns: "repeat(6, 1fr)", padding: "0" }}>
                                <div className="item-print border-bottom-none item-print-cost">대</div>
                                <div className="item-print border-bottom-none item-print-cost">{shippingBox?.boxLarge}</div>
                                <div className="item-print border-bottom-none item-print-cost">중</div>
                                <div className="item-print border-bottom-none item-print-cost">{shippingBox?.boxMedium}</div>
                                <div className="item-print border-bottom-none item-print-cost">소</div>
                                <div className="item-print border-bottom-none border-right-none">{shippingBox?.boxSmall}</div>
                            </div>
                            <div className="item-print" style={{ gridColumn: "span 4", borderTop: "1px solid #000" }}>
                                <span className="won-money-icon">
                                    ₩
                                </span>
                                {formatLargeNumber(shippingBox.boxTotal?.toString()) || 0}
                            </div>

                            <div className="item-print border-left-item">3</div>
                            <div className="item-print" style={{ gridColumn: "span 3" }}>퀵서비스</div>
                            <div className="item-print" style={{ gridColumn: "span 10", display: "grid", gridTemplateColumns: "repeat(4, 1fr)", padding: "0" }}>
                                <div className="item-print border-bottom-none item-print-cost">트럭</div>
                                <div className="item-print border-bottom-none item-print-cost">{formatLargeNumber(quickService.damas?.toString())}</div>
                                <div className="item-print border-bottom-none item-print-cost">오토바이</div>
                                <div className="item-print border-bottom-none border-right-none">{formatLargeNumber(quickService.motorcycle?.toString())}</div>
                            </div>
                            <div className="item-print" style={{ gridColumn: "span 4" }}>
                                <span className="won-money-icon">
                                    ₩
                                </span>
                                {formatLargeNumber((quickService.quickTotal)?.toString()) || 0}
                            </div>

                            <div className="item-print border-left-item">4</div>
                            <div className="item-print" style={{ gridColumn: "span 3" }}>기 타</div>
                            <div className="item-print" style={{ gridColumn: "span 10", display: "grid", gridTemplateColumns: "repeat(4, 1fr)", padding: "0" }}>
                                <div className="item-print border-bottom-none item-print-cost">{extraFee.reasonFee1}</div>
                                <div className="item-print border-bottom-none item-print-cost">{formatLargeNumber(extraFee.fee1?.toString())}</div>
                                <div className="item-print border-bottom-none item-print-cost">{extraFee.reasonFee2}</div>
                                <div className="item-print border-bottom-none border-right-none">{formatLargeNumber(extraFee.fee1?.toString())}</div>
                            </div>
                            <div className="item-print" style={{ gridColumn: "span 4" }}>
                                <span className="won-money-icon">
                                    ₩
                                </span>
                                {formatLargeNumber((Number(extraFee.fee1) +
                                    Number(extraFee.fee2)).toString())}
                            </div>

                            <div className="item-print border-bottom-none" style={{ gridColumn: "span 12", fontSize: "12px" }}>
                                *견적금액은 수정사항이 있는 경우 변경될 수 있으며, 신청단계가 처리 완료된 때에 확인바랍니다.
                            </div>
                            <div className="item-print" style={{ gridColumn: "span 2" }}>소계</div>
                            <div className="item-print" style={{ gridColumn: "span 4" }}>
                                <span className="won-money-icon">
                                    ₩
                                </span>
                                {
                                    formatLargeNumber((
                                        Number(parcel.parcelTotal) +
                                        Number(shippingBox.boxTotal) +
                                        Number(quickService?.quickTotal) +
                                        Number(extraFee.fee1) +
                                        Number(extraFee.fee2)
                                    )?.toString())
                                }
                            </div>

                        </div>
                        <div className="content" style={{ border: "0" }}>
                            <div className="row" style={{
                                display: "grid",
                                gridTemplateRows: "auto auto",
                                gridTemplateColumns: "repeat(9, minmax(0,1fr))",
                                maxWidth: "102%",
                                boxSizing: "border-box",
                                marginLeft: "0",
                                marginRight: "0",
                                border: "none",
                                marginTop: "8px"
                            }}>
                                <div className="item-print border-top-item border-left-item" style={{ gridRow: "span 2" }}>공급자</div>
                                <div className="item-print border-top-item" >사업자번호</div>
                                <div className="item-print border-top-item" style={{ gridColumn: "span 3" }}>
                                    220-86-88486
                                </div>
                                <div className="item-print border-top-item" >소재지</div>
                                <div className="item-print border-top-item" style={{ gridColumn: "span 3" }}>
                                    경기도 화성시 세자로396번길 49
                                </div>

                                <div className="item-print">상 호</div>
                                <div className="item-print" >
                                    디자인티티
                                </div>
                                <div className="item-print" >대표자</div>
                                <div className="item-print" >
                                    손건화
                                </div>
                                <div className="item-print">업 태</div>
                                <div className="item-print" >
                                    제 조
                                </div>
                                <div className="item-print" > 종 목</div>
                                <div className="item-print" >
                                    전시디자인
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div >

    );
});

export default PrintReceiving;
