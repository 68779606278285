import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "@mui/material/Container";
import { memo, useEffect, useState, useRef } from "react";
import Table from "react-bootstrap/Table";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getAllConfigCostsFunc, getAllOrderLogsFunc, getDeliveryGoodsOrderFunc, updateDeliveryGoodsOrderFunc } from "../../api";
import { InputGroup } from "react-bootstrap";
import PostButton from "../../components/PostButton";
import PrintReceivingForm from "../../components/PrintReceivingForm";
import { useReactToPrint } from 'react-to-print';
import { formatDateToYYYYMMDDHHMM, formatDateToYYYYMMDDHHMMSS, formatLargeNumber } from '../../utils'
import { ORDER_STATUS } from "../../utils/constant";

const ReceivingForm = () => {
  // state 취득
  const { id } = useParams()
  const componentRef = useRef();
  const navigate = useNavigate();
  const [data, setData] = useState({})
  const [state, setState] = useState("")
  const [oldState, setOldState] = useState("")

  const [preview, setPreview] = useState("")
  const [imgSrc, setImgSrc] = useState("");
  const [installPreview, setInstallPreview] = useState("");

  const [shippingBox, setShippingBox] = useState({ boxLarge: "", boxMedium: "", boxSmall: "", boxTotal: "" })
  const [quickService, setQuickService] = useState({ damas: "", motorcycle: "", quickTotal: "" })
  const [parcel, setParcel] = useState({ oddShaped: "", parcelLarge: "", parcelMedium: "", parcelSmall: "", parcelMini: "", parcelTotal: "" })
  const [flag, setFlag] = useState(false);
  const location = useLocation();
  const isOrder = location?.state && location?.state?.isOrder

  const [boxPrices, setBoxPrices] = useState("")
  const [parcelPrices, setParcelPrices] = useState("");
  const [extraFee, setExtraFee] = useState({ reasonFee1: "", fee1: "", reasonFee2: "", fee2: "" })

  const [orderLogs, setOrderLogs] = useState();

  const [forms, setForms] = useState({
    orderCode: "",
    deliveryMethod: "",
    staffPhone: "",
    recipientName: "",
    recipientPhone: "",
    addr1: "",
    addr2: "",
    zipCode: "",
    specialMemo: "",
    state: "신규",
    addressID: "",
    totalPrice: 0,
    shippingDate: "",
    hasAdditionalCosts: ""
  });

  const handleChangeExtra = (e) => {
    // console.log(`${e.target.name}:${e.target.value}`);
    const { name, value } = e.target;
    let rawValue = value.replace(/,/g, "");

    const updatedExtraFee = {
      ...extraFee,
      [name]: rawValue
    };

    setExtraFee(updatedExtraFee);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const handleChangeImage = (e) => {
    // encodeFileToBase64(e.target.files[0]);
    setImgSrc(e.target.files[0]);
  };


  // 이미지 뷰어
  useEffect(() => {

    if (imgSrc) {
      setInstallPreview(URL.createObjectURL(imgSrc));
    }
  }, [imgSrc]);


  const handleChangeState = (e) => {
    const { name, type, checked, value } = e.target;

    setForms({
      ...forms,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  console.log('forms', forms)
  // 금액 계산 

  const allConfigCosts = async (id) => {
    const response = await getAllConfigCostsFunc(id);

    if (response.status === 200) {
      const filteredPrices = { ...response?.data?.data };

      const newBoxPrices = {
        boxLarge: Number(filteredPrices.boxLarge) || 0,
        boxMedium: Number(filteredPrices.boxMedium) || 0,
        boxSmall: Number(filteredPrices.boxSmall) || 0,
      };

      const newParcelPrices = {
        oddShaped: Number(filteredPrices.parcelOddShaped) || 0,
        parcelLarge: Number(filteredPrices.parcelLarge) || 0,
        parcelMedium: Number(filteredPrices.parcelMedium) || 0,
        parcelSmall: Number(filteredPrices.parcelSmall) || 0,
        parcelMini: Number(filteredPrices.parcelMini) || 0,
        additionalCosts: Number(filteredPrices.additionalCosts) || 0,
      };

      // Set state
      setBoxPrices(newBoxPrices);
      setParcelPrices(newParcelPrices);
    } else {
      toast.error("부스종류를 가져오는데 실패했습니다.");
    }
  };


  const handleChangeBox = (e) => {
    const { name, value } = e.target;
    const updatedBox = {
      ...shippingBox,
      [name]: value
    };
    // 총액 계산
    updatedBox.boxTotal = Object.keys(boxPrices).reduce((total, box) => {
      return total + (boxPrices[box] * (updatedBox[box] || 0));
    }, 0);

    setShippingBox(updatedBox);
  };

  const handleChangeParcel = (e) => {
    const { name, value } = e.target;
    const updatedParcel = {
      ...parcel,
      [name]: value
    };

    updatedParcel.parcelTotal = Object.keys(parcelPrices).reduce((total, size) => {
      if (forms.hasAdditionalCosts) {
        return total + (parcelPrices[size] * (updatedParcel[size] || 0) + (updatedParcel[size] || 0) * parcelPrices["additionalCosts"]);
      }
      return total + (parcelPrices[size] * (updatedParcel[size] || 0));
    }, 0);

    setParcel(updatedParcel);
  };

  useEffect(() => {
    // 전체 합산 금액 계산
    if (forms.hasAdditionalCosts) {
      const updatedParcel = {
        ...parcel,
      };

      const parcelTypes = ["oddShaped", "parcelLarge", "parcelMedium", "parcelMini", "parcelSmall"];

      const totalParcels = parcelTypes.reduce((sum, key) => sum + Number(parcel[key] || 0), 0);

      updatedParcel.parcelTotal += totalParcels * (parcelPrices?.additionalCosts || 0);

      setParcel(updatedParcel);
    } else {
      const updatedParcel = {
        ...parcel,
      };

      const parcelTypes = ["oddShaped", "parcelLarge", "parcelMedium", "parcelMini", "parcelSmall"];

      const totalParcels = parcelTypes.reduce((sum, key) => sum + Number(parcel[key] || 0), 0);

      updatedParcel.parcelTotal -= totalParcels * (parcelPrices?.additionalCosts || 0);

      setParcel(updatedParcel);
    }

  }, [forms.hasAdditionalCosts]);

  const handleChangeQuick = (e) => {
    // console.log(`${e.target.name}:${e.target.value}`);
    const { name, value } = e.target;
    let rawValue = value.replace(/,/g, "");

    const updatedQuick = {
      ...quickService,
      [name]: rawValue
    };
    // 입력된 금액들을 숫자로 변환하여 합산
    updatedQuick.quickTotal = Object.values(updatedQuick)
      .filter(v => v !== updatedQuick.quickTotal)
      .reduce((total, currentValue) => total + (Number(currentValue) || 0), 0);

    setQuickService(updatedQuick);
  };

  useEffect(() => {
    // 전체 합산 금액 계산
    const total = Number(quickService.quickTotal) + Number(parcel.parcelTotal) + Number(shippingBox.boxTotal) + Number(extraFee.fee1) + Number(extraFee.fee2);

    setForms({ ...forms, totalPrice: total });

  }, [quickService.quickTotal, parcel.parcelTotal, shippingBox.boxTotal, forms.hasAdditionalCosts, extraFee.fee1, extraFee.fee2]); // 의존성 배열

  useEffect(() => {
    setForms({ ...forms, addressID: "" })
    if (flag) {
      setForms({ ...forms, addr2: "" })
    }
    if (forms.addr1) {
      setFlag(true);
    }
  }, [forms.addr1]);

  useEffect(() => {
    //  전체 물품 리스트
    const DeliveryGoodsOrderGet = async (id) => {
      const response = await getDeliveryGoodsOrderFunc(id);
      if (response.status === 200) {

        allConfigCosts(response?.data?.clientID);
        setData(response.data)
        console.log("🚀 ~ DeliveryGoodsOrderGet ~ response.data:", response.data)
        setForms({
          ...forms,
          orderCode: response?.data?.orderCode || "",
          deliveryMethod: response?.data?.combined ? "합배송" : "개별배송" || "",
          staffPhone: response?.data?.staffPhone,
          recipientName: response?.data?.recipientName,
          recipientPhone: response?.data?.recipientPhone,
          addr1: response?.data?.addr1,
          addr2: response?.data?.addr2,
          zipCode: response?.data?.zipCode,
          specialMemo: response?.data?.specialMemo,
          state: response?.data?.state,
          shippingDate: response?.data?.shippingDate,
          totalPrice: response?.data?.totalPrice,
          addressID: response?.data?.addressID || "",
          hasAdditionalCosts: response?.data?.hasAdditionalCosts,
        })
        //     setPreview(response.data.eventImage)
        setState(response.data.state)
        setOldState(response.data.state)
        response?.data?.extraFee && setExtraFee(JSON.parse(response?.data?.extraFee))

        if (Number(response?.data?.totalPrice) !== 0) {
          setParcel(JSON.parse(response.data?.parcel))
          setShippingBox(JSON.parse(response.data?.shippingBox))
          setQuickService(JSON.parse(response.data?.quickService))
        }
      } else {
        toast.error("행사요청을 가져오는데 실패했습니다.");
      }


    };
    DeliveryGoodsOrderGet(id)


  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append("state", state);
    formData.append("shippingBox", JSON.stringify(shippingBox));
    formData.append("quickService", JSON.stringify(quickService));
    formData.append("parcel", JSON.stringify(parcel));
    formData.append("extraFee", JSON.stringify(extraFee));

    if (!forms.hasAdditionalCosts) {
      formData.append("hasAdditionalCosts", false);
    }

    formData.delete('boxLarge')
    formData.delete('boxMedium')
    formData.delete('boxSmall')
    formData.delete('parcelLarge')
    formData.delete('parcelMedium')
    formData.delete('parcelSmall')
    formData.delete('parcelMini')
    formData.delete('damas')
    formData.delete('motorcycle')
    formData.delete('boxTotal')
    formData.delete('quickTotal')
    formData.delete('parcelTotal')
    formData.delete('reasonFee1')
    formData.delete('reasonFee2')
    formData.delete('fee1')
    formData.delete('fee2')

    // formData.append("boothContent", content);

    // console.log(Object.fromEntries(formData.entries()));

    const response = await updateDeliveryGoodsOrderFunc(id, formData);
    if (response.status === 200) {
      toast.success("Successfully Update!");
      if (isOrder) {
        setTimeout(() => {
          navigate("/disReceving", { replace: true, state: { backPage: true } });
        }, 1000);
      } else {
        setTimeout(() => {
          navigate("/disSendHistory", { replace: true, state: { backPage: true } });
        }, 1000);
      }
    } else {
      toast.error("행사요청 업데이트에 실패했습니다.!");
    }
  };

  useEffect(() => {
    const fetchOrderLogs = async (id) => {
      const response = await getAllOrderLogsFunc(id);

      if (response.status === 200) {
        setOrderLogs(response.data?.data)
      } else {
        toast.error("행사요청을 가져오는데 실패했습니다.");
      }
    };

    fetchOrderLogs(id)
  }, [id]);

  return (
    <>
      <Container maxWidth="md" className="py-3">
        <Row className="fs-1 fw-bold p-2 border-bottom mb-5">
          물류접수 현황 상세
        </Row>
        <Form onSubmit={handleSubmit}>

          <Row className=' mx-1 mb-3 text-xl text-slate-600'>1.주문자정보</Row>

          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              코드
            </Form.Label>
            <Col sm="3">
              <Form.Control
                name="orderCode"
                value={forms.orderCode}
                onChange={handleChangeState}
                type="text"
                size="sm"
                disabled
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              제품명
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="9" >
                {data?.productName}
              </Form.Label>
              {/* <Form.Control type="text" name="companyCode" value={state.companyCode} onChange={handleChangeState} size="sm" required /> */}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              거래처명
            </Form.Label>

            <Col sm="7">
              <Form.Label column sm="7" >
                {data?.companyName}
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              주문부서
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="9">
                {data?.department}
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              신청인
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="7" >
                {data?.staffName}
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              전화번호
            </Form.Label>
            <Col sm="3">
              <Form.Control
                type="text"
                name="staffPhone"
                onChange={handleChangeState}
                value={forms.staffPhone}
                size="sm"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              주문일자
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="7" >
                {data?.createdAt ? formatDateToYYYYMMDDHHMM(data?.createdAt) : ""}
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              상태
            </Form.Label>
            <Col sm="9">
              {["신규", "접수", "발송", "취소", "취소완료"].map((item, idx) => (
                <Form.Check
                  key={idx}
                  inline
                  label={item}
                  value={item}
                  checked={state === item}
                  onChange={(e) => setState(e.target.value)}
                  disabled={[ORDER_STATUS.CANCEL, ORDER_STATUS.CANCEL_COMPLETE].includes(oldState) && ![ORDER_STATUS.CANCEL, ORDER_STATUS.CANCEL_COMPLETE].includes(item)}
                  type="radio"
                />
              ))}
            </Col>
          </Form.Group>

          {!!orderLogs?.length &&
            <Form.Group as={Row} className="mb-3 px-3">
              <Form.Label column sm="2">
                상태로그
              </Form.Label>
              <Col sm="12">
                <Table striped bordered hover >
                  <thead>
                    <tr>
                      <th className="text-center">번호</th>
                      <th className="text-center">일자</th>
                      <th className="text-center">상태</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderLogs?.map((item, idx) => (<tr key={item._id} className="text-center">
                      <td>{orderLogs?.length - idx}</td>
                      <td>{formatDateToYYYYMMDDHHMMSS(item?.createdAt)}</td>
                      <td>{item?.status}</td>
                    </tr>))}
                  </tbody>
                </Table>
              </Col>
            </Form.Group>}
          <hr />
          <Row className=' mx-1 mb-3 text-xl text-slate-600'>2.수취자정보</Row>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              수취자
            </Form.Label>

            <Col sm="9">
              <Form.Control
                type="text"
                name="recipientName"
                value={forms.recipientName}
                onChange={handleChangeState}
                size="sm"
              />
            </Col>
          </Form.Group>
          {/* 전화번호 */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              전화번호
            </Form.Label>
            <Col sm="9">
              <Form.Control
                type="text"
                name="recipientPhone"
                value={forms.recipientPhone}
                onChange={handleChangeState}
                size="sm"
              />
            </Col>
          </Form.Group>
          {/* 배송방식 */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              배송방식
            </Form.Label>
            <Col sm="9">
              <Form.Control
                type="text"
                name="deliveryMethod"
                value={forms.deliveryMethod}
                onChange={handleChangeState}
                size="sm"
              />
            </Col>
          </Form.Group>
          {/* 주소코드 */}
          {/* <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              주소코드
            </Form.Label>
            <Col sm="9">
              <Form.Control
                type="text"
                name="addressID"
                disabled
                value={forms.addressID}
                onChange={handleChangeState}
                size="sm"
              />
            </Col>
          </Form.Group> */}

          {/* 수취장소 */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              수취장소
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                name="addr1"
                value={forms.addr1}
                onChange={handleChangeState}
                required
                size="sm"
              />
            </Col>
            <Col sm="3">
              <PostButton forms={forms} setForms={setForms} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2"></Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                name="addr2"
                value={forms.addr2}
                onChange={handleChangeState}
                size="sm"
                required
              />
            </Col>
            <Col sm="3">
              <Form.Control
                type="text"
                name="zipCode"
                value={forms.zipCode}
                onChange={handleChangeState}
                size="sm"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            {/* 특이사항 */}
            <Form.Label column sm="2">
              특이사항
            </Form.Label>
            <Col sm="9">
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">

                <Form.Control as="textarea" value={forms.specialMemo} name="specialMemo" onChange={handleChangeState} />
              </Form.Group>

            </Col>
          </Form.Group>
          <hr />

          <Row className=' mx-1 mb-3 text-xl text-slate-600'> 3.물류신청품목</Row>
          <Table striped bordered hover >
            <thead>
              <tr>
                <th className="text-center">번호</th>
                <th className="text-center">물품명</th>
                <th className="text-center">제품명</th>
                <th className="text-center">종류</th>
                <th className="text-center">주문수량</th>
              </tr>
            </thead>
            <tbody>
              {data && data?.goods?.map((item, idx) => (<tr key={item._id} className="text-center">
                <td>{data?.goods?.length - idx}</td>
                <td>{item?.goodsId?.goodsName}</td>
                <td>{item?.goodsId?.productName}</td>
                <td>{item?.goodsId?.category}</td>
                <td>{item?.amount} ({item?.goodsId?.unit})</td>
              </tr>))}
            </tbody>
          </Table>
          <hr />

          <Row className=' mx-1 mb-3 text-xl text-slate-600'> 4.배송정보</Row>

          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              발송일자
            </Form.Label>
            <Col sm="3">

              <Form.Control
                type="date"
                name="shippingDate"
                value={forms.shippingDate}
                onChange={handleChangeState}
                size="sm"
              />
            </Col>
          </Form.Group>
          {/* 택배 */}
          <Form.Group as={Row} className="mb-3 px-3" style={{ flexWrap: "nowrap" }}>
            <Form.Label column sm="2">
              택배사 박스
            </Form.Label>
            <Col sm="2">
              <InputGroup className="mb-2" size="sm">
                <InputGroup.Text> 이형 </InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="0"
                  name="oddShaped"
                  size="sm"
                  min={0}
                  value={parcel.oddShaped}
                  onChange={handleChangeParcel}
                  style={{ textAlign: "center" }}
                />
              </InputGroup>
            </Col>
            <Col sm="2">
              <InputGroup className="mb-2" size="sm">
                <InputGroup.Text> 대 </InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="0"
                  name="parcelLarge"
                  size="sm"
                  min={0}
                  value={parcel.parcelLarge}
                  onChange={handleChangeParcel}
                  style={{ textAlign: "center" }}
                />
              </InputGroup>
            </Col>
            <Col sm="2">
              <InputGroup className="mb-2" size="sm">
                <InputGroup.Text> 중 </InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="0"
                  name="parcelMedium"
                  size="sm"
                  min={0}
                  value={parcel.parcelMedium}
                  onChange={handleChangeParcel}
                  style={{ textAlign: "center" }}
                />
              </InputGroup>
            </Col>
            <Col sm="2">
              <InputGroup className="mb-2" size="sm">
                <InputGroup.Text> 소 </InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="0"
                  name="parcelSmall"
                  size="sm"
                  min={0}
                  value={parcel.parcelSmall}
                  onChange={handleChangeParcel}
                  style={{ textAlign: "center" }}
                />
              </InputGroup>
            </Col>
            <Col sm="2">
              <InputGroup className="mb-2" size="sm">
                <InputGroup.Text> 극소 </InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="0"
                  name="parcelMini"
                  size="sm"
                  min={0}
                  value={parcel.parcelMini}
                  onChange={handleChangeParcel}
                  style={{ textAlign: "center" }}
                />
              </InputGroup>
            </Col>
            <Col sm="2">
              <Form.Control
                type="text"
                placeholder="0"
                name="parcelTotal"
                size="sm"
                min={0}
                value={formatLargeNumber(parcel.parcelTotal?.toString())}
                onChange={handleChangeParcel}
                style={{ textAlign: "end" }}
              />
            </Col>
          </Form.Group>
          {/* 박스 */}
          <Form.Group as={Row} className="mb-3 px-3" >
            <Form.Label column sm="2">
              DTT 박스
            </Form.Label>
            <Col sm="2" >
              <InputGroup className="mb-2" size="sm" >
                <InputGroup.Text> 대 </InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="0"
                  size="sm"
                  min={0}
                  name="boxLarge"
                  value={shippingBox.boxLarge}
                  onChange={handleChangeBox}
                  style={{ textAlign: "center" }}
                />
              </InputGroup>

            </Col>
            <Col sm="2">
              <InputGroup className="mb-2" size="sm">
                <InputGroup.Text> 중 </InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="0"
                  name="boxMedium"
                  size="sm"
                  min={0}
                  value={shippingBox.boxMedium}
                  onChange={handleChangeBox}
                  style={{ textAlign: "center" }}
                />
              </InputGroup>

            </Col>
            <Col sm="2">

              <InputGroup className="mb-2" size="sm">
                <InputGroup.Text> 소 </InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="0"
                  name="boxSmall"
                  size="sm"
                  min={0}
                  value={shippingBox.boxSmall}
                  onChange={handleChangeBox}
                  style={{ textAlign: "center" }}
                />
              </InputGroup>
            </Col>
            <Col sm="2">

              <Form.Control
                type="text"
                placeholder="0"
                name="boxTotal"
                size="sm"
                min={0}
                value={formatLargeNumber(shippingBox.boxTotal?.toString())}
                onChange={handleChangeBox}
                style={{ textAlign: "end" }}
              />

            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3 d-flex'>
            <Form.Label column sm='2' className='d-flex flex-direction-column'>
              <div>
                <div as={Row}>
                  제주/도서산간
                </div>
                <div as={Row} className='d-flex justify-content-center'>
                  (추가비용)
                </div>
              </div>
            </Form.Label>
            <Col sm='3' className='d-flex align-items-center'>
              <Form.Check
                inline
                name="hasAdditionalCosts"
                // label={"(체크박스)"}
                type="checkbox"
                checked={forms?.hasAdditionalCosts}
                onChange={handleChangeState}
                value={forms?.hasAdditionalCosts || false}
              />
            </Col>
          </Form.Group>


          {/* 퀵서비스 */}
          <Form.Group as={Row} className="mb-3 px-3 align-items-center" >
            <Form.Label column sm="2">
              퀵서비스
            </Form.Label>
            <Col sm="2">
              <Form.Control
                type="text"
                placeholder="트럭"
                name="damas"
                size="sm"
                min={0}
                value={formatLargeNumber(quickService.damas?.toString())}
                onChange={handleChangeQuick}
                style={{ textAlign: "center" }}
              />
            </Col>
            원
            <Col sm="2">

              <Form.Control
                type="text"
                placeholder="오토바이"
                name="motorcycle"
                size="sm"
                min={0}
                value={formatLargeNumber(quickService.motorcycle?.toString())}
                onChange={handleChangeQuick}
                style={{ textAlign: "center" }}
              />

            </Col>
            원
            <Col sm="2">

              <Form.Control
                type="text"
                placeholder="0"
                name="quickTotal"
                size="sm"
                min={0}
                value={formatLargeNumber(quickService.quickTotal?.toString())}
                onChange={handleChangeQuick}
                style={{ textAlign: "end" }}
              />

            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 px-3 align-items-center" style={{ flexWrap: "nowrap" }} >
            <Form.Label column sm="2">
              기타
            </Form.Label>
            <Col sm="3" style={{ flex: "0 0 28%" }} >
              <InputGroup size="sm" >
                {/* <InputGroup.Text> 항목 1 </InputGroup.Text> */}
                <Form.Control
                  type="text"
                  size="sm"
                  min={0}
                  name="reasonFee1"
                  value={extraFee.reasonFee1}
                  onChange={handleChangeExtra}
                  style={{ textAlign: "center" }}
                  placeholder="항목 1"
                />
              </InputGroup>

            </Col>
            <Col sm="2">
              <InputGroup size="sm" >
                <Form.Control
                  type="text"
                  placeholder="0"
                  name="fee1"
                  size="sm"
                  min={0}
                  value={formatLargeNumber(extraFee.fee1?.toString())}
                  onChange={handleChangeExtra}
                  style={{ textAlign: "center" }}
                />
              </InputGroup>
            </Col>
            원
            <Col sm="3" style={{ flex: "0 0 28%" }} className="!ml-3">
              <InputGroup size="sm" >
                {/* <InputGroup.Text> 항목 2 </InputGroup.Text> */}
                <Form.Control
                  type="text"
                  size="sm"
                  min={0}
                  name="reasonFee2"
                  value={extraFee.reasonFee2}
                  onChange={handleChangeExtra}
                  style={{ textAlign: "center" }}
                  placeholder="항목 2"
                />
              </InputGroup>

            </Col>
            <Col sm="2">
              <InputGroup size="sm" >
                <Form.Control
                  type="text"
                  placeholder="0"
                  name="fee2"
                  size="sm"
                  min={0}
                  value={formatLargeNumber(extraFee.fee2?.toString())}
                  onChange={handleChangeExtra}
                  style={{ textAlign: "center" }}
                />
              </InputGroup>
            </Col>
            원
          </Form.Group>

          <Form.Group as={Row} className="mb-3 px-3 align-items-center">
            <Form.Label column sm="2">
              전체금액
            </Form.Label>
            <Col sm="3">
              <Form.Control
                type="text"
                name="totalPrice"
                min={0}
                value={formatLargeNumber(forms.totalPrice?.toString())}
                onChange={handleChangeState}
                readOnly
                size="sm"
                style={{ textAlign: "end" }}
              />
            </Col>
            원
          </Form.Group>
          <hr />
          <Row className="mt-2 mb-5">
            {/* <Col className="text-start ">
              <Button variant="primary" className="px-4 mx-3">
               엑셀다운로드
              </Button>{" "}
              <Button variant="outline-secondary" className="px-4 mx-3">
                삭제
              </Button>{" "}
              
            </Col> */}
            <Col className="text-end ">
              <div style={{ display: "none" }} >
                <PrintReceivingForm ref={componentRef} orderLogs={orderLogs} />
              </div>

              <Button variant="outline-secondary" type="button" className="px-4 mx-3" onClick={() => {
                if (isOrder) {
                  navigate("/disReceving", { replace: true, state: { backPage: true } });
                } else {
                  navigate("/disSendHistory", { replace: true, state: { backPage: true } });
                }
              }}>
                목 록
              </Button>
              <Button className="px-4 " style={{ marginRight: "15px" }} onClick={handlePrint}>인쇄</Button>
              <Button as="input" type="submit" value="저장" />
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};



export default memo(ReceivingForm);
