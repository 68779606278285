import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "@mui/material/Container";
import { memo, useEffect, useState, useRef } from "react";
import { tokens } from "../../theme";
import Table from "react-bootstrap/Table";
import { Typography, useTheme } from "@mui/material";
import { getAllConfigCostsFunc, getAllEventLogsFunc, getAllOtherCostsFunc, getEventBoothPriceFunc, getEventRequestItemFunc, updateEventRequestItemFunc } from "../../api";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PrintEvent from "../../components/PrintEvent";
import { useReactToPrint } from 'react-to-print';
import useEmblaCarousel from 'embla-carousel-react';
import modal from "@easonlin0716/js-modal";
import { AreaCity } from "../../utils/constant";
import { InputGroup } from "react-bootstrap";
import { formatDateToYYYYMMDDHHMMSS, formatLargeNumber } from "../../utils";
import PrintEventStatement from "../../components/PrintEventStatement";

export function calculateTotalCost(otherCost, forms) {
  const costItems = [
    { count: forms?.guestBook, cost: otherCost?.guestbookCost },
    { count: forms?.otherCostNumber1, cost: otherCost?.otherCost1 },
    { count: forms?.otherCostNumber2, cost: otherCost?.otherCost2 },
    { count: forms?.otherCostNumber3, cost: otherCost?.otherCost3 },
    { count: forms?.otherCostNumber4, cost: otherCost?.otherCost4 },
    { count: forms?.otherCostNumber5, cost: otherCost?.otherCost5 }
  ];

  return costItems.reduce((sum, item) => sum + Number(item.count ?? 0) * Number(item.cost ?? 0), 0);
}

const EventFormDetail = () => {
  const { id } = useParams()
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const componentRef = useRef();
  const componentRefStatement = useRef();
  const navigate = useNavigate();
  const [data, setData] = useState({})
  const [state, setState] = useState("")
  const [oldState, setOldState] = useState("")
  const [preview, setPreview] = useState("")
  const [imgSrc, setImgSrc] = useState("");
  const [installPreview, setInstallPreview] = useState("");
  const [booths, setBooths] = useState([]);
  const fileInput = useRef(null);
  const fileInput2 = useRef(null);
  const [imgSrc2, setImgSrc2] = useState("");
  const [installPreview2, setInstallPreview2] = useState("");
  const location = useLocation();
  const isFromEvent = location?.state && location?.state?.isEvent
  const [isImage, setIsImage] = useState(false);
  const [isImageAttachFile, setIsImageAttachFile] = useState(false);
  const [attachFile, setAttachFile] = useState("")
  const [fileName, setFileName] = useState("");
  const [shippingBox, setShippingBox] = useState({ boxLarge: "", boxMedium: "", boxSmall: "", boxTotal: "" })
  const [parcel, setParcel] = useState({ oddShaped: "", parcelLarge: "", parcelMedium: "", parcelSmall: "", parcelMini: "", parcelTotal: "" })
  const [boxPrices, setBoxPrices] = useState("")
  const [parcelPrices, setParcelPrices] = useState("");
  const [quickService, setQuickService] = useState({ damas: "", motorcycle: "", quickTotal: "" })
  const [extraFee, setExtraFee] = useState({ reasonFee1: "", fee1: "", reasonFee2: "", fee2: "" })
  const [otherCost, setOtherCost] = useState({});
  const [otherCostNumber, setOtherCostNumber] = useState({ guestbookNumber: "", otherCostNumber1: "", otherCostNumber2: "", otherCostNumber3: "", otherCostNumber4: "", otherCostNumber5: "" })

  const [boothPrices, setBoothPrices] = useState([]) //
  const [totalBoothPrices, setTotalBoothPrices] = useState([])
  const [eventLogs, setEventLogs] = useState([])

  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "start",
    containScroll: 'trimSnaps',
  })


  const [forms, setForms] = useState({
    eventCode: "",
    productName: "",
    eventName: "",
    eventCity: "",
    disassembly: "",
    eventStart: "",
    eventEnd: "",
    eventTime: "",
    eventImage: "",
    guestBook: "",
    installer: "",
    manager: "",
    totalPrice: 0,
    pickupDay: "",
    pickupTime: "",
    eventAreaId: ","
  });
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrintStatement = useReactToPrint({
    content: () => componentRefStatement.current,
  });

  const handleChangeImage = (e) => {
    if (!e.target.files) {
      return;
    }

    const selectedFile = e.target.files[0];
    const maxSize = 5 * 1024 * 1024; // 5 MB in bytes

    if (selectedFile?.size > maxSize) {
      toast.error('5MB 이하의 파일을 업로드해주세요.');
      setImgSrc(null);
      fileInput.current.value = "";
      return;
    }

    setImgSrc(e.target.files[0]);
  };

  const handleChangeImageTwo = (e) => {
    if (!e.target.files) {
      return;
    }

    const selectedFile = e.target.files[0];
    const maxSize = 5 * 1024 * 1024; // 5 MB in bytes

    if (selectedFile?.size > maxSize) {
      toast.error('5MB 이하의 파일을 업로드해주세요.');
      setImgSrc2(null);
      fileInput2.current.value = "";
      return;
    }

    setImgSrc2(e.target.files[0]);
  };

  // 이미지 뷰어
  useEffect(() => {
    if (imgSrc) {
      setInstallPreview(URL.createObjectURL(imgSrc));
    }
  }, [imgSrc]);

  useEffect(() => {
    if (imgSrc2) {
      setInstallPreview2(URL.createObjectURL(imgSrc2));
    }
  }, [imgSrc2]);


  const handleChangeState = (e) => {
    const { name, type, checked, value } = e.target;

    setForms({
      ...forms,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  console.log('other cost 1', otherCost);

  useEffect(() => {
    // 전체 물품 리스트
    const EventRequestItemGet = async (id) => {
      const response = await getEventRequestItemFunc(id);
      if (response.status === 200) {
        console.log("response.data?.eventEnd", response.data?.eventEnd)
        allConfigCosts(response?.data?.clientId);
        getAllOtherCosts(response?.data?.clientId);
        setData(response.data)

        setForms({
          ...forms, eventCode: response.data.eventCode || "",
          productName: response.data.productId?.productName,
          eventName: response.data?.eventName,
          eventLocation: response.data?.eventLocation,
          eventCity: response.data?.eventCity,
          disassembly: response.data?.disassembly,
          eventStart: response.data?.eventStart ? response.data?.eventStart.split("T")[0] : "",
          eventEnd: response.data?.eventEnd ? response.data?.eventEnd.split("T")[0] : "",
          eventTime: response.data?.eventTime,
          eventImage: "",
          guestBook: response.data?.guestBook,
          installer: response.data?.installer || "",
          manager: response.data?.manager || "",
          totalPrice: response.data?.totalPrice || 0,
          pickupDay: response.data?.pickupDay ? response.data?.pickupDay.split("T")[0] : "",
          pickupTime: response.data?.pickupTime,
          directions: response.data?.directions,
          memo: result?.memo,
          InstallationPhoto: response.data?.InstallationPhoto,
          InstallationPhoto2: response.data?.InstallationPhoto2,
          attachFile: "",
          eventAreaId: response.data?.eventAreaId,
          shippingDate: response?.data?.shippingDate,
          totalPriceShipping: response?.data?.totalPriceShipping,
          hasAdditionalCosts: response?.data?.hasAdditionalCosts,
          otherCostNumber1: response?.data?.otherCostNumber1,
          otherCostNumber2: response?.data?.otherCostNumber2,
          otherCostNumber3: response?.data?.otherCostNumber3,
          otherCostNumber4: response?.data?.otherCostNumber4,
          otherCostNumber5: response?.data?.otherCostNumber5,
        })
        setBooths(response.data?.booths)
        setPreview(response.data?.eventImage)
        setAttachFile(response.data?.attachFile)
        setState(response.data?.eventState)
        setOldState(response.data?.eventState)
        setInstallPreview(response.data?.InstallationPhoto)
        setInstallPreview2(response.data?.InstallationPhoto2);

        response.data?.parcel && setParcel(JSON.parse(response.data?.parcel))
        response.data?.shippingBox && setShippingBox(JSON.parse(response.data?.shippingBox))
        response.data?.quickService && setQuickService(JSON.parse(response.data?.quickService))
        response?.data?.extraFee && setExtraFee(JSON.parse(response?.data?.extraFee))

        const attachFile = response?.data?.attachFile
        let attachFileName = "";
        if (attachFile) {
          attachFileName = decodeURI(
            attachFile.split("/")[attachFile?.split("/").length - 1]
          );
          attachFileName = attachFileName.split("-").pop();
          setFileName(attachFileName);
        }

      } else {
        toast.error("행사요청을 가져오는데 실패했습니다.");
      }
    };
    const result = EventRequestItemGet(id)

  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    formData.append("shippingBox", JSON.stringify(shippingBox));
    formData.append("quickService", JSON.stringify(quickService));
    formData.append("parcel", JSON.stringify(parcel));
    formData.append("extraFee", JSON.stringify(extraFee));

    formData.delete('boxLarge')
    formData.delete('boxMedium')
    formData.delete('boxSmall')
    formData.delete('parcelLarge')
    formData.delete('parcelMedium')
    formData.delete('parcelSmall')
    formData.delete('parcelMini')
    formData.delete('damas')
    formData.delete('motorcycle')
    formData.delete('boxTotal')
    formData.delete('quickTotal')
    formData.delete('parcelTotal')
    formData.delete('reasonFee1')
    formData.delete('reasonFee2')
    formData.delete('fee1')
    formData.delete('fee2')

    if (!forms.hasAdditionalCosts) {
      formData.append("hasAdditionalCosts", false);
    }


    if (forms.eventAreaId || forms.eventAreaId !== "") {
      const area = AreaCity.find((item) => item.id === forms.eventAreaId)
      formData.append('eventCity', area?.name);
    }

    const config = {
      "Content-Type": "multipart/form-data",
    };

    const response = await updateEventRequestItemFunc(id, formData, config);
    if (response.status === 200) {
      toast.success("Successfully Register!");
      if (isFromEvent) {
        setTimeout(() => {
          navigate("/eventform", { replace: true, state: { backPage: true } });
        }, 1000);
      } else {
        setTimeout(() => {
          navigate("/eventReport", { replace: true, state: { backPage: true } });
        }, 1000);
      }
    } else {
      toast.error("행사요청 업데이트에 실패했습니다.!");
    }
  };

  useEffect(() => {
    if (attachFile) {
      checkFileType(attachFile, 'attachFile');
    }
  }, [attachFile]);

  useEffect(() => {
    if (preview) {
      checkFileType(preview, 'eventImage');
    }
  }, [preview]);

  const checkFileType = (url, type) => {
    const extension = url.split('.').pop().toLowerCase();
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
    if (type === "attachFile") {
      setIsImageAttachFile(imageExtensions.includes(extension))
    }
    if (type === "eventImage") {
      setIsImage(imageExtensions.includes(extension));
    }
  };

  const openImg = (idx) => {
    const modalE = '#img-modal-booth-' + idx;
    const myModal = document.querySelector(modalE);
    modal.open(myModal, { allowDrag: true, showClose: true, clickClose: true })
  }

  const getAllOtherCosts = async (id) => {
    const response = await getAllOtherCostsFunc(id);

    if (response.status === 200) {
      const filteredPrices = { ...response?.data?.data };

      // Xóa các trường không cần thiết
      delete filteredPrices._id;
      delete filteredPrices.clientId;
      delete filteredPrices.__v;
      delete filteredPrices.createdAt;
      delete filteredPrices.updatedAt;

      setOtherCost(filteredPrices);
    } else {
      toast.error("부스종류를 가져오는데 실패했습니다.");
    }
  };

  const allConfigCosts = async (id) => {
    const response = await getAllConfigCostsFunc(id);

    if (response.status === 200) {
      const filteredPrices = { ...response?.data?.data };

      const newBoxPrices = {
        boxLarge: Number(filteredPrices.boxLarge) || 0,
        boxMedium: Number(filteredPrices.boxMedium) || 0,
        boxSmall: Number(filteredPrices.boxSmall) || 0,
      };

      const newParcelPrices = {
        oddShaped: Number(filteredPrices.parcelOddShaped) || 0,
        parcelLarge: Number(filteredPrices.parcelLarge) || 0,
        parcelMedium: Number(filteredPrices.parcelMedium) || 0,
        parcelSmall: Number(filteredPrices.parcelSmall) || 0,
        parcelMini: Number(filteredPrices.parcelMini) || 0,
        additionalCosts: Number(filteredPrices.additionalCosts) || 0,
      };

      // Set state
      setBoxPrices(newBoxPrices);
      setParcelPrices(newParcelPrices);
    } else {
      toast.error("부스종류를 가져오는데 실패했습니다.");
    }
  };

  const handleChangeParcel = (e) => {
    const { name, value } = e.target;
    // let rawValue = value.replace(/,/g, "");

    const updatedParcel = {
      ...parcel,
      [name]: value
    };

    updatedParcel.parcelTotal = Object.keys(parcelPrices).reduce((total, size) => {
      if (forms.hasAdditionalCosts) {
        return total + (parcelPrices[size] * (updatedParcel[size] || 0) + (updatedParcel[size] || 0) * parcelPrices["additionalCosts"]);
      }
      return total + (parcelPrices[size] * (updatedParcel[size] || 0));
    }, 0);

    setParcel(updatedParcel);
  };

  useEffect(() => {
    // 전체 합산 금액 계산
    if (forms.hasAdditionalCosts) {
      const updatedParcel = {
        ...parcel,
      };

      const parcelTypes = ["oddShaped", "parcelLarge", "parcelMedium", "parcelMini", "parcelSmall"];

      const totalParcels = parcelTypes.reduce((sum, key) => sum + Number(parcel[key] || 0), 0);

      updatedParcel.parcelTotal += totalParcels * (parcelPrices?.additionalCosts || 0);

      setParcel(updatedParcel);
    } else {
      const updatedParcel = {
        ...parcel,
      };

      const parcelTypes = ["oddShaped", "parcelLarge", "parcelMedium", "parcelMini", "parcelSmall"];

      const totalParcels = parcelTypes.reduce((sum, key) => sum + Number(parcel[key] || 0), 0);

      updatedParcel.parcelTotal -= totalParcels * (parcelPrices?.additionalCosts || 0);

      setParcel(updatedParcel);
    }

  }, [forms.hasAdditionalCosts]);

  const handleChangeBox = (e) => {
    const { name, value } = e.target;

    const updatedBox = {
      ...shippingBox,
      [name]: value
    };
    // 총액 계산
    updatedBox.boxTotal = Object.keys(boxPrices).reduce((total, box) => {
      return total + (boxPrices[box] * (updatedBox[box] || 0));
    }, 0);

    setShippingBox(updatedBox);
  };

  const handleChangeQuick = (e) => {
    // console.log(`${e.target.name}:${e.target.value}`);
    const { name, value } = e.target;
    let rawValue = value.replace(/,/g, "");

    const updatedQuick = {
      ...quickService,
      [name]: rawValue
    };
    // 입력된 금액들을 숫자로 변환하여 합산
    updatedQuick.quickTotal = Object.values(updatedQuick)
      .filter(v => v !== updatedQuick.quickTotal)
      .reduce((total, currentValue) => total + (Number(currentValue) || 0), 0);

    setQuickService(updatedQuick);
  };

  useEffect(() => {
    // 전체 합산 금액 계산
    const total = Number(quickService.quickTotal) + Number(parcel.parcelTotal) + Number(shippingBox.boxTotal) + Number(extraFee.fee1) + Number(extraFee.fee2);

    setForms({ ...forms, totalPriceShipping: total });

  }, [quickService.quickTotal, parcel.parcelTotal, shippingBox.boxTotal, forms.hasAdditionalCosts, extraFee.fee1, extraFee.fee2]); // 의존성 배열

  const handleChangeExtra = (e) => {
    // console.log(`${e.target.name}:${e.target.value}`);
    const { name, value } = e.target;
    let rawValue = value.replace(/,/g, "");

    const updatedExtraFee = {
      ...extraFee,
      [name]: rawValue
    };

    setExtraFee(updatedExtraFee);
  };

  useEffect(() => {
    // 전체 물품 리스트
    const EventRequestItemGet = async (id) => {
      const response = await getEventBoothPriceFunc(id);
      if (response.status === 200) {

        console.log('esponse.data.data', response.data.data);

        const totalPrice = response.data.data?.reduce((sum, item) => sum + (item?.price || 0), 0);

        setBoothPrices(response.data.data)
        setTotalBoothPrices(totalPrice)
      } else {
        toast.error("행사요청을 가져오는데 실패했습니다.");
      }
    };
    EventRequestItemGet(id)
  }, [id]);

  useEffect(() => {
    // 전체 물품 리스트
    const fetchEventLogs = async (id) => {
      const response = await getAllEventLogsFunc(id);

      if (response.status === 200) {

        setEventLogs(response.data?.data)
      } else {
        toast.error("행사요청을 가져오는데 실패했습니다.");
      }
    };

    fetchEventLogs(id)
  }, [id]);

  return (
    <>
      <Container maxWidth="md" className="py-3">
        <Row className="fs-1 fw-bold p-2 border-bottom mb-5">
          행사 접수 신청서
        </Row>
        <Form onSubmit={handleSubmit}>
          <Typography
            variant="h4"
            color={colors.grey[400]}
            sx={{ m: "15px 0 15px 0" }}
          >
            1.부스 및 행사정보
          </Typography>

          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              코드
            </Form.Label>
            <Col sm="3">
              <Form.Control
                name="eventCode"
                value={forms.eventCode}
                onChange={handleChangeState}
                type="text"
                size="sm"
                required
                disabled
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              품목
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="9" style={{ fontSize: 16 }}>
                {forms.productName}
              </Form.Label>
              {/* <Form.Control type="text" name="companyCode" value={state.companyCode} onChange={handleChangeState} size="sm" required /> */}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              행사명칭
            </Form.Label>

            <Col sm="3">
              <Form.Control
                type="text"
                name="eventName"
                onChange={handleChangeState}
                value={forms.eventName}
                size="sm"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              부스
            </Form.Label>
            <Col sm="9">
              {
                !!booths?.length && booths?.map((item, idx) => (
                  <Form.Label key={idx} column sm="9" style={{ fontSize: 16 }}>
                    {`${item?.boothId?.boothName} ${item?.count}개 / ${item.acronyms}/${item.boothTypeName}`}
                  </Form.Label>
                ))
              }
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">

            </Form.Label>
            <Col sm="9">
              <div className='ra_box ra_box02 in1 name_form box-slide-img' ref={emblaRef} style={{ overflow: "hidden" }}>
                <div className='raInner continerImage' style={{ gap: "8px" }}>
                  {!!booths?.length &&
                    booths.map((item, idx) => (
                      <>
                        <div key={idx} className='contentImg'>
                          <label className='' htmlFor={`ra0${idx}`} style={{ height: '200px', minWidth: '100%' }}>
                            <p className='imageP'>
                              <img
                                style={{ height: '200px', cursor: 'pointer' }}
                                src={item?.boothId?.boothImage?.[0]}
                                alt=''
                                onClick={() => openImg(item?._id)}
                              />
                              {/* <b>{`${item?.boothId?.boothName} ${item?.count}개 / ${item.acronyms}/${item.boothTypeName}`}</b> */}
                            </p>
                            <span />
                          </label>
                          <div id={`img-modal-booth-${item?._id}`} className="modal" style={{ height: "fit-content" }}>
                            <img
                              width={'100%'}
                              src={item?.boothId?.boothImage[0]}
                              alt='it_img'
                            ></img>
                          </div>
                        </div>
                      </>
                    ))}
                </div>
              </div>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              행사지역
            </Form.Label>
            <Col xs={3}>
              <Form.Select
                aria-label='Default select example'
                size='sm'
                name='eventAreaId'
                onChange={handleChangeState}
                value={forms?.eventAreaId}
              >
                {/* <option >행사지역</option> */}
                {AreaCity &&
                  AreaCity?.map((it) => (
                    <option key={it.id} value={it.id}>
                      {it.name}
                    </option>
                  ))}
              </Form.Select>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              행사장소
            </Form.Label>
            <Col sm="3">
              <Form.Control
                type="text"
                name="eventLocation"
                onChange={handleChangeState}
                value={forms?.eventLocation}
                size="sm"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              설치/해제시간
            </Form.Label>
            <Col sm="4">
              <Form.Control
                type="text"
                name="disassembly"
                onChange={handleChangeState}
                value={forms.disassembly}
                size="sm"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3 flex items-center">
            <Form.Label column sm="2">
              행사일자
            </Form.Label>
            <Col sm="3">
              <Form.Control
                type="date"
                onChange={handleChangeState}
                name='eventStart'
                value={forms.eventStart}
                size="sm"
              />
            </Col>
            ~
            <Col sm="3">
              <Form.Control
                type="date"
                name="eventEnd"
                onChange={handleChangeState}
                value={forms.eventEnd}
                size="sm"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              행사시간
            </Form.Label>
            <Col sm="3">
              <Form.Control
                type="text"
                name="eventTime"
                onChange={handleChangeState}
                value={forms.eventTime}
                size="sm"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              부스배치도
            </Form.Label>
            <Col sm="6">
              <Form.Group controlId="formFile" >
                {preview ? (
                  <div className="flex items-center">
                    {isImage ? <img src={preview} width={"300px"} alt="" /> :
                      <a href={preview} download target='_blank' style={{ textDecoration: "none" }}>
                        {forms.eventName} 배치도
                      </a>}
                    <span onClick={() => setPreview("")} className="cursor-pointer ml-3 inline-flex items-center rounded-md bg-red-50 px-4 py-2 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">삭제</span>
                  </div>) : (
                  <Form.Control type="file" size="sm" name="eventImage" />)}
              </Form.Group>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              첨부파일
            </Form.Label>
            <Col sm="6">
              <Form.Group controlId="formFile" >
                {attachFile ? (
                  <div className="flex items-center">
                    {isImageAttachFile ? <img src={attachFile} width={"300px"} alt="" /> :
                      <a href={attachFile} download target='_blank' style={{ textDecoration: "none" }}>
                        {fileName}
                      </a>}
                    <span onClick={() => setAttachFile("")} className="cursor-pointer ml-3 inline-flex items-center rounded-md bg-red-50 px-4 py-2 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">삭제</span>
                  </div>) : (
                  <Form.Control type="file" size="sm" name="attachFile" />)}
              </Form.Group>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              상태
            </Form.Label>
            <Col sm="9">

              {["신규", "접수", "수정", "완료", "취소", "취소완료"].map((item, idx) => (
                <Form.Check
                  key={`${item}-${idx}`}
                  inline
                  label={item}
                  value={item}
                  onChange={(e) => setState(e.target.value)}
                  name="eventState"
                  type="radio"
                  checked={state == item}
                  disabled={["취소", "취소완료"].includes(oldState) && !["취소", "취소완료"].includes(item)}
                />
              ))}

            </Col>
          </Form.Group>

          {!!eventLogs?.length &&
            <Form.Group as={Row} className="mb-3 px-3">
              <Form.Label column sm="2">
                상태로그
              </Form.Label>
              <Col sm="12">
                <Table striped bordered hover >
                  <thead>
                    <tr>
                      <th className="text-center">번호</th>
                      <th className="text-center">일자</th>
                      <th className="text-center">상태</th>
                    </tr>
                  </thead>
                  <tbody>
                    {eventLogs?.map((item, idx) => (<tr key={item._id} className="text-center">
                      <td>{(eventLogs?.length - idx)}</td>
                      <td>{formatDateToYYYYMMDDHHMMSS(item?.createdAt)}</td>
                      <td>{item?.status}</td>
                    </tr>))}
                  </tbody>
                </Table>
              </Col>
            </Form.Group>}
          <hr />
          <Typography
            variant="h4"
            color={colors.grey[400]}
            sx={{ m: "15px 0 15px 0" }}
          >
            2.추가신청정보
          </Typography>
          <Form.Group as={Row} className="mb-3 px-3 flex items-center">
            <Form.Label column sm="2">
              방명록
            </Form.Label>

            <Col sm="2">
              <Form.Control
                type="text"
                className="text-center"
                name="guestBook"
                value={forms.guestBook}
                onChange={handleChangeState}
                size="sm"
              />
            </Col>
            <Col>개</Col>
          </Form.Group>
          {/* 전화번호 */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              전화번호
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="3">
                {data ? data?.staff?.phone : ""}
              </Form.Label>
            </Col>
          </Form.Group>
          {/* 배송방식 */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              특이사항
            </Form.Label>
            <Col sm="10">
              <Form.Label column sm="3" style={{ width: "100%", display: "block", wordBreak: "break-all", whiteSpace: "break-spaces" }}>
                {data ? data.memo : "특이사항 없음"}
              </Form.Label>
            </Col>
          </Form.Group>
          {/* 주소코드 */}
          {/*<Form.Group as={Row} className="mb-3 px-3">*/}
          {/*  <Form.Label column sm="2">*/}
          {/*    물품 픽업 일시*/}
          {/*  </Form.Label>*/}
          {/*  <Col sm="3">*/}
          {/*    <Form.Control*/}
          {/*      type="date"*/}
          {/*      name="pickupDay"*/}
          {/*      onChange={handleChangeState}*/}
          {/*      value={forms.pickupDay}*/}
          {/*      size="sm"*/}
          {/*    />*/}
          {/*  </Col>*/}
          {/*  <Col sm="3">*/}
          {/*    <Form.Control*/}
          {/*      type="text"*/}
          {/*      name="pickupTime"*/}
          {/*      onChange={handleChangeState}*/}
          {/*      value={forms.pickupTime}*/}
          {/*      size="sm"*/}
          {/*    />*/}
          {/*  </Col>*/}
          {/*</Form.Group>*/}


          {/* 특이사항 */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              지시사항
            </Form.Label>
            <Col sm="10">
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Control as="textarea" name="directions" value={forms.directions} onChange={handleChangeState} rows={3} />
              </Form.Group>

            </Col>
          </Form.Group>
          <hr />
          <Typography
            variant="h4"
            color={colors.grey[400]}
            sx={{ m: "20px 0 15px 0" }}
          >
            3.행사신청품목
          </Typography>

          <Table striped bordered hover >
            <thead>
              <tr>
                <th className="text-center">번호</th>
                <th className="text-center">물품명</th>
                <th className="text-center">품목</th>
                <th className="text-center">구분</th>
                <th className="text-center">주문수량</th>
              </tr>
            </thead>
            <tbody>
              {data && data?.goods?.map((item, idx) => {
                return (item?.amount > 0 && <tr key={item._id} className="text-center">
                  <td className="text-center align-middle">{data?.goods?.length - idx}</td>
                  <td>{item?.goodsId?.goodsName}</td>
                  <td>{item?.goodsId?.productName}</td>
                  <td>{item?.goodsId?.category}</td>
                  <td>{item?.amount} ({item?.goodsId?.unit})</td>
                </tr>)
              })}

            </tbody>
          </Table>
          <hr />

          <Row className=' mx-1 mb-3 text-xl text-slate-600'> 4.배송정보</Row>

          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              발송일자
            </Form.Label>
            <Col sm="3">

              <Form.Control
                type="date"
                name="shippingDate"
                value={forms.shippingDate}
                onChange={handleChangeState}
                size="sm"
              />
            </Col>
          </Form.Group>

          {/* 택배 */}
          <Form.Group as={Row} className="mb-3 px-3" style={{ flexWrap: "nowrap" }}>
            <Form.Label column sm="2">
              택배사 박스
            </Form.Label>
            <Col sm="2">
              <InputGroup size="sm">
                <InputGroup.Text> 이형 </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="0"
                  name="oddShaped"
                  size="sm"
                  min={0}
                  value={parcel.oddShaped}
                  onChange={handleChangeParcel}
                  style={{ textAlign: "center" }}
                />
              </InputGroup>
            </Col>
            <Col sm="2">
              <InputGroup size="sm">
                <InputGroup.Text> 대 </InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="0"
                  name="parcelLarge"
                  size="sm"
                  min={0}
                  value={parcel.parcelLarge}
                  onChange={handleChangeParcel}
                  style={{ textAlign: "center" }}
                />
              </InputGroup>
            </Col>
            <Col sm="2">
              <InputGroup size="sm">
                <InputGroup.Text> 중 </InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="0"
                  name="parcelMedium"
                  size="sm"
                  min={0}
                  value={parcel.parcelMedium}
                  onChange={handleChangeParcel}
                  style={{ textAlign: "center" }}
                />
              </InputGroup>
            </Col>
            <Col sm="2">
              <InputGroup size="sm">
                <InputGroup.Text> 소 </InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="0"
                  name="parcelSmall"
                  size="sm"
                  min={0}
                  value={parcel.parcelSmall}
                  onChange={handleChangeParcel}
                  style={{ textAlign: "center" }}
                />
              </InputGroup>
            </Col>
            <Col sm="2">
              <InputGroup size="sm">
                <InputGroup.Text> 극소 </InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="0"
                  name="parcelMini"
                  size="sm"
                  min={0}
                  value={parcel.parcelMini}
                  onChange={handleChangeParcel}
                  style={{ textAlign: "center" }}
                />
              </InputGroup>
            </Col>
            <Col sm="2">
              <Form.Control
                type="text"
                placeholder="0"
                name="parcelTotal"
                size="sm"
                min={0}
                value={formatLargeNumber(parcel.parcelTotal?.toString())}
                onChange={handleChangeParcel}
                style={{ textAlign: "end" }}
              />
            </Col>
          </Form.Group>

          {/* 박스 */}
          <Form.Group as={Row} className="mb-3 px-3" >
            <Form.Label column sm="2">
              DTT 박스
            </Form.Label>
            <Col sm="2" >
              <InputGroup size="sm" >
                <InputGroup.Text> 대 </InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="0"
                  size="sm"
                  min={0}
                  name="boxLarge"
                  value={shippingBox.boxLarge}
                  onChange={handleChangeBox}
                  style={{ textAlign: "center" }}
                />
              </InputGroup>

            </Col>
            <Col sm="2">
              <InputGroup size="sm">
                <InputGroup.Text> 중 </InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="0"
                  name="boxMedium"
                  size="sm"
                  min={0}
                  value={shippingBox.boxMedium}
                  onChange={handleChangeBox}
                  style={{ textAlign: "center" }}
                />
              </InputGroup>

            </Col>
            <Col sm="2">

              <InputGroup size="sm">
                <InputGroup.Text> 소 </InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="0"
                  name="boxSmall"
                  size="sm"
                  min={0}
                  value={shippingBox.boxSmall}
                  onChange={handleChangeBox}
                  style={{ textAlign: "center" }}
                />
              </InputGroup>
            </Col>
            <Col sm="2">

              <Form.Control
                type="text"
                placeholder="0"
                name="boxTotal"
                size="sm"
                min={0}
                value={formatLargeNumber(shippingBox.boxTotal?.toString())}
                onChange={handleChangeBox}
                style={{ textAlign: "end" }}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3 d-flex'>
            <Form.Label column sm='2' className='d-flex flex-direction-column'>
              <div>
                <div as={Row}>
                  제주/도서산간
                </div>
                <div as={Row} className='d-flex justify-content-center'>
                  (추가비용)
                </div>
              </div>
            </Form.Label>
            <Col sm='3' className='d-flex align-items-center'>
              <Form.Check
                inline
                name="hasAdditionalCosts"
                // label={"(체크박스)"}
                type="checkbox"
                checked={forms?.hasAdditionalCosts}
                onChange={handleChangeState}
                value={forms?.hasAdditionalCosts || false}
              />
            </Col>
          </Form.Group>

          {/* 퀵서비스 */}
          <Form.Group as={Row} className="mb-3 px-3 align-items-center" >
            <Form.Label column sm="2">
              퀵서비스
            </Form.Label>
            <Col sm="2">
              <Form.Control
                type="text"
                placeholder="트럭"
                name="damas"
                size="sm"
                min={0}
                value={formatLargeNumber(quickService.damas?.toString())}
                onChange={handleChangeQuick}
                style={{ textAlign: "center" }}
              />
            </Col>
            원
            <Col sm="2">

              <Form.Control
                type="text"
                placeholder="오토바이"
                name="motorcycle"
                size="sm"
                min={0}
                value={formatLargeNumber(quickService.motorcycle?.toString())}
                onChange={handleChangeQuick}
                style={{ textAlign: "center" }}
              />

            </Col>
            원
            <Col sm="2">

              <Form.Control
                type="text"
                placeholder="0"
                name="quickTotal"
                size="sm"
                min={0}
                value={formatLargeNumber(quickService.quickTotal?.toString())}
                onChange={handleChangeQuick}
                style={{ textAlign: "end" }}
              />

            </Col>
          </Form.Group>

          {/* 퀵서비스 */}
          <Form.Group as={Row} className="mb-3 px-3 align-items-center" style={{ flexWrap: "nowrap" }} >
            <Form.Label column sm="2">
              기타
            </Form.Label>
            <Col sm="3" style={{ flex: "0 0 28%" }} >
              <InputGroup size="sm" >
                {/* <InputGroup.Text> 항목 1 </InputGroup.Text> */}
                <Form.Control
                  type="text"
                  size="sm"
                  min={0}
                  name="reasonFee1"
                  value={extraFee.reasonFee1}
                  onChange={handleChangeExtra}
                  style={{ textAlign: "center" }}
                  placeholder="항목 1"
                />
              </InputGroup>

            </Col>
            <Col sm="2">
              <InputGroup size="sm" >
                <Form.Control
                  type="text"
                  placeholder="0"
                  name="fee1"
                  size="sm"
                  min={0}
                  value={formatLargeNumber(extraFee.fee1?.toString())}
                  onChange={handleChangeExtra}
                  style={{ textAlign: "center" }}
                />
              </InputGroup>
            </Col>
            원
            <Col sm="3" style={{ flex: "0 0 28%" }} className="!ml-3">
              <InputGroup size="sm" >
                {/* <InputGroup.Text> 항목 2 </InputGroup.Text> */}
                <Form.Control
                  type="text"
                  size="sm"
                  min={0}
                  name="reasonFee2"
                  value={extraFee.reasonFee2}
                  onChange={handleChangeExtra}
                  style={{ textAlign: "center" }}
                  placeholder="항목 2"
                />
              </InputGroup>

            </Col>
            <Col sm="2">
              <InputGroup size="sm" >
                <Form.Control
                  type="text"
                  placeholder="0"
                  name="fee2"
                  size="sm"
                  min={0}
                  value={formatLargeNumber(extraFee.fee2?.toString())}
                  onChange={handleChangeExtra}
                  style={{ textAlign: "center" }}
                />
              </InputGroup>
            </Col>
            원
          </Form.Group>

          <Form.Group as={Row} className="mb-3 px-3 align-items-center">
            <Form.Label column sm="2">
              전체금액
            </Form.Label>
            <Col sm="3">
              <Form.Control
                type="text"
                name="totalPriceShipping"
                min={0}
                value={formatLargeNumber(forms.totalPriceShipping?.toString())}
                onChange={handleChangeState}
                readOnly
                size="sm"
                style={{ textAlign: "end" }}
              />
            </Col>
            원
          </Form.Group>

          {/* 주문자정보 */}
          <hr />
          <Typography
            variant="h4"
            color={colors.grey[400]}
            sx={{ m: "15px 0 15px 0" }}
          >
            5.주문자정보
          </Typography>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              거래처명
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="9" style={{ fontSize: 16 }}>
                {data ? data?.staff?.client?.companyName : ""}
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              품목
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="9" style={{ fontSize: 16 }}>
                {forms?.productName}
              </Form.Label>
              {/* <Form.Control type="text" name="companyCode" value={state.companyCode} onChange={handleChangeState} size="sm" required /> */}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              주문부서
            </Form.Label>

            <Col sm="9">
              <Form.Label column sm="9" style={{ fontSize: 16 }}>
                {data ? data?.staff?.department : ""}
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              신청인
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="9" style={{ fontSize: 16 }}>
                {data ? data?.staff?.staffName : ""}
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              전화번호
            </Form.Label>
            <Col sm="3">
              <Form.Label column sm="9" style={{ fontSize: 16 }}>
                {data ? data?.staff?.phone : ""}
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              특이사항
            </Form.Label>
            <Col sm="10">
              <Form.Label column sm="10" style={{ fontSize: 16, width: "100%", display: "block", wordBreak: "break-all", whiteSpace: "break-spaces" }}>
                {data ? data?.memo : ""}
              </Form.Label>
            </Col>
          </Form.Group>

          <hr />
          <Typography
            variant="h4"
            color={colors.grey[400]}
            sx={{ m: "15px 0 15px 0" }}
          >
            6.관리자정보
          </Typography>
          <Row>
            <Form.Label column sm='2'>
              {""}
            </Form.Label>
            <Col lg='6'>
              <small className='mt-3 px-2 d-block text-danger mb-1 text-start'>
                * 5MB를 초과하는 파일은 등록할 수 없습니다.
              </small>
            </Col>
          </Row>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2" className="pt-0">
              행사설치사진 1
            </Form.Label>
            <Col sm="9" >
              {/* 이미지 프리뷰 */}
              {installPreview && (
                <Form.Group as={Row} className='mb-3 px-0'>
                  <img
                    src={installPreview}
                    alt='img preview'
                    style={{ maxWidth: "100%", height: "auto", width: "100%" }}
                  />
                </Form.Group>
              )}
              <Form.Group as={Row} className='px-0'>
                <Col sm="4">
                  <Form.Control ref={fileInput} type="file" size="sm" name="InstallationPhoto" onChange={handleChangeImage} />
                </Col>
              </Form.Group>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2" className="pt-0">
              행사설치사진 2
            </Form.Label>
            <Col sm="9" >
              {/* 이미지 프리뷰 */}
              {installPreview2 && (
                <Form.Group as={Row} className='mb-3 px-0'>
                  <img
                    src={installPreview2}
                    alt='img preview'
                    style={{ maxWidth: "100%", height: "auto", width: "100%" }}
                  />
                </Form.Group>
              )}
              <Form.Group as={Row} className='px-0'>
                <Col sm="4">
                  <Form.Control ref={fileInput2} type="file" size="sm" name="InstallationPhoto2" onChange={handleChangeImageTwo} />
                </Col>
              </Form.Group>
            </Col>
          </Form.Group>

          {/* 박스 */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              설치자
            </Form.Label>
            <Col sm="3">
              <Form.Control
                type="text"
                name="installer"
                // placeholder="대"
                value={forms.installer || ""}
                onChange={handleChangeState}
                size="sm"
              />

            </Col>
          </Form.Group>
          {/* 택배 */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              관리자
            </Form.Label>
            <Col sm="3">
              <Form.Control
                type="text"
                name="manager"
                // placeholder="대"
                value={forms.manager || ""}
                onChange={handleChangeState}
                size="sm"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 px-3" style={{ display: "none" }}>
            <Form.Label column sm="2">
              총금액
            </Form.Label>
            <Col sm="3">
              <Form.Control
                style={{ display: "none" }}
                type="number"
                name="totalPrice"
                // placeholder="대"
                value={(calculateTotalCost(otherCost, forms) +
                  parcel.parcelTotal +
                  shippingBox.boxTotal +
                  quickService?.quickTotal +
                  Number(extraFee.fee1) +
                  Number(extraFee.fee2) +
                  totalBoothPrices)}
                size="sm"
              />
            </Col>
          </Form.Group>

          <hr />
          <Typography
            variant="h4"
            color={colors.grey[400]}
            sx={{ m: "20px 0 15px 0" }}
          >
            7.견적 정보
          </Typography>

          <Typography
            variant="h5"
            color={colors.grey[400]}
            sx={{ m: "20px 0 15px 0" }}
          >
            신청 부스 목록
          </Typography>

          <Table striped bordered hover >
            <thead>
              <tr>
                <th className="text-center">번호</th>
                <th className="text-center">부스 이름</th>
                <th className="text-center">부스 유형</th>
                <th className="text-center">신청 개수</th>
                <th className="text-center">금액(원) – <span style={{ fontSize: "10px" }}>VAT별도</span> </th>

              </tr>
            </thead>
            <tbody>
              {!!boothPrices.length && boothPrices?.map((item, idx) => {
                if (!item) return;
                return <tr key={`booth_${idx}`} className="text-center">
                  <td>{boothPrices?.length - idx}</td>
                  <td>{item?.boothName}</td>
                  <td>{item?.boothType}</td>
                  <td>{item?.count}</td>
                  <td>{formatLargeNumber(item?.price?.toString())}</td>
                </tr>
              })}

              <tr key={'123'} className="text-center">
                <td colSpan={4}>합계</td>
                <td>{formatLargeNumber(totalBoothPrices?.toString()) ?? 0}</td>
              </tr>
            </tbody>
          </Table>

          <Typography
            variant="h5"
            color={colors.grey[400]}
            sx={{ m: "20px 0 15px 0" }}
          >
            신청 물류 목록
          </Typography>

          <Table striped bordered hover >
            <thead>
              <tr>
                <th className="text-center" style={{ whiteSpace: "nowrap" }}>번호</th>
                <th className="text-center">항목</th>
                <th className="text-center">세부 항목</th>
                <th className="text-center" style={{ whiteSpace: "nowrap" }}>금액(원) – <span style={{ fontSize: "10px" }}>VAT별도</span> </th>

              </tr>
            </thead>
            <tbody>
              <tr className="text-center">
                <td className="text-center align-middle" >1</td>
                <td className="text-center align-middle" style={{ whiteSpace: "nowrap" }}>발송 비용</td>
                <td className="text-center align-middle">
                  <Form.Group as={Row} className="align-items-center" style={{ flexWrap: "nowrap" }}>
                    <Col sm="2">
                      <InputGroup size="sm">
                        <InputGroup.Text> 이형 </InputGroup.Text>
                        <Form.Control
                          type="text"
                          placeholder="0"
                          name="oddShaped"
                          size="sm"
                          min={0}
                          value={parcel.oddShaped}
                          onChange={handleChangeParcel}
                          style={{ textAlign: "center" }}
                          disabled
                        />
                      </InputGroup>
                    </Col>
                    <Col sm="2">
                      <InputGroup size="sm">
                        <InputGroup.Text> 대 </InputGroup.Text>
                        <Form.Control
                          type="text"
                          placeholder="0"
                          name="parcelLarge"
                          size="sm"
                          min={0}
                          value={parcel.parcelLarge}
                          onChange={handleChangeParcel}
                          style={{ textAlign: "center" }}
                          disabled
                        />
                      </InputGroup>
                    </Col>
                    <Col sm="2">
                      <InputGroup size="sm">
                        <InputGroup.Text> 중 </InputGroup.Text>
                        <Form.Control
                          type="text"
                          placeholder="0"
                          name="parcelMedium"
                          size="sm"
                          min={0}
                          value={parcel.parcelMedium}
                          onChange={handleChangeParcel}
                          style={{ textAlign: "center" }}
                          disabled
                        />
                      </InputGroup>
                    </Col>
                    <br />
                    <Col sm="2">
                      <InputGroup size="sm">
                        <InputGroup.Text> 소 </InputGroup.Text>
                        <Form.Control
                          type="text"
                          placeholder="0"
                          name="parcelSmall"
                          size="sm"
                          min={0}
                          value={parcel.parcelSmall}
                          onChange={handleChangeParcel}
                          style={{ textAlign: "center" }}
                          disabled
                        />
                      </InputGroup>
                    </Col>
                    <Col sm="2">
                      <InputGroup size="sm">
                        <InputGroup.Text> 극소 </InputGroup.Text>
                        <Form.Control
                          type="text"
                          placeholder="0"
                          name="parcelMini"
                          size="sm"
                          min={0}
                          value={parcel.parcelMini}
                          onChange={handleChangeParcel}
                          style={{ textAlign: "center" }}
                          disabled
                        />
                      </InputGroup>
                    </Col>
                  </Form.Group>
                </td>
                <td className="text-center align-middle" style={{ whiteSpace: "nowrap" }}>{formatLargeNumber(parcel.parcelTotal?.toString())}</td>
              </tr>

              <tr className="text-center">
                <td className="text-center align-middle" >2</td>
                <td className="text-center align-middle" style={{ whiteSpace: "nowrap" }}>재포장 <br /> (박스)비용</td>
                <td className="text-center align-middle">
                  <Form.Group as={Row} className="" >
                    <Col sm="2" >
                      <InputGroup size="sm" >
                        <InputGroup.Text> 대 </InputGroup.Text>
                        <Form.Control
                          type="text"
                          placeholder="0"
                          size="sm"
                          min={0}
                          name="boxLarge"
                          value={shippingBox.boxLarge}
                          onChange={handleChangeBox}
                          style={{ textAlign: "center" }}
                          disabled
                        />
                      </InputGroup>

                    </Col>
                    <Col sm="2">
                      <InputGroup size="sm">
                        <InputGroup.Text> 중 </InputGroup.Text>
                        <Form.Control
                          type="text"
                          placeholder="0"
                          name="boxMedium"
                          size="sm"
                          min={0}
                          value={shippingBox.boxMedium}
                          onChange={handleChangeBox}
                          style={{ textAlign: "center" }}
                          disabled
                        />
                      </InputGroup>

                    </Col>
                    <Col sm="2">

                      <InputGroup size="sm">
                        <InputGroup.Text> 소 </InputGroup.Text>
                        <Form.Control
                          type="text"
                          placeholder="0"
                          name="boxSmall"
                          size="sm"
                          min={0}
                          value={shippingBox.boxSmall}
                          onChange={handleChangeBox}
                          style={{ textAlign: "center" }}
                          disabled
                        />
                      </InputGroup>
                    </Col>
                  </Form.Group>
                </td>
                <td className="text-center align-middle" style={{ whiteSpace: "nowrap" }}>{shippingBox?.boxTotal ? formatLargeNumber((shippingBox.boxTotal)?.toString()) : 0}</td>
              </tr>

              <tr className="text-center">
                <td className="text-center align-middle" >3</td>
                <td className="text-center align-middle" style={{ whiteSpace: "nowrap" }}>퀵서비스</td>
                <td className="text-center align-middle" >
                  {/* 퀵서비스 */}
                  <Form.Group as={Row} className="align-items-center" >
                    <Col sm="4">
                      <InputGroup size="sm" style={{ textAlign: "center" }}>
                        <InputGroup.Text> 트럭 </InputGroup.Text>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="damas"
                          size="sm"
                          min={0}
                          value={formatLargeNumber(quickService.damas?.toString())}
                          onChange={handleChangeQuick}
                          style={{ textAlign: "center" }}
                          disabled
                        />
                      </InputGroup>

                    </Col>
                    원
                    <Col sm="4">

                      <InputGroup size="sm" style={{ textAlign: "center" }}>
                        <InputGroup.Text> 오토바이 </InputGroup.Text>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="motorcycle"
                          size="sm"
                          min={0}
                          value={formatLargeNumber(quickService.motorcycle?.toString())}
                          onChange={handleChangeQuick}
                          style={{ textAlign: "center", minWidth: "80px" }}
                          disabled
                        />
                      </InputGroup>
                    </Col>
                    원
                  </Form.Group>
                </td>
                <td className="text-center align-middle" style={{ whiteSpace: "nowrap" }}>{formatLargeNumber(quickService?.quickTotal?.toString()) || 0}</td>
              </tr>

              <tr key={'4'} className="text-center">
                <td className="text-center align-middle" >4</td>
                <td className="text-center align-middle">기타</td>
                <td className="text-center align-middle" >
                  <Form.Group as={Row} className="align-items-center" >
                    <Col sm="3" style={{ flex: "0 0 28%" }} >
                      <InputGroup size="sm" >
                        <Form.Control
                          type="text"
                          size="sm"
                          min={0}
                          name="reasonFee1"
                          placeholder="항목 1"
                          value={extraFee.reasonFee1 || '-'}
                          onChange={handleChangeExtra}
                          style={{ textAlign: "center" }}
                          disabled
                        />
                      </InputGroup>

                    </Col>
                    <Col sm="2" >
                      <InputGroup size="sm" >
                        <Form.Control
                          type="text"
                          placeholder="0"
                          name="fee1"
                          size="sm"
                          min={0}
                          value={formatLargeNumber(extraFee.fee1?.toString())}
                          onChange={handleChangeExtra}
                          style={{ textAlign: "center" }}
                          disabled
                        />
                      </InputGroup>
                    </Col>
                    원
                    <Col sm="3" style={{ flex: "0 0 28%" }} className="!ml-3">
                      <InputGroup size="sm" >
                        <Form.Control
                          type="text"
                          placeholder="항목 2"
                          size="sm"
                          min={0}
                          name="reasonFee2"
                          value={extraFee.reasonFee2 || "-"}
                          onChange={handleChangeExtra}
                          style={{ textAlign: "center" }}
                          disabled
                        />
                      </InputGroup>

                    </Col>
                    <Col sm="2">
                      <InputGroup size="sm" >
                        <Form.Control
                          type="text"
                          placeholder="0"
                          name="fee2"
                          size="sm"
                          min={0}
                          value={formatLargeNumber(extraFee.fee2?.toString())}
                          onChange={handleChangeExtra}
                          style={{ textAlign: "center" }}
                          disabled
                        />
                      </InputGroup>
                    </Col>
                    원
                  </Form.Group>
                </td>
                <td className="text-center align-middle" style={{ whiteSpace: "nowrap" }}>{formatLargeNumber((Number(extraFee.fee1) + Number(extraFee.fee2))?.toString())}</td>
              </tr>

              <tr key={'123'} className="text-center">
                <td colSpan={3}>합계</td>
                <td>{formatLargeNumber((Number(parcel?.parcelTotal) + Number(shippingBox?.boxTotal) + Number(quickService?.quickTotal) + Number(extraFee.fee1) + Number(extraFee.fee2))?.toString()) || 0}</td>
              </tr>
            </tbody>
          </Table>

          <Typography
            variant="h5"
            color={colors.grey[400]}
            sx={{ m: "20px 0 10px 0" }}
          >
            기타
          </Typography>

          <Table striped bordered hover className="table text-center">
            <thead>
              <tr>
                <th className="text-center" >번호</th>
                <th className="text-center" style={{ minWidth: "300px" }}>항목</th>
                <th className="text-center" >신청 개수</th>
                <th className="text-center">금액(원) – <span style={{ fontSize: "10px" }}>VAT별도</span> </th>
              </tr>
            </thead>
            <tbody>
              <tr key={'123'} className="text-center">
                <td className="text-center align-middle">
                  1
                </td>
                <td className="text-center align-middle">방명록</td>
                <td className="text-center align-middle">
                  <InputGroup size="sm">
                    <Form.Control
                      type="text"
                      placeholder="0"
                      name="guestBook"
                      value={forms.guestBook}
                      size="sm"
                      min={0}
                      onChange={handleChangeState}
                      style={{ textAlign: "center" }}
                      disabled
                    />
                  </InputGroup></td>
                <td className="text-center align-middle" >{formatLargeNumber((forms.guestBook * Number(otherCost?.guestbookCost ?? 0))?.toString()) || 0}</td>
              </tr>
              <tr className="text-center">
                <td className="text-center align-middle">2</td>
                <td className="text-center align-middle">{otherCost.otherReasonCost1 ?? '-'}</td>
                <td className="text-center align-middle"><InputGroup size="sm">
                  <Form.Control
                    type="text"
                    placeholder="0"
                    name="otherCostNumber1"
                    value={forms.otherCostNumber1}
                    size="sm"
                    min={0}
                    onChange={handleChangeState}
                    style={{ textAlign: "center" }}
                  />
                </InputGroup></td>
                <td className="text-center align-middle" >{formatLargeNumber(((forms.otherCostNumber1 ?? 0) * Number(otherCost?.otherCost1 ?? 0))?.toString()) || 0}</td>
              </tr>
              <tr className="text-center">
                <td className="text-center align-middle">3</td>
                <td className="text-center align-middle">{otherCost.otherReasonCost2 ?? '-'}</td>
                <td className="text-center align-middle"><InputGroup size="sm">
                  <Form.Control
                    type="text"
                    placeholder="0"
                    name="otherCostNumber2"
                    value={forms.otherCostNumber2}
                    size="sm"
                    min={0}
                    onChange={handleChangeState}
                    style={{ textAlign: "center" }}
                  />
                </InputGroup></td>
                <td className="text-center align-middle" >{formatLargeNumber(((forms?.otherCostNumber2 ?? 0) * Number(otherCost?.otherCost2 ?? 0))?.toString()) || 0}</td>
              </tr>
              <tr className="text-center">
                <td className="text-center align-middle">4</td>
                <td className="text-center align-middle">{otherCost.otherReasonCost3 ?? '-'}</td>
                <td className="text-center align-middle"><InputGroup size="sm">
                  <Form.Control
                    type="text"
                    placeholder="0"
                    name="otherCostNumber3"
                    value={forms.otherCostNumber3}
                    size="sm"
                    min={0}
                    onChange={handleChangeState}
                    style={{ textAlign: "center" }}
                  />
                </InputGroup></td>
                <td className="text-center align-middle" >{formatLargeNumber(((forms?.otherCostNumber3 ?? 0) * Number(otherCost?.otherCost3 ?? 0))?.toString()) || 0}</td>
              </tr>
              <tr className="text-center">
                <td className="text-center align-middle">5</td>
                <td className="text-center align-middle">{otherCost.otherReasonCost4 ?? '-'}</td>
                <td className="text-center align-middle"><InputGroup size="sm">
                  <Form.Control
                    type="text"
                    placeholder="0"
                    name="otherCostNumber4"
                    value={forms.otherCostNumber4}
                    size="sm"
                    min={0}
                    onChange={handleChangeState}
                    style={{ textAlign: "center" }}
                  />
                </InputGroup></td>
                <td className="text-center align-middle" >{formatLargeNumber(((forms?.otherCostNumber4 || 0) * Number(otherCost?.otherCost4 || 0))?.toString()) || 0}</td>
              </tr>
              <tr className="text-center">
                <td className="text-center align-middle">6</td>
                <td className="text-center align-middle">{otherCost.otherReasonCost5 ?? '-'}</td>
                <td className="text-center align-middle"><InputGroup size="sm">
                  <Form.Control
                    type="text"
                    placeholder="0"
                    name="otherCostNumber5"
                    value={forms.otherCostNumber5}
                    size="sm"
                    min={0}
                    onChange={handleChangeState}
                    style={{ textAlign: "center" }}
                  />
                </InputGroup></td>
                <td className="text-center align-middle" >{formatLargeNumber(((forms?.otherCostNumber5 ?? 0) * Number(otherCost?.otherCost5 ?? 0))?.toString()) || 0}</td>
              </tr>

              <tr className="text-center">
                <td colSpan={3}>합계</td>
                <td>{formatLargeNumber(calculateTotalCost(otherCost, forms)?.toString())}</td>
              </tr>
            </tbody>
          </Table>

          <Table striped bordered hover className="table text-center">
            <tbody>
              <tr key={'123'} className="text-center">
                <td className="text-center align-middle" style={{ width: "33.33%" }}>
                  신청 내역 총 합계
                </td> {/* Ô đầu tiên gộp 2 dòng */}

                <td style={{ width: "33.33%", padding: "0" }}>
                  <div style={{ height: "50%", borderBottom: "1px solid #dee2e6", display: "flex", alignItems: "center", justifyContent: "center", padding: "2px 0" }}>
                    금액(원)_ vat별도
                  </div>
                  <div style={{ height: "50%", display: "flex", alignItems: "center", justifyContent: "center", paddingTop: "2px", fontSize: "16px" }}>
                    {formatLargeNumber((
                      calculateTotalCost(otherCost, forms) +
                      Number(parcel.parcelTotal) +
                      Number(shippingBox.boxTotal) +
                      Number(quickService?.quickTotal) +
                      Number(extraFee.fee1) +
                      Number(extraFee.fee2) +
                      totalBoothPrices
                    )?.toString())}
                  </div>
                </td>
                <td className="text-center" style={{ width: "33.33%" }}>
                  *견적금액은 수정사항이 있는 경우 변경될 수 있으며, 신청단계가 처리 완료된 때에 확인바랍니다.
                </td>
              </tr>
            </tbody>
          </Table>

          <hr />
          <Row className="mt-5">
            <Col className="text-end ">
              <Button className="px-4 mx-3" onClick={handlePrint}>인쇄</Button>
              <Button variant="outline-secondary" type="button" className="px-4 mx-3" onClick={() => {
                if (isFromEvent) {
                  navigate("/eventform", { state: { backPage: true } })
                } else {
                  navigate("/eventReport", { state: { backPage: true } })
                }
              }}>
                목 록
              </Button>{" "}
              <Button className="px-4 mx-3" onClick={handlePrintStatement}>거래명세표 인쇄</Button>

              <Button as="input" type="submit" value="저장" className="px-4 ml-4" />
            </Col>

            <div style={{ display: "none" }} >
              <PrintEvent
                boothPrices={boothPrices}
                totalBoothPrices={totalBoothPrices}
                otherCost={otherCost}
                data={data}
                eventLogs={eventLogs}
                ref={componentRef} />
            </div>

            <div style={{ display: "none" }} >
              <PrintEventStatement
                boothPrices={boothPrices}
                totalBoothPrices={totalBoothPrices}
                otherCost={otherCost}
                data={data}
                eventLogs={eventLogs}
                parcelPrices={parcelPrices}
                boxPrices={boxPrices}
                extraFee={extraFee}
                ref={componentRefStatement} />
            </div>
          </Row>
        </Form>
      </Container>
    </>
  );
};



export default memo(EventFormDetail);
