import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import HeardSearch from "../../../components/HeardSearch";
import { useNavigate } from "react-router-dom";
import { Button, Col, Pagination, Row, Table } from "react-bootstrap";
import { memo, useEffect, useState } from "react";
import { getAllboothtypeFunc } from "../../../api";
import { toast } from "react-toastify";
import Pagenation from "../../../components/Pagenation";
import { ORDER_BY } from "../../../utils/type";

const BoothType = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [totlaCount, setTotalCount] = useState({
    total: 0,
    pageItem: 0,
  });

  const hendleChangePage = () => {
    navigate("/boothtyperegister");
  };
  const headColumn = ["번호", "부스 종류 1", "부스 종류 2", "부스 형태"];
  const myButton = [
    { variant: "primary", name: "부스종류등록", onClick: hendleChangePage },
  ];
  // BoothTypeRegister
  const searchForm = {
    title: "부스종류관리",
    period: {
      exist: false,
    },
    section: { exist: false },
    state: {
      exist: false,
      type: "checkbox",
      name: ["접수", "신규", "발송", "취소"],
    },
    search: { exist: false },
  };


  useEffect(() => {
    // 전체회사 리스트
    const allBoothtypeGet = async (page) => {
      const response = await getAllboothtypeFunc(page, ORDER_BY.ASC);
      if (response.status === 200) {

        const boothGroup = [];
        response?.data?.data?.forEach((item) => {
          const key = `${item.boothType}`
          if (!boothGroup[key]) {
            boothGroup[key] = []
          }

          boothGroup[key].push(item);
        })

        setData(() => boothGroup);
        setPageCount(() =>
          response?.data?.Pagination?.pageCount
        );
        setTotalCount((totlaCount) => ({
          ...totlaCount,
          total: response?.data?.Pagination?.count,
          pageItem: response?.data?.Pagination?.pageItem,
        }));
      } else {
        toast.error("부스종류를 가져오는데 실패했습니다.");
      }
    };
    allBoothtypeGet(page);
  }, [page]);
  return (
    <>
      <Container className='align-items-center justify-content-center p-5'>
        <HeardSearch searchForm={searchForm} />
        <Table striped bordered hover>
          <thead>
            <tr>
              <th colSpan={3} className='text-center' style={{ backgroundColor: "#CA56A7" }}>
                고정 값
              </th>
              <th className='text-center' style={{ backgroundColor: "#699B36" }}>
                입력 값
              </th>
            </tr>
            <tr>
              {headColumn.map((it, idx) => (
                <th className='text-center' key={idx}>
                  {it}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {
              Object.entries(data)?.flatMap(([id, booths], groupIdx) =>
                booths.map((it, idx) =>
                  <tr
                    key={it._id}
                    className='text-center align-middle'
                    style={{ cursor: "pointer" }}
                    onClick={(e) => navigate("/boothtype-edit/" + it._id)}
                  >
                    {/* <td>{totlaCount?.total -
                      (page - 1) * totlaCount?.pageItem -
                      idx}</td> */}
                    <td>{groupIdx + 1}-{idx + 1}</td>
                    {it?.boothType ? <td>{it.boothType}</td> : null}
                    {it?.boothType2 ? <td>{it?.boothType2}</td> : <td></td>}
                    {it?.boothName ? <td>{it.boothName}</td> : null}
                  </tr>
                )
              )
            }
            {/* {data.map((it, idx) => (
              <tr
                key={it._id}
                className='text-center align-middle'
                style={{ cursor: "pointer" }}
                onClick={(e) => navigate("/boothtype-edit/" + it._id)}
              >
                <td>{totlaCount?.total -
                  (page - 1) * totlaCount?.pageItem -
                  idx}</td>
                {it?.boothType ? <td>{it.boothType}</td> : null}
                {it?.boothType2 ? <td>{it?.boothType2}</td> : <td></td>}
                {it?.boothName ? <td>{it.boothName}</td> : null}
              </tr>
            ))} */}
          </tbody>
        </Table>
        <Pagenation
          pageCount={pageCount}
          page={page}
          setPage={setPage}
        />
        <Row className='mt-3 mb-3 text-end'>
          <Col>
            {myButton.map((it, idx) => (
              <Button
                key={idx}
                variant={it.variant}
                onClick={it.onClick ? it.onClick : null}
                className='px-4 mx-2'
              >
                {it.name}
              </Button>
            ))}
          </Col>
        </Row>

      </Container>
    </>
  );
};

export default memo(BoothType);
