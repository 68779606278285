import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "@mui/material/Container";
import { memo, useEffect, useRef, useState } from "react";
import { tokens } from "../../theme";
import { Typography, useTheme } from "@mui/material";
import {
  getAllClientsFunc,
  getAllProductFunc,
  goodsRegisterFunc,
} from "../../api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ManageMentForm = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const imgInputRef = useRef();
  const imgInputRef2 = useRef();
  const imgInputRef3 = useRef();
  /* preview image*/
  const [showImages, setShowImages] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectClient, setSelectClient] = useState("");
  const [products, setProducts] = useState([]);
  const [selectProduct, setSelectProduct] = useState({});
  const [forms, setForms] = useState([]);
  const [imgSrc, setImgSrc] = useState(null);
  const [imgSrc2, setImgSrc2] = useState(null);
  const [imgSrc3, setImgSrc3] = useState(null);
  const [autoRecive, setautoRecive] = useState(false);

  const handleChangeAutoRecive = (e) => {
    setautoRecive(e.target.checked);
  };

  const allClientsGet = async () => {
    const response = await getAllClientsFunc();
    if (response.status === 200) {
      setClients(response.data?.data);
    } else {
      toast.error("거래처 정보를 가져오기 실패");
    }
  };

  useEffect(() => {
    allClientsGet();
  }, []);
  // const handleChangeState = (e) => {
  //   console.log(`${e.target.name}:${e.target.value}`);
  //   setState({
  //     ...state,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  // 이미지 뷰어
  const encodeFileToBase64 = (fileBlob, setImage) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);
    return new Promise((resolve) => {
      reader.onload = () => {
        setImage(reader.result);
        resolve();
      };
    });
  };

  const handleDeleteImg = (e) => {
    if (e.target.name === "img1") {
      setImgSrc(null);
      imgInputRef.current.value = "";
    } else if (e.target.name === "img2") {
      setImgSrc2(null);
      imgInputRef2.current.value = "";
    } else if (e.target.name === "img3") {
      setImgSrc3(null);
      imgInputRef3.current.value = "";
    }
  };

  const handleChangeImage = (e) => {
    // console.log(e.target.name);

    const selectedFile = e.target.files[0];
    const maxSize = 5 * 1024 * 1024; // 5 MB in bytes

    if (selectedFile?.size > maxSize) {
      toast.error('5MB 이하의 파일을 업로드해주세요.');
      handleDeleteImg(e)
      return;
    }

    if (e.target.name === "img1") {
      encodeFileToBase64(e.target.files[0], setImgSrc);
    } else if (e.target.name === "img2") {
      encodeFileToBase64(e.target.files[0], setImgSrc2);
    } else if (e.target.name === "img3") {
      encodeFileToBase64(e.target.files[0], setImgSrc3);
    }
  };

  const handleDeleteImage = (e) => {
    if (e.target.id === "del_img1") {
      setImgSrc(null);
      imgInputRef.current.value = "";
    } else if (e.target.id === "del_img2") {
      setImgSrc2(null);
      imgInputRef2.current.value = "";
    } else if (e.target.id === "del_img3") {
      setImgSrc3(null);
      imgInputRef3.current.value = "";
    }
  };

  const handleChangeClient = (e) => {
    const { value } = e.target;
    // console.log(value)
    const clientName = e.target[e.target.selectedIndex].text;
    // console.log(e.target[e.target.selectedIndex].text)
    if (value === "거래처선택") {
      setSelectClient("");
      return;
    }
    setSelectClient({ id: value, clientName: clientName });
  };
  const handleChangeProduct = (e) => {
    const { value } = e.target;
    // const productName = e.target[e.target.selectedIndex].text
    let result = products.find((product) => product._id === value);
    // console.log("result", result);
    if (result === undefined) {
      setSelectProduct({});
    } else {
      setSelectProduct(result);
    }
  };

  const productData = async (id) => {
    const res = await getAllProductFunc(id, false);
    if (res.status === 200) {
      setProducts(res.data);
    } else {
      toast.error("제품정보 가져오기실패!!");
    }
  };

  useEffect(() => {
    if (selectClient === "" || selectClient === "거래처선택") {
      setSelectClient("");
      setSelectProduct([]);
      setProducts([]);
      return;
    } else {
      setSelectProduct([]);
      setProducts([]);
    }
    // console.log(selectClient)
    productData(selectClient.id);
  }, [selectClient]);

  const onChange = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!forms.goodsName || forms.goodsName === "") {
      toast.error("물품명을 입력해주세요.");
      return;
    }

    // if(!forms.expirationData || forms.expirationData === "") {
    //   toast.error("유통기한을 입력해주세요.");
    //   return;
    // }

    if (!forms.currentAmount || forms.currentAmount === "") {
      toast.error("재고을 입력해주세요.");
      return;
    }

    const formData = new FormData(e.target);
    formData.append("clientName", selectClient.clientName);
    formData.append("department", selectProduct.department);
    formData.append("productName", selectProduct.productName);
    // const staff = []
    // if (Object.keys(selectProduct.staff).length !== 0) {
    //   selectProduct?.staff.forEach(element => {
    //     staff.push(element.staffName)
    //   })
    //   formData.append("staffName",selectProduct?.staff.staffName)
    // } else {
    //   formData.append("staffName",[])
    // }
    formData.append("staffName", selectProduct?.staff?.staffName);

    // console.log("selectProduct", selectProduct?.staff?.staffName);

    // console.log(selectProduct)
    const config = {
      "Content-Type": "multipart/form-data",
    };
    // console.log(Object.fromEntries(formData.entries()));

    const res = await goodsRegisterFunc(formData, config);
    if (res.status === 201 || res.status === 200) {
      toast.success("Successfully Register!");
      // setTimeout(() => {
      //   navigate("/partnerstable", { replace: true });
      // }, 1000);
      setForms({
        ...forms,
        goodsName: "",
        goodsCode: "",
        memo: "",
        manufactureData: "",
        expirationData: "",
        currentAmount: "",
        orderAmount: "",
        loadingLocation: "",
      });
      setImgSrc(null);
      setImgSrc2(null);
      setImgSrc3(null);
      setProducts([]);
      setSelectProduct({})
      imgInputRef.current.value = "";
      imgInputRef2.current.value = "";
      imgInputRef3.current.value = "";
      setTimeout(() => {
        navigate("/disManagement");
      }, 2000);
    } else {
      toast.error("물품등록에 실패했습니다. 빈칸을 채워주세요.!");
    }
  };

  return (
    <>
      <Container maxWidth='md' className='py-3'>
        <Row className='fs-1 fw-bold p-2 border-bottom mb-5'>물품 등록</Row>
        <Form onSubmit={handleSubmit}>
          <Typography
            variant='h4'
            color={colors.grey[400]}
            sx={{ m: "15px 0 15px 0" }}
          >
            1.물품정보
          </Typography>
          {/* 거래처명 */}
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              거래처명 <span className="require">*</span>
            </Form.Label>
            <Col xs={3}>
              <Form.Select
                aria-label='Default select example'
                size='sm'
                name='clientID'
                onChange={handleChangeClient}
              >
                <option >거래처선택</option>
                {clients &&
                  clients?.map((it) => (
                    <option key={it._id} value={it._id}>
                      {it.companyName}
                    </option>
                  ))}
              </Form.Select>
            </Col>
          </Form.Group>

          {/* 제품명 */}
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              제품명 <span className="require">*</span>
            </Form.Label>
            <Col xs={3}>
              <Form.Select
                aria-label='Default select example'
                size='sm'
                name='productID'
                onChange={handleChangeProduct}
              >
                <option>제품명 선택</option>
                {products &&
                  products?.map((it) => (
                    <option key={it._id} value={it._id}>
                      {it.productName}
                    </option>
                  ))}
              </Form.Select>
            </Col>
          </Form.Group>
          {/* 부서명 */}
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              부서명 <span className="require">*</span>
            </Form.Label>

            <Col xs={9}>
              <Form.Label column sm='6' name='department'>
                {selectProduct && selectProduct?.department}
              </Form.Label>
              {/* <Form.Select aria-label="Default select example" size="sm">
                          <option>부서 선택</option>
                          <option value="1">거래처</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select> */}
            </Col>
          </Form.Group>
          {/* 부서명 */}
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              담당자 <span className="require">*</span>
            </Form.Label>

            <Col xs={9}>
              <Form.Label column sm='6'>
                {/* {Object.keys(selectProduct).length !== 0
                  ? selectProduct?.staff.map((it,idx) => (
                      <span key={idx} className='inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 mx-1'>
                        {it.staffName}
                      </span>
                    ))
                  : ""}
                  */}
                {selectProduct?.staff?.staffName}
              </Form.Label>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              물품명 <span className="require">*</span>
            </Form.Label>
            <Col sm='3'>
              <Form.Control
                name='goodsName'
                type='text'
                size='sm'
                value={forms.goodsName || ""}
                onChange={onChange}

              />
            </Col>
          </Form.Group>


          {/* 카테고리 */}
          <Form.Group as={Row} className='mb-3 px-3 align-items-center'>
            <Form.Label column sm='2'>
              종류 <span className="require">*</span>
            </Form.Label>
            <Col sm='9'>
              {["radio"].map((type) => (
                <div key={`inline-${type}`} onChange={onChange}>
                  <Form.Check
                    inline
                    label='판촉물'
                    defaultChecked
                    name='category'
                    type={type}
                    value='판촉물'
                  />
                  <Form.Check
                    inline
                    label='브로슈어'
                    name='category'
                    type={type}
                    value='브로슈어'
                  />
                  <Form.Check
                    inline
                    label='문헌'
                    name='category'
                    type={type}
                    value='문헌'
                  />
                  <Form.Check
                    inline
                    // disabled
                    label='식품류'
                    name='category'
                    type={type}
                    value='식품류'
                  />
                </div>
              ))}
            </Col>
          </Form.Group>
          {/* 단위 */}
          <Form.Group as={Row} className='mb-3 px-3 align-items-center'>
            <Form.Label column sm='2'>
              단위 <span className="require">*</span>
            </Form.Label>
            <Col sm='9'>
              {["radio"].map((type) => (
                <div key={`inline-${type}`} onChange={onChange}>
                  <Form.Check
                    inline
                    label='Box'
                    name='unit'
                    type={type}
                    value='box'
                  />
                  <Form.Check
                    inline
                    label='EA'
                    defaultChecked
                    name='unit'
                    type={type}
                    value='ea'
                  />
                  <Form.Check
                    inline
                    label='SET'
                    name='unit'
                    type={type}
                    value='set'
                  />
                </div>
              ))}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              비고
            </Form.Label>
            <Col sm='6'>
              <Form.Control
                as="textarea"
                type='text'
                size='sm'
                name='memo'
                value={forms.memo || ""}
                onChange={onChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              제조일자
            </Form.Label>
            <Col sm='3'>
              <Form.Control
                type='date'
                size='sm'
                name='manufactureData'
                value={forms.manufactureData || ""}
                onChange={onChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              유통기한
            </Form.Label>
            <Col sm='3'>
              <Form.Control
                type='date'
                size='sm'
                name='expirationData'
                value={forms.expirationData || ""}
                onChange={onChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              재고 <span className="require">*</span>
            </Form.Label>
            <Col sm='3'>
              <Form.Control
                type='number'
                size='sm'
                name='currentAmount'
                value={forms.currentAmount || ""}
                onChange={onChange}
                min='0'
              />
            </Col>
          </Form.Group>
          {/* <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              출고가능
            </Form.Label>
            <Col sm='3'>
              <Form.Control
                type='number'
                size='sm'
                name='orderAmount'
                value={
                  parseInt(forms?.orderAmount) >= parseInt(forms.currentAmount)
                    ? forms.currentAmount
                    : forms?.orderAmount
                }
                onChange={onChange}
                min='0'
                max={forms.currentAmount}
              />
            </Col>
          </Form.Group> */}
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              적재위치
            </Form.Label>
            <Col sm='3'>
              <Form.Control
                type='text'
                size='sm'
                name='loadingLocation'
                value={forms.loadingLocation || ""}
                onChange={onChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              제한수량 일괄 등록하기
            </Form.Label>
            <Col sm='3'>
              <Form.Control
                type='number'
                size='sm'
                name='limitNumber'
                value={forms.limitNumber || ""}
                onChange={onChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              자동 입고 품목 지정
            </Form.Label>
            <Col sm='3'>
              <Form.Check
                inline
                name={'autoRecive'}
                type="checkbox"
                checked={autoRecive || ""}
                onChange={handleChangeAutoRecive}
                value={autoRecive}
              />
            </Col>
          </Form.Group>

          <Row>
            <Form.Label column sm='2'>
              {""}
            </Form.Label>
            <Col lg='6'>
              <small className='mt-2 px-2 d-block text-danger text-start'>
                * 최적 사이즈 (800픽셀 * 600픽셀)
              </small>
            </Col>
          </Row>
          <Row>
            <Form.Label column sm='2'>
              {""}
            </Form.Label>
            <Col lg='6'>
              <small className='mt-3 px-2 d-block text-danger mb-1 text-start'>
                * 5MB를 초과하는 파일은 등록할 수 없습니다.
              </small>
            </Col>
          </Row>
          <Form.Group as={Row} className='mb-3 px-3 align-items-center'>
            <Form.Label column sm='2'>
              물품이미지 1
            </Form.Label>
            <Col >
              {/* 이미지 프리뷰 */}
              {imgSrc && (
                <Row sm='9' className="mb-3">
                  <img
                    src={imgSrc}
                    alt='img preview'
                    style={{ maxWidth: "100%", height: "auto", width: "100%" }}
                  />
                </Row>
              )}
              <Row className='d-flex gap-2 align-items-center'>
                <Form.Group as={Col} controlId='formFile' sm='5'>
                  <Form.Control
                    ref={imgInputRef}
                    name='img1'
                    type='file'
                    size='sm'
                    onChange={handleChangeImage}
                    accept='image/*'
                  />
                </Form.Group>
                <Col>
                  {imgSrc && (
                    <span
                      onClick={handleDeleteImage}
                      id='del_img1'
                      className=' px-3 py-1 bg-sky-600 rounded-md text-white font-bold cursor-pointer'
                    >
                      삭제
                    </span>
                  )}
                </Col>
              </Row>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3 align-items-center'>
            <Form.Label column sm='2'>
              물품이미지 2
            </Form.Label>

            <Col >
              {/* 이미지 프리뷰 */}
              {imgSrc2 && (
                <Row sm='9' className="mb-3">
                  <img
                    src={imgSrc2}
                    alt='img preview'
                    style={{ maxWidth: "100%", height: "auto", width: "100%" }}
                  />
                </Row>
              )}
              <Row className='d-flex gap-2 align-items-center'>
                <Form.Group as={Col} controlId='formFile' sm='5'>
                  <Form.Control
                    ref={imgInputRef2}
                    type='file'
                    size='sm'
                    name='img2'
                    disabled={!imgSrc}
                    onChange={handleChangeImage}
                    accept='image/*'
                  />
                </Form.Group>
                <Col>
                  {imgSrc2 && (
                    <span
                      onClick={handleDeleteImage}
                      id='del_img2'
                      className=' px-3 py-1 bg-sky-600 rounded-md text-white font-bold cursor-pointer'
                    >
                      삭제
                    </span>
                  )}
                </Col>
              </Row>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3 align-items-center'>
            <Form.Label column sm='2'>
              물품이미지 3
            </Form.Label>
            <Col >
              {/* 이미지 프리뷰 */}
              {imgSrc3 && (
                <Row sm='9' className="mb-3">
                  <img
                    src={imgSrc3}
                    alt='img preview'
                    style={{ maxWidth: "100%", height: "auto", width: "100%" }}
                  />
                </Row>
              )}
              <Row className='d-flex gap-2 align-items-center'>
                <Form.Group as={Col} controlId='formFile' sm='5'>
                  <Form.Control
                    ref={imgInputRef3}
                    type='file'
                    size='sm'
                    name='img3'
                    disabled={!imgSrc2}
                    onChange={handleChangeImage}
                    accept='image/*'
                  />
                </Form.Group>
                <Col>
                  {imgSrc3 && (
                    <span
                      onClick={handleDeleteImage}
                      id='del_img3'
                      className=' px-3 py-1 bg-sky-600 rounded-md text-white font-bold cursor-pointer'
                    >
                      삭제
                    </span>
                  )}
                </Col>
              </Row>
            </Col>
          </Form.Group>

          <hr />
          <Row className='mt-5'>
            {/* <Col className="text-start ">
              <Button variant="outline-secondary" className="px-4 mx-3">
                삭제
              </Button>{" "}
            </Col> */}
            <Col className='text-end '>
              <Button
                variant='outline-secondary'
                type='button'
                className='px-4 mx-3'
                onClick={() => navigate("/disManagement", { replace: true, state: { backPage: true } })}
              >
                목 록
              </Button>{" "}
              <Button
                as='input'
                type='submit'
                disabled={selectProduct.length === 0}
                value='저장'
              />{" "}
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default memo(ManageMentForm);
