import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import HeardSearch from "../../../components/HeardSearch";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Pagination, Row, Table } from "react-bootstrap";
import { memo, useEffect, useState } from "react";
import { areaPricesRegisterFunc, getAllAreaPriceFunc, getAllboothtypeFunc } from "../../../api";
import { toast } from "react-toastify";
import Pagenation from "../../../components/Pagenation";
import { AreaCity } from "../../../utils/constant";
import { formatLargeNumber } from "../../../utils";
import { ORDER_BY } from "../../../utils/type";

const BoothTypePrice = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const { id, idArea } = useParams()

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [totlaCount, setTotalCount] = useState({
    total: 0,
    pageItem: 0,
  });
  const [prices, setPrices] = useState({})
  const [prices2, setPrices2] = useState({})

  const areaSelect = AreaCity?.find((item) => item.id === idArea);

  const hendleChangePage = () => {
    navigate("/boothtyperegister");
  };
  const headColumn = ["번호", "부스 종류 1", "부스 종류 2", "부스 형태", "1부스 금액", "2부스 할인 금액"];
  const myButton = [
    { variant: "primary", name: "부스종류등록", onClick: hendleChangePage },
  ];
  // BoothTypeRegister
  const searchForm = {
    title: `${areaSelect?.name} 지역 금액 정보`,
    period: {
      exist: false,
    },
    section: { exist: false },
    state: {
      exist: false,
      type: "checkbox",
      name: ["접수", "신규", "발송", "취소"],
    },
    search: { exist: false },
  };


  useEffect(() => {
    // 전체회사 리스트
    const allBoothtypeGet = async (page) => {
      const [response, areaPrices] = await Promise.all([
        getAllboothtypeFunc(page, ORDER_BY.ASC),
        getAllAreaPriceFunc(idArea, id)
      ]);

      if (response.status === 200) {

        const oldPrices = areaPrices?.data?.data?.reduce((acc, areaPrice) => {
          acc[areaPrice?.boothTypeId] = { price: areaPrice?.price, price2: areaPrice.price2, name: areaPrice?.boothTypeName }
          return acc;
        }, {})

        setPrices(oldPrices);

        const boothGroup = [];
        response?.data?.data?.forEach((item) => {
          const key = `${item.boothType}`
          if (!boothGroup[key]) {
            boothGroup[key] = []
          }

          boothGroup[key].push(item);
        })

        setData(() => boothGroup);
        setPageCount(() =>
          response?.data?.Pagination?.pageCount
        );
        setTotalCount((totlaCount) => ({
          ...totlaCount,
          total: response?.data?.Pagination?.count,
          pageItem: response?.data?.Pagination?.pageItem,
        }));
      } else {
        toast.error("부스종류를 가져오는데 실패했습니다.");
      }
    };
    allBoothtypeGet(page);
  }, [page]);

  const handleInputChange = (id, name, value) => {

    setPrices((prev) => ({ ...prev, [id]: { ...prev[id], price: value, name }, }));
  }

  const handleInputChangePrice2 = (id, name, value) => {

    setPrices((prev) => ({ ...prev, [id]: { ...prev[id], price2: value, name }, }));
  }

  const handleSubmit = async () => {

    const response = await areaPricesRegisterFunc({
      clientId: id,
      areaId: areaSelect?.id,
      areaName: areaSelect?.name,
      areaPrices: prices,
    });

    if (response.status === 200) {
      toast.success("Successfully Update!");
      navigate("/areaPrice/" + id, { replace: true, state: { selectSection: 0 } });
    } else {
      toast.error("거래처정보 변경실패!!");
    }
  };

  return (
    <>
      <Container className='align-items-center justify-content-center p-5'>
        <HeardSearch searchForm={searchForm} />
        <Table striped bordered hover>
          <thead>
            <tr>
              {headColumn.map((it, idx) => (
                <th className='text-center' key={idx}>
                  {it}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {
             Object.entries(data)?.flatMap(([key, booths], groupIdx) => 
                booths?.map((it, idx) =>
                <tr
                  key={`${it._id}-${idx}`}
                  className='text-center align-middle'
                  style={{ cursor: "pointer" }}
                // onClick={(e) => navigate("/boothtype-edit/" + it._id)}
                >
                  <td>{groupIdx + 1}-{idx + 1}</td>
                  <td>{it.boothType}</td>
                  <td>{it.boothType2}</td>
                  <td>{it.boothName}</td>
                  <td>
                    <input
                      type='text'
                      className="order-by-product h-[42px]"
                      style={{ width: "70%" }}
                      value={prices[it._id]?.price !== undefined ?
                        formatLargeNumber(prices[it._id]?.price?.toString())
                        : (it?.orderBy ? formatLargeNumber(it?.orderBy?.toString()) : "")}

                      min={1}
                      onChange={(e) => {
                        let rawValue = e.target.value.replace(/,/g, "");
                        rawValue = rawValue.replace(/^0+/, "");

                        const regex = /^\d+$/;
                        if (!regex.test(rawValue) && rawValue !== "") return;

                        handleInputChange(
                          it._id,
                          it.boothName,
                          rawValue,
                        )
                      }
                      }
                      placeholder=''
                    /> 원
                  </td>
                  <td>
                    <input
                      type='text'
                      className="order-by-product h-[42px]"
                      style={{ width: "70%" }}
                      value={prices[it._id]?.price2 !== undefined ?
                        formatLargeNumber(prices[it._id]?.price2?.toString())
                        : (it?.orderBy ? formatLargeNumber(it?.orderBy?.toString()) : "")}

                      min={1}
                      onChange={(e) => {
                        let rawValue = e.target.value.replace(/,/g, "");
                        rawValue = rawValue.replace(/^0+/, "");

                        const regex = /^\d+$/;
                        if (!regex.test(rawValue) && rawValue !== "") return;

                        handleInputChangePrice2(
                          it._id,
                          it.boothName,
                          rawValue,
                        )
                      }
                      }
                      placeholder=''
                    /> 원
                  </td>
                </tr>
              )
              )
            }
            {/* {data.map((it, idx) => (
              <tr
                key={it._id}
                className='text-center align-middle'
                style={{ cursor: "pointer" }}
              // onClick={(e) => navigate("/boothtype-edit/" + it._id)}
              >
                <td>{idx + 1}</td>
                <td>{it.boothType}</td>
                <td>{it.boothType2}</td>
                <td>{it.boothName}</td>
                <td>
                  <input
                    type='text'
                    className="order-by-product h-[42px]"
                    style={{ width: "70%" }}
                    value={prices[it._id]?.price !== undefined ?
                      formatLargeNumber(prices[it._id]?.price?.toString())
                      : (it?.orderBy ? formatLargeNumber(it?.orderBy?.toString()) : "")}

                    min={1}
                    onChange={(e) => {
                      let rawValue = e.target.value.replace(/,/g, "");
                      rawValue = rawValue.replace(/^0+/, "");

                      const regex = /^\d+$/;
                      if (!regex.test(rawValue) && rawValue !== "") return;

                      handleInputChange(
                        it._id,
                        it.boothName,
                        rawValue,
                      )
                    }
                    }
                    placeholder=''
                  /> 원
                </td>
                <td>
                  <input
                    type='text'
                    className="order-by-product h-[42px]"
                    style={{ width: "70%" }}
                    value={prices[it._id]?.price2 !== undefined ?
                      formatLargeNumber(prices[it._id]?.price2?.toString())
                      : (it?.orderBy ? formatLargeNumber(it?.orderBy?.toString()) : "")}

                    min={1}
                    onChange={(e) => {
                      let rawValue = e.target.value.replace(/,/g, "");
                      rawValue = rawValue.replace(/^0+/, "");

                      const regex = /^\d+$/;
                      if (!regex.test(rawValue) && rawValue !== "") return;

                      handleInputChangePrice2(
                        it._id,
                        it.boothName,
                        rawValue,
                      )
                    }
                    }
                    placeholder=''
                  /> 원
                </td>
              </tr>
            ))} */}
          </tbody>
        </Table>
        {/* <Pagenation
          pageCount={pageCount}
          page={page}
          setPage={setPage}
        /> */}
        <Row className='mt-3 mb-3 text-end'>
          <Col>
            <Button
              variant="outline-secondary"
              className="px-4 mx-3"
              onClick={() => {
                navigate("/areaPrice/" + id, { state: { selectSection: 0 } });
              }}
            >
              목 록
            </Button>
            <Button
              as='input'
              type='submit'
              value={"수정"}
              className='px-4'
              onClick={handleSubmit}
            />
          </Col>
        </Row>

      </Container>
    </>
  );
};

export default memo(BoothTypePrice);
