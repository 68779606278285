import "bootstrap/dist/css/bootstrap.min.css";
import CompanyTable from "../../components/CompanyTable";
import Container from "react-bootstrap/Container";
import HeardSearch from "../../components/HeardSearch";
import { Button, Col, Pagination, Row, Table } from "react-bootstrap";
import { memo, useEffect, useState } from "react";
import { getAlllogFunc, getAlllogExcelFunc } from "../../api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ChangeLogHead from "./ChangeLogHead";
import Pagenation from "../../components/Pagenation";
import * as XLSX from 'xlsx';
import { BASE_URL } from "../../api/helper";
import useStoreLocal from "../../hook/useStoreLocal.hook";
import { KEY_GOODS_LOG } from "../../utils/constant";

const ChangeLog = () => {
  const navigate = useNavigate()
  const [data, setData] = useState([])
  console.log("🚀 ~ Receiving ~ data:", data)

  // const [orderDate,setOrderDate] = useState({start:getStingDate(new Date()),end:''})
  const [searchStartDay, setSearchStartDay] = useState("")
  const [searchEndDay, setSearchEndDay] = useState("")
  const [sendStartDay, setSendStartDay] = useState("")
  const [sendEndDay, setSendEndDay] = useState("")
  const [selectClient, setSelectClient] = useState("All")
  const [selectDepartment, setSelectDepartment] = useState("All")
  const [selectProduct, setSelectProduct] = useState("All")
  const [selectCatgoris, setSelectCatgoris] = useState("All")

  const [search, setSearch] = useState("")
  const [searchState, setSearchState] = useState({
    신규: false,
    접수: false,
    발송: false,
    취소: false,
  })
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [totlaCount, setTotalCount] = useState({
    total: 0,
    pageItem: 0,
  });
  const [searchQuery, setSearchQuery, backPage, setValueBackPage] = useStoreLocal(KEY_GOODS_LOG, {});

  // 전체회사 로그
  const alllogGet = async (searchData) => {
    const response = await getAlllogFunc(searchData);
    if (response.status === 200) {
      setData(() => response?.data?.data);
      setPageCount(() =>
        response?.data?.Pagination.pageCount
      );
      setTotalCount((totlaCount) => ({
        ...totlaCount,
        total: response?.data?.Pagination.count,
        pageItem: response?.data?.Pagination.pageItem,
      }));
    } else {
      toast.error("재고현황을 가져오는데 실패했습니다.");
    }
  };
  const searchData = { searchStartDay, searchEndDay, selectClient, selectDepartment, selectProduct, search, sendStartDay, sendEndDay, selectCatgoris, page }

  useEffect(() => {
    if (!backPage) {
      alllogGet(searchData)
    }
    setSearchQuery({ ...searchData, ...searchQuery, page: page })
  }, [page]);

  useEffect(() => {
    if (backPage) {
      const {
        searchStartDay,
        searchEndDay,
        selectClient,
        selectDepartment,
        selectProduct,
        search,
        trueStates,
        sendStartDay,
        sendEndDay,
        page
      } = searchQuery;

      selectClient && setSelectClient(selectClient);
      selectDepartment && setSelectDepartment(selectDepartment);
      selectProduct && setSelectProduct(selectProduct);
      searchStartDay && setSearchStartDay(searchStartDay);
      searchEndDay && setSearchEndDay(searchEndDay);
      search && setSearch(search);
      sendStartDay && setSendStartDay(sendStartDay);
      sendEndDay && setSendEndDay(sendEndDay);
      page && setPage(page);

      if (trueStates?.length) {
        const newStates = trueStates.reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {})

        setSearchState(checkedState => ({ ...checkedState, ...newStates }));
      }

      alllogGet({ ...searchData, ...searchQuery });
      setValueBackPage(false);
    }
  }, [searchQuery, backPage]);

  const handleSearchChange = () => {
    setSearchQuery(searchData);
  };

  const handleSearchFnc = async (e) => {
    // 버튼이 눌리면 주문기간,구분,상태,검색을 api에 던져서 나온값을 data에 넣어준다.

    e.preventDefault();
    handleSearchChange();
    const response = await getAlllogFunc(searchData);
    if (response.status === 200) {
      setData(() => response?.data?.data);
      setPageCount(() =>
        response?.data?.Pagination.pageCount
      );
      setTotalCount((totlaCount) => ({
        ...totlaCount,
        total: response?.data?.Pagination.count,
        pageItem: response?.data?.Pagination.pageItem,
      }));
    } else {
      toast.error("전체물품을 가져오는데 실패했습니다.");
    }
  }

  // useEffect(() => {
  //   alllogGet(searchData);
  // }, [page]);

  const headColumn = [
    "번호",
    "변경일",
    "거래처명",
    "부서명",
    "담당자",
    "제품명",
    "종류",
    "물품명",
    "입출고",
    "수량",
    "보유재고",
  ];

  const exportToExcel = () => {

    const url = `${BASE_URL}/log/excel?search=${encodeURIComponent(search)}&searchStartDay=${encodeURIComponent(searchStartDay)}&searchEndDay=${encodeURIComponent(searchEndDay)}&selectClient=${encodeURIComponent(selectClient)}&selectDepartment=${encodeURIComponent(selectDepartment)}&selectProduct=${encodeURIComponent(selectProduct)}&page=${encodeURIComponent(page)}&catgoris=${encodeURIComponent(selectCatgoris)}`;
    const outputFilename = '재고변동현황.xlsx';
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();

  };

  const myButton = [
    { variant: "primary", name: "엑셀다운로드", onClick: exportToExcel },
  ];

  return (
    <>
      <Container className="align-items-center justify-content-center p-5">
        <ChangeLogHead
          handleSearchFnc={handleSearchFnc}
          setSelectClient={setSelectClient}
          selectClient={selectClient}
          setSelectDepartment={setSelectDepartment}
          selectDepartment={selectDepartment}
          setSelectProduct={setSelectProduct}
          selectProduct={selectProduct}
          selectCatgoris={selectCatgoris}
          setSelectCatgoris={setSelectCatgoris}
          setSearchStartDay={setSearchStartDay}
          searchStartDay={searchStartDay}
          setSearchEndDay={setSearchEndDay}
          searchEndDay={searchEndDay}
          setSearchState={setSearchState}
          searchState={searchState}

          setSearch={setSearch}
          search={search} />
        <Table striped bordered hover>
          <thead>
            <tr>
              {headColumn.map((it, idx) => (
                <th className='text-center' key={idx}>
                  {it}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((it, idx) => (
              <tr
                key={it._id}
                className='text-center align-middle'
                style={{ cursor: "pointer" }}
                onClick={(e) => navigate("/managementdetail/" + it?.goodsID)}
              >
                <td> {totlaCount?.total -
                  (page - 1) * totlaCount?.pageItem -
                  idx}</td>
                <td>{it?.editDate ? it.editDate.slice(0, 10) : ""}</td>
                <td>{it?.clientName ? it.clientName : ""}</td>
                <td>{it?.department ? it.department : ""}</td>
                <td>{it?.staffName ? it.staffName : ""}</td>
                <td>{it?.productName ? it.productName : ""}</td>
                <td>{it?.category ? it.category : ""}</td>
                <td>{it?.goodsName ? it.goodsName : ""}</td>
                <td>{
                  it.inOut === "입고" ? (<span className='inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10'>
                    {it.inOut}
                  </span>) : (<span className='inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-700/10'>
                    {it.inOut}
                  </span>)
                }

                </td>
                {it?.amountCount ? <td>{it.inOut === "출고" ? "-" + it.amountCount : it.amountCount}</td> : null}
                {it?.currentAmount ? <td>{it.currentAmount}</td> : <td>{it.currentAmount}</td>}
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagenation
          pageCount={pageCount}
          page={page}
          setPage={setPage}
        />
        <Row className='mt-3 mb-3 text-end'>
          <Col>
            {myButton.map((it, idx) => (
              <Button
                key={idx}
                variant={it.variant}
                onClick={it.onClick ? it.onClick : null}
                className='px-4 mx-2'
              >
                {it.name}
              </Button>
            ))}
          </Col>
        </Row>

      </Container>
    </>
  );
};

export default memo(ChangeLog);
