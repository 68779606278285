import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Container from "@mui/material/Container";
import { memo, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  boothDeleteFunc,
  boothUpdateFunc,
  getAllClientsFunc,
  getAllProductFunc,
  getAllboothtypeFunc,
  getBoothFunc,
} from "../../../api";
import { toast } from "react-toastify";

//TODO -  2. 등록된 부스종류 목록을 가져온다

const BoothView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const imgInputRef = useRef();
  const imgInputRef2 = useRef();
  const imgInputRef3 = useRef();
  const [forms, setForms] = useState([]);
  const [edit, setEdit] = useState(false);
  const [clients, setClients] = useState([]);
  const [selectClient, setSelectClient] = useState("");
  const [boothType, setBoothType] = useState([]);
  const [department, setDepartment] = useState([]);
  const [selectProduct, setselectProduct] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [imgSrc, setImgSrc] = useState('');
  const [imgSrc2, setImgSrc2] = useState("");
  const [imgSrc3, setImgSrc3] = useState("");
  const [oldImgSrc, setOldImgeSrc] = useState([]);
  const [preview, setPreview] = useState([]);
  const [boothTypeShow, setBoothTypeShow] = useState();
  // 전체회사 리스트

  const allClientsGet = async () => {
    const response = await getAllClientsFunc();
    if (response.status === 200) {
      setClients(response.data.data);
    } else {
      toast.error("거래처 정보를 가져오기 실패");
    }
  };
  // 전체회사 리스트
  const allBoothtypeGet = async () => {
    const response = await getAllboothtypeFunc();
    if (response.status === 200) {
      setBoothType(response.data.data);
    } else {
      toast.error("부스종류를 가져오기 실패.");
    }
  };

  // 부스 정보
  const boothtGet = async (id) => {
    const response = await getBoothFunc(id);
    if (response.status === 200) {
      // setBoothType(response.data);
      const {
        boothName,
        boothCode,
        boothType,
        clientName,
        specialNote,
        productName,
        department,
        boothImage,
        boothTypeId
      } = response.data;
      setForms({
        ...forms,
        boothName,
        boothCode,
        boothType,
        clientName,
        specialNote,
        productName,
        department,
        boothTypeId,
      });

      const selectCompany = clients.find(
        (client) => client.companyName === clientName
      );
      if (selectCompany && selectCompany.department) {
        setDepartment(selectCompany.department);
        data(selectCompany._id, department);
      }

      setPreview(boothImage)
      boothImage?.[0] && setImgSrc(boothImage[0])
      boothImage?.[1] && setImgSrc2(boothImage[1])
      boothImage?.[2] && setImgSrc3(boothImage[2])
      setOldImgeSrc(boothImage);

      // setSelectClient(department)
      // console.log(response.data)
    } else {
      toast.error("부스종류를 가져오기 실패.");
    }
  };

  useEffect(() => {
    allClientsGet();
    allBoothtypeGet();
    boothtGet(id);
  }, [edit]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  // 이미지 뷰어
  const encodeFileToBase64 = (fileBlob, setImage) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);
    return new Promise((resolve) => {
      reader.onload = () => {
        setImage(reader.result);
        resolve();
      };
    });
  };

  const handleDeleteImg = (e) => {
    if (e.target.name === "img1") {
      !oldImgSrc?.[0] && setImgSrc(null);
      imgInputRef.current.value = "";
    } else if (e.target.name === "img2") {
      !oldImgSrc?.[1] && setImgSrc2(null);
      imgInputRef2.current.value = "";
    } else if (e.target.name === "img3") {
      !oldImgSrc?.[2] && setImgSrc3(null);
      imgInputRef3.current.value = "";
    }
  };

  const handleChangeImage = (e) => {
    // console.log(e.target.name);

    const selectedFile = e.target.files[0];
    const maxSize = 5 * 1024 * 1024; // 5 MB in bytes

    if (selectedFile?.size > maxSize) {
      toast.error('5MB 이하의 파일을 업로드해주세요.');
      handleDeleteImg(e)
      return;
    }

    if (e.target.name === "img1") {
      setOldImage(0);
      encodeFileToBase64(e.target.files[0], setImgSrc);
    } else if (e.target.name === "img2") {
      setOldImage(1);
      encodeFileToBase64(e.target.files[0], setImgSrc2);
    } else if (e.target.name === "img3") {
      setOldImage(2);
      encodeFileToBase64(e.target.files[0], setImgSrc3);
    }
  };

  const setOldImage = (index) => {
    const newOldImgSrc = [...oldImgSrc];
    newOldImgSrc[index] = null;
    setOldImgeSrc(newOldImgSrc);
  }

  const handleDeleteImage = (e) => {
    if (e.target.id === "del_img1") {
      setOldImage(0);
      setImgSrc(null);
      imgInputRef.current.value = "";
    } else if (e.target.id === "del_img2") {
      setOldImage(1);
      setImgSrc2(null);
      imgInputRef2.current.value = "";
    } else if (e.target.id === "del_img3") {
      setOldImage(2);
      setImgSrc3(null);
      imgInputRef3.current.value = "";
    }
  };

  const data = async (id, department) => {
    const res = await getAllProductFunc(id);
    if (res.status === 200) {
      setselectProduct(res.data);
    }
    if (department) {
      const filterProducts = res.data?.filter(
        (product) => product.department === department
      );
      filterProducts && setFilterList(filterProducts);
    }
  };

  const handleChangeClient = (e) => {
    const { value } = e.target;
    onChange(e);
    setSelectClient(value);
    const clientName = value;
    const selectCompany = clients.find(
      (client) => client.companyName === clientName
    );

    if (selectCompany && selectCompany.department) {
      setDepartment(selectCompany.department);
      data(selectCompany._id);
    } else {
      setDepartment([]);
    }
  };

  const handleChangeDepartment = (e) => {
    const department = e.target.value;
    onChange(e);
    const filterProducts = selectProduct.filter(
      (product) => product.department === department
    );
    if (filterProducts) {
      setFilterList(filterProducts);
    } else {
      setFilterList([]);
    }
  };

  const onChange = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!edit) {
      setEdit(true);
      scrollToTop();
      return;
    }
    const formData = new FormData(e.target);
    formData.append('boothType', boothTypeShow.boothType);

    const newForms = Object.fromEntries(formData.entries())
    if (newForms.clientName === "default") {
      toast.error("거래처를 선택해 주세요.!");
      return
    }
    if (newForms.department === "default") {
      toast.error("부서를 선택해 주세요.!");
      return
    }
    if (newForms.productName === "default") {
      toast.error("제품명을 선택해 주세요.!");
      return
    }

    if (oldImgSrc?.length) {
      oldImgSrc.forEach((imagePath, index) => {
        formData.append(`oldBoothImage[${index}]`, imagePath);
      });
    }

    const config = {
      "Content-Type": "multipart/form-data",
    };
    const res = await boothUpdateFunc(id, formData, config);
    if (res.status === 201 || res.status === 200) {
      toast.success("Successfully Register!");
      // setTimeout(() => {
      //   navigate("/partnerstable", { replace: true });
      // }, 1000);
      scrollToTop();
      setEdit(false);
      setForms({
        ...forms,
        boothName: res.data?.boothName,
        boothCode: res.data?.boothCode,
        boothType: res.data?.boothType,
        clientName: res.data?.clientName,
        department: res.data?.department,
        productName: res.data?.productName,
        specialNote: res.data?.specialNote,
      });
      setImgSrc(res.data.boothImage?.[0] ?? null);
      setImgSrc2(res.data.boothImage?.[1] ?? null);
      setImgSrc3(res.data.boothImage?.[2] ?? null);
      imgInputRef.current.value = "";
      imgInputRef2.current.value = "";
      imgInputRef3.current.value = "";
    } else {
      toast.error("거래처 변경에 실패했습니다.!");
    }
  };
  const handleDelete = async () => {
    if (window.confirm("삭제하시겠습니까?")) {
      const res = await boothDeleteFunc(id);
      if (res.status === 200 || res.status === 201) {
        setTimeout(() => {
          toast.success("Successfully Delete!");
          navigate("/bmanager", { replace: true });
        }, 1000);
      } else {
        toast.error("부스종류 삭제실패!");
      }
    }
  };

  useEffect(() => {
    if(boothType?.length && forms.boothTypeId) {
      const boothTypeFind =  boothType?.find((item) => item?._id === forms.boothTypeId);
      setBoothTypeShow(boothTypeFind)
    }
  }, [boothType, forms.boothTypeId]);

  return (
    <>
      <Container maxWidth='md' className='py-3'>
        <Row className='fs-1 fw-bold p-2 border-bottom mb-5'>
          {edit ? "부스 수정" : "부스뷰"}
        </Row>
        <Form onSubmit={handleSubmit}>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              부스명
            </Form.Label>
            <Col sm='4'>
              {edit ? (
                <Form.Control
                  name='boothName'
                  value={forms.boothName}
                  onChange={onChange}
                  type='text'
                  size='sm'
                  required
                />
              ) : (
                <div className='flex items-center w-full h-full  text-lg text-slate-600'>
                  {forms.boothName}
                </div>
              )}
            </Col>
            <Form.Label column sm='2'>
              부스종류
            </Form.Label>
            <Col sm='4'>
              {edit ? (
                <Form.Select
                  aria-label='BoothType select'
                  name='boothTypeId'
                  size='sm'
                  value={forms.boothTypeId}
                  onChange={onChange}
                  // disabled
                >
                  {<option value=''>부스종류</option>}

                  {boothType?.length &&
                    boothType.map((it) => (
                      <option key={it._id} value={it._id}>
                        {it.boothType} / {it?.boothType2} / {it.boothName}
                      </option>
                    ))}
                </Form.Select>
              ) : (
                <div className='flex items-center w-full h-full  text-lg text-slate-600'>
                  {boothTypeShow ? `${boothTypeShow?.boothType}/${boothTypeShow?.boothType2}/${boothTypeShow?.boothName}` : ""}
                </div>
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              부스코드
            </Form.Label>
            <Col sm='4'>
              {edit ? (
                <Form.Control
                  type='text'
                  name='boothCode'
                  value={forms.boothCode}
                  onChange={onChange}
                  size='sm'
                  required
                  disabled
                />
              ) : (
                <div className='flex items-center w-full h-full  text-lg text-slate-600'>
                  {forms.boothCode}
                </div>
              )}
            </Col>

            <Form.Label column sm='2'>
              부서명
            </Form.Label>
            <Col sm='4'>
              {edit ? (
                <Form.Select
                  aria-label='Department Select'
                  name='department'
                  onChange={handleChangeDepartment}
                  value={forms.department}
                  size='sm'
                  disabled
                >
                  <option value='default'>부서명</option>
                  {department &&
                    department.map((it, idx) => (
                      <option key={idx} value={it}>
                        {it}
                      </option>
                    ))}
                </Form.Select>
              ) : (
                <div className='flex items-center w-full h-full  text-lg text-slate-600'>
                  {forms.department}
                </div>
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              거래처명
            </Form.Label>
            <Col sm='4'>
              {edit ? (
                <Form.Select
                  aria-label='Client select '
                  onChange={handleChangeClient}
                  name='clientName'
                  size='sm'
                  value={forms?.clientName}
                  disabled
                >
                  {!clients?.length && <option value='default'>거래처명</option>}
                  {clients?.length &&
                    clients.map((it) => (
                      <option key={it._id} id={it._id} value={it.companyName}>
                        {it.companyName}
                      </option>
                    ))}
                </Form.Select>

              ) : (
                <div className='flex items-center w-full h-full  text-lg text-slate-600'>
                  {forms.clientName}
                </div>
              )}
            </Col>

            <Form.Label column sm='2'>
              제품명
            </Form.Label>
            <Col sm='4'>
              {edit ? (
                <Form.Select
                  aria-label='Product select'
                  name='productName'
                  size='sm'
                  onChange={onChange}
                  value={forms.productName}
                  disabled
                >
                  <option value='default'>제품명</option>
                  {filterList &&
                    filterList.map((it) => (
                      <option key={it._id} id={it._id} value={it.productName}>
                        {it.productName}
                      </option>
                    ))}
                </Form.Select>
              ) : (
                <div className='flex items-center w-full h-full  text-lg text-slate-600'>
                  {forms.productName}
                </div>
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3 mt-5 px-3 flex items-center'>
            <Form.Label column sm='2'>
              특이사항
            </Form.Label>
            <Col sm='10'>
              <FloatingLabel controlId='floatingTextarea2' label='특이사항'>
                <Form.Control
                  as='textarea'
                  name='specialNote'
                  placeholder='Leave a comment here'
                  value={forms.specialNote}
                  onChange={onChange}
                  disabled={!edit}
                  style={{ height: "100px" }}
                />
              </FloatingLabel>
            </Col>
          </Form.Group>
          {edit && <Row>
            <Form.Label column sm='2'>
              {""}
            </Form.Label>
            <Col lg='9'>
              <small className='mt-3 px-2 d-block text-danger text-start'>
              * 5MB를 초과하는 파일은 등록할 수 없습니다.
              </small>
            </Col>
          </Row>}
          {/* //ANCHOR - image */}
          {edit && <Form.Group as={Row} className='mb-3 px-3 mt-5 align-items-center'>
            <Form.Label column sm='2'>
              부스이미지 1
            </Form.Label>
            <Col sm="10">
              <Row>
                {imgSrc && edit && (
                  <Form.Group as={Row} className='mb-3 pe-0'>
                    <Col className="w-100 pe-0">
                      <img
                        src={imgSrc}
                        alt='img preview'
                        style={{ maxWidth: "100%", height: "auto", width: "100%" }}
                      />
                    </Col>
                  </Form.Group>
                )}
              </Row>
              <Row className="d-flex f-row align-items-center">
                <Col sm="6">
                  <Form.Group controlId='formFile'>
                    <Form.Control
                      ref={imgInputRef}
                      type='file'
                      size='sm'
                      name='img1'
                      disabled={!edit}
                      onChange={handleChangeImage}
                      accept='image/*'
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  {imgSrc && (imgSrc2 || imgSrc3) && (
                    <span
                      onClick={handleDeleteImage}
                      id='del_img1'
                      className=' px-3 py-1 bg-sky-600 rounded-md text-white font-bold cursor-pointer'
                    >
                      삭제
                    </span>
                  )}
                </Col>


              </Row>
            </Col>
          </Form.Group>}
          {/* 이미지 프리뷰 */}

          {edit && <Form.Group as={Row} className='mb-3 px-3 align-items-center mt-5'>
            <Form.Label column sm='2'>
              부스이미지 2
            </Form.Label>
            <Col sm="10">
              <Row >
                {imgSrc2 && edit && (
                  <Form.Group as={Row} className='mb-3 px-3 pe-0'>
                    <Col className="w-100 pe-0">
                      <img
                        src={imgSrc2}
                        alt='img preview'
                        style={{ maxWidth: "100%", height: "auto", width: "100%" }}
                      />
                    </Col>
                  </Form.Group>
                )}
              </Row>

              <Row className="d-flex align-items-center">
                <Col sm='6'>
                  <Form.Group controlId='formFile'>
                    <Form.Control
                      ref={imgInputRef2}
                      type='file'
                      size='sm'
                      name='img2'
                      onChange={handleChangeImage}
                      accept='image/*'
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  {imgSrc2 && (imgSrc || imgSrc3) && (
                    <span
                      onClick={handleDeleteImage}
                      id='del_img2'
                      className=' px-3 py-1 bg-sky-600 rounded-md text-white font-bold cursor-pointer'
                    >
                      삭제
                    </span>
                  )}
                </Col>
              </Row>
            </Col>

          </Form.Group>}
          {/* 이미지 프리뷰 */}


          {edit && <Form.Group as={Row} className='mb-3 px-3 align-items-center mt-5'>
            <Form.Label column sm='2'>
              부스이미지 3
            </Form.Label>
            <Col sm="10">
              <Row>
                {imgSrc3 && edit && (
                  <Form.Group as={Row} className='mb-3 px-3 pe-0'>
                    <Col className="w-100 pe-0">
                      <img
                        src={imgSrc3}
                        alt='img preview'
                        style={{ maxWidth: "100%", height: "auto", width: "100%" }}
                      />
                    </Col>
                  </Form.Group>
                )}
              </Row>
              <Row className="d-flex align-items-center">
                <Col sm='6'>
                  <Form.Group controlId='formFile'>
                    <Form.Control
                      ref={imgInputRef3}
                      type='file'
                      size='sm'
                      name='img3'
                      onChange={handleChangeImage}
                      accept='image/*'
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  {imgSrc3 && (imgSrc || imgSrc2) && (
                    <span
                      onClick={handleDeleteImage}
                      id='del_img3'
                      className=' px-3 py-1 bg-sky-600 rounded-md text-white font-bold cursor-pointer'
                    >
                      삭제
                    </span>
                  )}
                </Col>
              </Row>
            </Col>
          </Form.Group>}

          {/* 이미지 프리뷰 */}

          {!edit &&
            preview && preview.map((it, idx) => (
              <Row className="flex items-center px-3 mt-5 " key={idx}>
                <Col sm="2">
                  <label>{`부스이미지 ${idx + 1}`}</label>
                </Col>
                <Col sm='10' className="p-0">
                  <img
                    src={it}
                    alt='img preview'
                    style={{ maxWidth: "100%", height: "auto", width: "100%", padding: "12px" }}
                  />
                </Col>
              </Row>
            ))}
          <Row>
            <Col className='text-start m-5 '>
              <Button
                variant='outline-danger'
                type='button'
                className='px-4 mx-3'
                onClick={handleDelete}
              >
                삭제
              </Button>
            </Col>
            <Col className='text-end m-5 '>
              <Button
                type='button'
                variant='outline-secondary'
                className='px-4 mx-3'
                onClick={() => navigate("/bmanager", { replace: true, state: {backPage: true} })}
              >
                목록
              </Button>{" "}
              <Button
                as='input'
                type='submit'
                value={edit ? "저 장" : "수 정"}
                className='px-4'
              />{" "}
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default memo(BoothView);
